import gql from 'graphql-tag';

const GET_PAYMENT_DETAILS_BY_IDS = gql`
  query GetPaymentDetailsByIdsQuery($paymentIds: [Float!]!) {
    payments: getPaymentDetailsByIds(paymentIds: $paymentIds) {
      id
      paymentId
      amount
      paymentDate
      status
      member {
        id
        name
      }
      project {
        id
        name
      }
      paymentGroup {
        id
        name
      }
      tags {
        id
        name
      }
      notes
      initiatedBy
      paypalEmail
      type
      timeline {
        id
        name
        createdDate
      }
      budgets {
        id
        name
      }
      brief {
        id
        name
        amount
        createdDate
      }
    }
  }
`;

export default GET_PAYMENT_DETAILS_BY_IDS;
