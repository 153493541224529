/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
 chain, isNil, isString, keyBy,
} from 'lodash';

import { LoadSpinner } from '@components';
import { UserAvatar } from '@frontend/app/components';

import { GetOrdersQuery_orders } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetOrdersQuery';
import { FulfillmentStatus, ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { useGetOrdersForDashboard } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersForDashboard';
import {
 BadgeGroup, DataTable, Empty, SelectWithInput, Tag, WidgetContainer,
} from '@frontend/app/refresh-components';
import { BoxOpenIcon, FileArrowDownIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetOrdersForDashboardCsv } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersForDashboardCsv';
import { useAppContext } from '@frontend/context/AppContext';
import { pluralize } from '../utils/strings';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';
import { useGetUsersQuery, useProgramsQuery, useQueryParams } from '../hooks';
import { fulfillmentStatusLabels } from '../containers/ProductFulfillmentDashboard/constants';
import { useGetCatalogs } from '../containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { EllipsisLabel } from '../refresh-components/EllipsisLabel';

const PAGE_SIZE = 40;

type IShippingLine = {
  price?: string;
};

type IOrderRaw = {
  shipping_lines?: IShippingLine[];
};

export interface IColumn {
  schemaId?: number;
  headerName: string;
  field: string;
  type: string;
  editable?: boolean;
  showInFilters?: boolean;
  showInEditColumns?: boolean;
  choices?: string[];
}

export type IOrders = GetOrdersQuery_orders & {
  id: string;
  member: any;
  products: any;
  _sortableFields: {
    member: string;
    products: string;
    raw: string;
    shipmentTracking: string;
    fulfillmentStatus: string;
  };
};

const renderNameField = (member) => (
  <div className="flex items-center gap-2">
    <UserAvatar name={member.name} />
    <NavLink to={(location) => ({ ...location, pathname: `/members/${member.id}` })}>
      <EllipsisLabel tooltip={member.name} width={180}>{member.name || ''}</EllipsisLabel>
    </NavLink>
  </div>
);

const customSort = (aValue: string | number, bValue: string | number) => {
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

const getOrderProducts = (order: GetOrdersQuery_orders): any[] => {
  switch (order.type) {
    case ProjectConfigType.NoCatalogue:
      return order.details?.no_catalogue_line_items || [];
    case ProjectConfigType.Shopify:
    case ProjectConfigType.ProductCatalog:
    default:
      return order.raw?.line_items || [];
  }
};

const getOrderProductsString = (order: GetOrdersQuery_orders): string => {
  const products = getOrderProducts(order);

  let productNames: string[] = [];
  switch (order.type) {
    case ProjectConfigType.NoCatalogue:
      productNames = chain(products)
        .map((lineItem) => lineItem?.productName)
        .filter((n): n is string => isString(n))
        .value();
      break;
    case ProjectConfigType.Shopify:
    case ProjectConfigType.ProductCatalog:
    default:
      productNames = chain(products)
        .map((lineItem) => lineItem?.name)
        .filter((n): n is string => isString(n))
        .value();
      break;
  }

  return productNames.join(', ');
};

const headerClassName = 'font-semibold text-[#1F1F21] flex items-center h-full -mx-2';

const tagClassName = 'border border-primary px-2 rounded-lg';

const Header = ({ children }) => (
  <div className={headerClassName}>{children}</div>
);

type SearchType = 'memberName' | 'orderId' | 'productName';

const PFAShipmentTable: React.FunctionComponent = () => {
  const [page, setPage] = useState(1);
  const { productCostEnabled } = useProductFulfillmentContext();

  const queryParams = useQueryParams();
  const catalogId = queryParams.get('catalogId');

  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [tempSearchText, setTempSearchText] = useState('');
  const [searchType, setSearchType] = useState<SearchType>('memberName');

  const {
    dateRangeSettings,
    ownerIds: userIds,
    projectIds,
    fulfillmentStatus,
    setState,
  } = usePFADashboardContext();

  const { apolloClient } = useAppContext();

  const { data: users } = useGetUsersQuery({
    client: apolloClient,
  });

  const { data: programs } = useProgramsQuery({
    client: apolloClient,
  });

  const { catalogs } = useGetCatalogs();

  const userMap = useMemo(() => keyBy(users?.users, 'id'), [users]);
  const programMap = useMemo(() => keyBy(programs?.programs, 'id'), [programs]);
  const catalogMap = useMemo(() => keyBy(catalogs, 'id'), [catalogs]);

  const {
 isOrdersLoading, orders: currOrders, totalOrders: currTotalOrders, previousOrders, previousTotalOrders,
} = useGetOrdersForDashboard({
    variables: {
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      orderFilters: {
        projectIds,
        catalogId: parseInt(catalogId, 10),
        userIds,
        startDate: dateRangeSettings.dateRange.startDate?.toISOString(),
        endDate: dateRangeSettings.dateRange.endDate?.toISOString(),
        memberName: searchType === 'memberName' ? searchText || undefined : undefined,
        orderNumber: searchType === 'orderId' ? searchText || undefined : undefined,
        fulfillmentStatuses: fulfillmentStatus,
        productName: searchType === 'productName' ? searchText || undefined : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    downloadOrdersCsv,
    isOrdersCsvLoading,
  } = useGetOrdersForDashboardCsv();

  const orders = isOrdersLoading ? previousOrders : currOrders;
  const totalOrders = (isOrdersLoading ? previousTotalOrders : currTotalOrders) || 0;

  useEffect(() => {
    setPage(1);
  }, [catalogId, userIds, dateRangeSettings.dateRange.startDate, dateRangeSettings.dateRange.endDate, searchType, searchText]);

  const calculateOrderShippingCost = (raw: IOrderRaw): string | null => {
    if (!raw?.shipping_lines || raw?.shipping_lines?.length === 0) return null;
    const shippingCost = raw.shipping_lines
      .reduce((total: number, shippingLine) => {
        const price = parseFloat(shippingLine.price);
        return total + (Number.isNaN(price) ? 0 : price);
      }, 0)
      .toFixed(2);
    return shippingCost;
  };

  const tableData: IOrders[] = useMemo(() => orders?.map((o, index) => {
      const row = {
        ...o,
        id: index.toString(),
        member: o.member,
        products: getOrderProducts(o),
        _sortableFields: {
          member: o.member?.name?.toLowerCase(),
          products: getOrderProductsString(o),
          raw: o.raw?.order_number || 'N/A',
          shipmentTracking: o.shipmentTracking?.shipmentStatus,
          fulfillmentStatus: o.fulfillmentStatus || 'N/A',
          orderShipmentCost: calculateOrderShippingCost(o.raw),
        },
      };
      return row;
    }) || [], [orders]);

  const debounceRef = useRef<NodeJS.Timer>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    setTempSearchText(e.target.value);
    debounceRef.current = setTimeout(() => {
      setSearchText(e.target.value);
    }, 1000);
  };

  const handleExportToCsv = () => {
    downloadOrdersCsv({
      variables: {
        orderFilters: {
          projectIds,
          catalogId: parseInt(catalogId, 10),
          userIds,
          startDate: dateRangeSettings.dateRange.startDate?.toISOString(),
          endDate: dateRangeSettings.dateRange.endDate?.toISOString(),
          memberName: searchType === 'memberName' ? searchText || undefined : undefined,
          orderNumber: searchType === 'orderId' ? searchText || undefined : undefined,
          fulfillmentStatuses: fulfillmentStatus,
          productName: searchType === 'productName' ? searchText || undefined : undefined,
        },
      },
      notifyOnNetworkStatusChange: true,
    });
  };

  const columns: ColumnDef<IOrders>[] = [
    {
      id: 'member',
      header: () => <Header>Name</Header>,
      accessorKey: 'member',
      cell: ({ getValue }) => <div>{renderNameField(getValue())}</div>,
      size: 250,
      sortingFn: (a, b) => customSort(a?.original?.member?.name.toLowerCase(), b?.original?.member?.name.toLowerCase()),
    },
    {
      header: () => <Header>Order Number</Header>,
      accessorKey: 'raw.order_number',
      cell: ({ getValue }) => (
        <Badge variant="secondary" className="max-w-full">
          <EllipsisLabel tooltip={getValue() as string || 'N/A'} width="100%" className="max-w-full">{getValue() || 'N/A'}</EllipsisLabel>
        </Badge>
      ),
    },
    {
      header: () => <Header>Products Ordered</Header>,
      accessorKey: '_sortableFields.products',
      cell: ({ getValue }) => <BadgeGroup items={(getValue() as string)?.split(', ') || []} />,
      size: 250,
    },
    {
      header: () => <Header>Created On</Header>,
      accessorKey: 'createdDate',
      cell: ({ getValue }) => (
        <Badge variant="secondary">
          {(getValue() && new Date(getValue() as string).toLocaleDateString()) || ''}
        </Badge>
      ),
    },
    {
      header: () => <Header>Fulfillment Status</Header>,
      accessorKey: 'fulfillmentStatus',
      cell: ({ getValue }) => <Badge variant="secondary">{getValue() || 'N/A'}</Badge>,
    },
    {
      header: () => <Header>Shipment Status</Header>,
      accessorKey: 'shipmentTracking.shipmentStatus',
      cell: ({ getValue }) => <Badge variant="secondary">{getValue() || 'N/A'}</Badge>,
    },
    {
      header: () => <Header>Tracking Number</Header>,
      accessorKey: 'shipmentTracking.trackingNumber',
      cell: ({ getValue }) => (
        <Badge variant="secondary" className="max-w-full">
          <EllipsisLabel tooltip={getValue() as string || 'N/A'} width="100%" className="max-w-full">{getValue() || 'N/A'}</EllipsisLabel>
        </Badge>
      ),
    },
    {
      header: () => <Header>Project</Header>,
      accessorKey: 'artifacts',
      cell: ({ row }) => {
        const programTitle = programMap[row.original?.artifacts?.programIds?.[0]]?.title;
        return (
          <Badge variant="secondary" className="max-w-full">
            <EllipsisLabel tooltip={programTitle || 'N/A'} width="100%" className="max-w-full">{programTitle || 'N/A'}</EllipsisLabel>
          </Badge>
        );
      },
      size: 250,
    },
    {
      header: () => <Header>Owner</Header>,
      accessorKey: 'owner',
      cell: ({ row }) => {
        const ownerName = userMap[row.original?.userId]?.name;
        return (
          <Badge variant="secondary" className="max-w-full">
            <EllipsisLabel tooltip={ownerName || 'N/A'} width="100%" className="max-w-full">{ownerName || 'N/A'}</EllipsisLabel>
          </Badge>
        );
      },
      size: 250,
    },
  ];

  if (productCostEnabled) {
    columns.splice(2, 0, {
      header: () => <Header>Product Cost</Header>,
      accessorKey: 'cost',
      cell: ({ getValue }) => <Badge variant="secondary">{`${getValue() ? `$${getValue()}` : 'N/A'}`}</Badge>,
    });

    columns.splice(3, 0, {
      header: () => <Header>Shipping Cost</Header>,
      accessorKey: '_sortableFields.orderShipmentCost',
      cell: ({ getValue }) => <Badge variant="secondary">{`${getValue() ? `$${getValue()}` : 'N/A'}`}</Badge>,
    });
  }

  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={BoxOpenIcon}
        title="Orders not found"
        description="It looks like there are no orders for the given filters. Try adjusting the filters and try again."
      />
    </div>
  );

  const handleRemoveFulfillmentStatus = (status: FulfillmentStatus) => {
    setState({
      fulfillmentStatus: fulfillmentStatus.filter((s) => s !== status),
      localFulfillmentStatus: fulfillmentStatus.filter((s) => s !== status),
    });
  };

  const handleRemoveOwner = (id: string) => {
    setState({
      ownerIds: userIds.filter((ownerId) => ownerId !== id),
      localOwnerIds: userIds.filter((ownerId) => ownerId !== id),
    });
  };

  const handleRemoveProjectIds = (id: number) => {
    setState({
      projectIds: projectIds.filter((projectId) => projectId !== id),
      localProjectIds: projectIds.filter((projectId) => projectId !== id),
    });
  };

  const handleRemoveCatalogId = () => {
    queryParams.delete('catalogId');
    history.push({ search: queryParams.toString() });
  };

  const isFilterApplied = (
    (fulfillmentStatus?.length > 0)
    || (userIds?.length > 0 && users?.users?.length > 0)
    || (projectIds?.length > 0 && programs?.programs?.length > 0)
    || (catalogId && catalogs?.length > 0)
  );

  return (
    <div className="flex flex-col gap-6 relative">
      {isFilterApplied && (
        <div className="flex gap-4">
          {
            fulfillmentStatus && (
              fulfillmentStatus.map((status) => (
                <Tag
                  key={status}
                  label={`Fulfillment Status: ${fulfillmentStatusLabels[status]}`}
                  isRemovable
                  onRemove={() => handleRemoveFulfillmentStatus(status)}
                  className={tagClassName}
                />
            ))
          )
        }
          {
          userIds.length > 0 && users?.users?.length > 0 && (
            userIds.map((id) => (
              <Tag
                key={id}
                label={`Owner: ${userMap[id]?.name}`}
                isRemovable
                onRemove={() => handleRemoveOwner(id)}
                className={tagClassName}
              />
            ))
          )
        }
          {
          projectIds.length > 0 && programs?.programs?.length > 0 && (
            projectIds.map((id) => (
              <Tag
                key={id}
                label={`Project: ${programMap[id]?.title}`}
                isRemovable
                onRemove={() => handleRemoveProjectIds(id)}
                className={tagClassName}
              />
            ))
          )
        }
          {
          !isNil(catalogId) && (
            <Tag
              label={`Catalog: ${catalogMap[catalogId]?.name}`}
              isRemovable
              onRemove={handleRemoveCatalogId}
              className={tagClassName}
            />
          )
          }
        </div>
      )}
      {isOrdersLoading && (
        <div className="absolute z-[9999] bg-primary-foreground opacity-50 rounded-2xl top-0 left-0 h-full w-full flex items-center justify-center">
          <LoadSpinner />
        </div>
      )}
      <WidgetContainer
        widgetTitle={`${totalOrders} ${pluralize(totalOrders, 'Order')}`}
        tooltip="This includes all the sent order requests as well."
        bgColor="bg-white"
        textColor="text-gray-700"
        widgetActions={(
          <div className="flex items-center gap-4">
            <Button size="icon" variant="outline" onClick={handleExportToCsv} loading={isOrdersCsvLoading}>
              {isOrdersCsvLoading ? null : <FileArrowDownIcon className="h-4 w-4" />}
            </Button>
            <SelectWithInput
              items={[
                    { value: 'memberName', label: 'Creator Name' },
                    { value: 'orderId', label: 'Order ID' },
                    { value: 'productName', label: 'Product Name' },
                  ]}
              selectProps={{
                    value: searchType,
                    onValueChange: (value) => setSearchType(value as SearchType),
                  }}
              selectTriggerProps={{
                    className: 'w-[150px]',
                  }}
              inputProps={{
                    placeholder: 'Search',
                    value: tempSearchText,
                    onChange: handleSearchChange,
                    className: 'w-[250px]',
                  }}
            />
            <div className="flex gap-2 items-center">
              <span>{`${(page - 1) * PAGE_SIZE + 1}-${Math.min(page * PAGE_SIZE, totalOrders)} of ${totalOrders}`}</span>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  disabled={page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                >
                  <ChevronLeft className="text-gray-500" size={16} />
                </Button>
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  disabled={page * PAGE_SIZE >= totalOrders}
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  <ChevronRight className="text-gray-500" size={16} />
                </Button>
              </div>
            </div>
          </div>
            )}
      >
        {tableData?.length ? (
          <DataTable
            columns={columns}
            data={tableData}
            maxHeight="calc(100vh - 300px)"
            columnPinning={{
              left: ['member'],
            }}
          />
        ) : emptyContent}
      </WidgetContainer>
    </div>
  );
};

export default PFAShipmentTable;
