import { EllipsisLabel } from '@frontend/app/refresh-components/EllipsisLabel';
import { isNumber } from 'lodash';
import React from 'react';

interface TagsProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string | number;
  align?: 'right' | 'left';
}

const Generic: React.FC<TagsProps> = ({ value, align }) => {
  // If the value is null or undefined, display '-'
  const label = value == null ? '-' : isNumber(value) ? value.toLocaleString() : value;
  const alignment = align ?? isNumber(value) ? 'right' : 'left';

  return (
    <EllipsisLabel align={alignment} tooltip={label} width="auto">
      {label}
    </EllipsisLabel>
  );
};

export default Generic;
