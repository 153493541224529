import {
  Button,
  Col, IColumnsType, Input, Row, Select, Table, Tag, Typography,
} from '@revfluence/fresh';
import React, {
  useCallback,
  useMemo, useState,
} from 'react';
import {
  first,
  isArray,
  isEmpty, isNil, isNull, isString, map, uniqBy,
} from 'lodash';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import DateFilter from '@frontend/applications/ReportsApp/pages/Reporting/components/DateFilter/DateFilter';
import { DateRangesLabel } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';
import { dateValues } from '@frontend/applications/ReportsApp/pages/Reporting/components/Header/Header';
import { format } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tooltip } from 'antd';
import { OFFER_SOURCE } from '../../types/globalTypes';
import styles from './OfferConversionHistoryDrawer.scss';
import { getLocaleString, getTimeStamp } from '../../utils/dateTimeUtils';
import { GetOfferAffiliateConversionHistoryById_offerAffiliateConversionHistory } from '../../queries/types/GetOfferAffiliateConversionHistoryById';
import { DataFormat, formatValue } from '../../utils';
import { arrangeTableColumns } from '../../utils/columnOrder';
import { ExportButton } from '../MemberTable/Buttons';
import { IHeader, useExportToCsv } from '../../hooks';
import { CONVERSION_SOURCE, CUSTOMER_TYPE, IOfferConversionTable } from './types';
import { CONVERSION_STATUS, getTagType } from '../../utils/getTagType';

interface IProps {
  offerConversionHistory: GetOfferAffiliateConversionHistoryById_offerAffiliateConversionHistory[];
  source: OFFER_SOURCE;
}

const { Text } = Typography;
const { Option } = Select;
const orderArray: string[] = ['orderId', 'tuneOrderId', 'memberName', 'promoCode', 'affiliatInfoOne', 'saleAmount', 'exchangeRates', 'sales', 'commisionEarned', 'status', 'date'];
const TABLE_PAGE_SIZE = 50;
export const OfferConversionHistoryDrawerTable: React.FC<IProps> = (props) => {
  const { offerConversionHistory, source } = props;
  const [dateRange, setDateRange] = useState<[Date?, Date?]>([null, null]);
  const [searchText, setSearchText] = useState('');
  const [selectedStore, setSelectedStore] = useState<string>('All Stores');
  const exportToCsv = useExportToCsv();
  const getCustomerType = (isNewCustomer): string => {
    if (isNil(isNewCustomer)) {
      return '';
    }
    if (isNewCustomer) {
      return CUSTOMER_TYPE.NEW_CUSTOMER;
    }
    return CUSTOMER_TYPE.RETURNING_CUSTOMER;
  };
  const data = useMemo(() => {
    const [startDate, endDate] = dateRange;
    const filteredOfferConversionHistory = offerConversionHistory.filter((affiliate) => selectedStore === 'All Stores' || affiliate.storeName === selectedStore);
    return map(filteredOfferConversionHistory, (conversion) => {
      let dupDiscountCode: string | undefined;
      try {
        if (conversion.discountCode) {
          const duplicateDiscount = JSON.parse(conversion.discountCode);
          if (isArray(duplicateDiscount)) {
            dupDiscountCode = first(duplicateDiscount).code;
          }
        }
      } catch (error) {
        console.log('conversion.discount is not a valid JSON');
      }
      const commonFields: IOfferConversionTable = {
        conversionDate: conversion.conversionDate,
        id: String(conversion.conversionId),
        conversionId: conversion.conversionId,
        key: String(conversion.conversionId),
        sales: {
          sale: conversion.sale,
          currency: conversion.currency,
        },
        orderId: conversion.conversionId,
        exchangeRates: {
          rate: conversion.exchangeRate,
          currency: conversion.currency,
        },
        saleAmount: {
          sale: conversion.sale,
          baseSale: conversion.salesBase,
          currency: conversion.currency,
        },
        commisionEarned: conversion.payoutEarned,
        status: conversion.status,
        affiliatInfoOne: conversion.affiliatInfoOne,
        tuneOrderId: conversion.tuneOrderId,
        memberName: conversion.memberName,
        promoCode: conversion.promoCode || '--',
        customerType: getCustomerType(conversion.isNewCustomer),
        duplicateDiscountCode: dupDiscountCode,
        source: conversion.source === 'link' ? CONVERSION_SOURCE.LINK : CONVERSION_SOURCE.PROMO,
      };
      return {
        ...commonFields,
      };
    }).filter((conversion) =>
      (startDate ? getTimeStamp(conversion.conversionDate) >= getTimeStamp(startDate.setHours(0, 0, 0, 0)) : true)
      && (endDate ? getTimeStamp(conversion.conversionDate) <= getTimeStamp(endDate.setHours(23, 59, 59, 999)) : true)).filter((conversion) => {
        const searchTextLower = String(searchText).toLowerCase();
        return _.includes(String(conversion?.conversionId).toLowerCase(), searchTextLower)
          || _.includes(conversion?.memberName.toLowerCase(), searchTextLower)
          || _.includes(conversion.source === CONVERSION_SOURCE.PROMO ? conversion?.promoCode.toLowerCase() : '', searchTextLower) || _.includes(conversion?.tuneOrderId, searchTextLower);
      });
  }, [offerConversionHistory, dateRange, searchText, selectedStore]);
  const uniqueStores = useMemo(() => {
    const stores = uniqBy(offerConversionHistory, 'storeName').map((obj) => obj.storeName);
    stores.unshift('All Stores');
    return stores;
  }, [offerConversionHistory]);
  const isUsdStore = _.every(data, (item) => item.saleAmount.currency === 'USD');
  const columns = useMemo(() => {
    const column: IColumnsType<IOfferConversionTable> = [
      {
        title: 'Date',
        key: 'conversionDate',
        dataIndex: 'conversionDate',
        render: (date) => getLocaleString(date),
        width: 170,
        sorter: (a, b) => getTimeStamp(a.conversionDate) - getTimeStamp(b.conversionDate),
      },
      {
        title: 'Order ID',
        key: 'orderId',
        dataIndex: 'orderId',
        render: (text, record) => <Text>{record.source === CONVERSION_SOURCE.LINK ? record.tuneOrderId : text}</Text>,
        width: 120,
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => a.orderId.localeCompare(b.orderId),
      },
      {
        title: 'Sales',
        key: 'saleAmount',
        dataIndex: 'saleAmount',
        render: (data, record) =>
          `${isUsdStore || record.source === CONVERSION_SOURCE.LINK ? '$' : ''}${
            record.source === CONVERSION_SOURCE.LINK
              ? formatValue(DataFormat.Money, data.sale)
              : formatValue(DataFormat.Money, data.baseSale)
          } ${source === OFFER_SOURCE.SHOPIFY && !isUsdStore ? data.currency : ''}`,
        width: 100,
        sorter: (a, b) => a.saleAmount.sale - b.saleAmount.sale,
      },
      {
        title: 'Commission Earned ',
        key: 'commisionEarned',
        dataIndex: 'commisionEarned',
        render: (data) => `$ ${formatValue(DataFormat.Money, data)}`,
        width: 150,
        sorter: (a, b) => a.commisionEarned - b.commisionEarned,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (text, data) => {
          if (data.duplicateDiscountCode) {
            return (
              <Tooltip title={`Conversion duplicated by promo ${data.duplicateDiscountCode}`}>
                <Tag color={getTagType(CONVERSION_STATUS.DUPLICATE)}>{CONVERSION_STATUS.DUPLICATE}</Tag>
              </Tooltip>
            );
          }
          return <Tag color={getTagType(text)}>{text}</Tag>;
        }, // add status for
        width: 120,
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        title: 'Affiliate',
        key: 'memberName',
        dataIndex: 'memberName',
        width: 120,
        sorter: (a, b) => a.memberName.localeCompare(b.memberName),
      },
    ];

    if ((source === OFFER_SOURCE.SHOPIFY || source === OFFER_SOURCE.PROMO_LINK) && !isUsdStore) {
      column.push(
        {
          title: 'Exchange Rate(USD)',
          key: 'exchangeRates',
          dataIndex: 'exchangeRates',
          render: (data, record) => (
            record.source === CONVERSION_SOURCE.PROMO
            ? (
              <Tag>
                1
                {data.currency}
                {' '}
                =
                {' '}
                {Number(data.rate).toFixed(4)}
                {' '}
                USD
              </Tag>
)
             : '--'
          ),
          width: 170,

        },
        {
          title: 'Sales (USD)',
          key: 'sales',
          dataIndex: 'sales',
          render: (data, record) => (record.source === CONVERSION_SOURCE.PROMO ? `$ ${formatValue(DataFormat.Money, data.sale)}` : '--'),
          width: 100,
          sorter: (a, b) => a.sales.sale - b.sales.sale,

        },
      );
    }
    // if (source === OFFER_SOURCE.TUNE) {
    // }
    if (source === OFFER_SOURCE.SHOPIFY || source === OFFER_SOURCE.PROMO_LINK) {
      column.push(
        {
          title: 'Promo Codes',
          key: 'promoCode',
          dataIndex: 'promoCode',
          width: 190,
          sorter: (a, b) => a.promoCode.localeCompare(b.promoCode),
        },
        {
          title: 'Customer',
          key: 'customerType',
          dataIndex: 'customerType',
          width: 120,
          render: (text) => (text ? <Tag color={text === CUSTOMER_TYPE.NEW_CUSTOMER ? 'gold' : 'baby'}>{text}</Tag> : '-'),
          sorter: (a, b) => a.customerType.localeCompare(b.customerType),
        },
      );
    }
    if (source === OFFER_SOURCE.PROMO_LINK) {
      column.push({
        title: 'Source',
        key: 'conversion_source',
        dataIndex: 'source',
        width: 120,
        render: (text) => <Tag color={text === CONVERSION_SOURCE.LINK ? 'blue' : 'purple'}>{text}</Tag>,
      });
    }

    return arrangeTableColumns(column);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);
  const renderCount = useCallback(() => {
    const tableCount = data.length;
    return (
      <div>
        <Text strong>
          {tableCount}
          {' '}
          Conversions
        </Text>
      </div>
    );
  }, [data]);
  const handleClickExport = useCallback(() => {
    const csvColumnConfig = columns.map((config): IHeader | null => {
      if (!isString(config.title)) {
        return null;
      }
      const field = config.key.toString();
      return {
        headerName: config.title,
        field,
      };
    }).filter((c) => !isNull(c));

    const csvData = map(data, (row) => {
      const formattedRow = {
        ...row,
        conversionDate: format(new Date(row.conversionDate), 'MM/dd/yyyy, hh:mm'),
        sales: `$ ${formatValue(DataFormat.Money, row.sales.sale)}`,
        saleAmount: `${isUsdStore || source === OFFER_SOURCE.TUNE ? '$' : ''}${source === OFFER_SOURCE.TUNE ? formatValue(DataFormat.Money, row.saleAmount.sale) : formatValue(DataFormat.Money, row.saleAmount.baseSale)} ${(source === OFFER_SOURCE.SHOPIFY && !isUsdStore) ? row.saleAmount.currency : ''}`,
        commisionEarned: `$ ${formatValue(DataFormat.Money, row.commisionEarned)}`,
        ...(source === OFFER_SOURCE.SHOPIFY && {
          exchangeRates: `1 ${row.exchangeRates.currency} = ${row.exchangeRates.rate.toFixed(4)} USD`,
        }),
        orderId: row.source === CONVERSION_SOURCE.LINK ? row.tuneOrderId : row.orderId,
      };

      return formattedRow;
    });

    if (!isEmpty(csvData)) {
      exportToCsv(`affiliates-${Date.now()}`, csvColumnConfig, csvData);
    }
  }, [columns, data, exportToCsv, source, isUsdStore]);

  const pagination = {
    total: data.length,
    pageSize: TABLE_PAGE_SIZE,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    itemRender: (_current, type, originalElement) => {
      if (type === 'prev') {
        return <Button icon={<ChevronLeftIcon fontSize={24} />} />;
      }
      if (type === 'next') {
        return <Button icon={<ChevronRightIcon fontSize={24} />} />;
      }
      return originalElement;
    },
    showLessItems: true,
    className: 'customPagination',
  };
  return (
    <div className={styles.OfferConversionHistoryDrawer}>
      <Row align="middle" justify="space-between" className={styles.OfferConversionHistoryTableHeader}>
        <Col>
          <Row gutter={8}>
            <Col>
              {renderCount()}
            </Col>
            <Col>
              {(uniqueStores && uniqueStores.length > 2) && (
                <Select defaultValue="All Stores" onChange={(e) => setSelectedStore(e)}>
                  {uniqueStores.map((s, index) => <Option value={s} key={index}>{s}</Option>)}
                </Select>
              )}
            </Col>
            <Col>
              <ExportButton
                disabled={isEmpty(data)}
                onClick={handleClickExport}
                showText={false}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={8} align="middle" style={{ marginRight: data.length < 9 ? 128 : data.length > 999 ? 200 : 174 }}>
            <Col>
              <Input
                placeholder={`Search ${source === OFFER_SOURCE.SHOPIFY ? 'Order ID, Affiliate, Promo Code' : 'Order ID, Affiliate, Event ID'}`}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
                style={{ width: '300px' }}
              />
            </Col>
            <Col>
              <DateFilter
                key="date-filter"
                defaultLabel={DateRangesLabel.ALL_TIME}
                isCheckbox={false}
                options={dateValues}
                isCustomDate={false}
                onFilterChange={([filter]) => filter && setDateRange(filter.value.dateRange)}
                data-testid="date-filter"
              />
            </Col>
            <Col />
          </Row>

        </Col>
      </Row>

      <Table.Draggable key={JSON.stringify(columns)} dataSource={data} columns={arrangeTableColumns(columns, orderArray)} pagination={pagination} size="small" className="offerConversionTable" />
    </div>
  );
};
