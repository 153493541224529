import { useCallback } from 'react';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { MemberInsights } from '@frontend/gql/social/graphql';
import { ColumnDefinitionInput, MappedColumn } from '..';

interface UseMemberTableConfig {
  selectedColumns: ColumnDefinitionInput[];
  setSelectedColumns: (
    columns: ColumnDefinitionInput[] | ((prev: ColumnDefinitionInput[]) => ColumnDefinitionInput[]),
  ) => void;
  memberInsights: MemberInsights[];
  currentPage: number;
  pageSize: number;
  handleSortingChange: (sorting: SortingState) => void;
  sorting: SortingState;
  globalFilter: string;
  setIsPartialLoading: (isPartialLoading: boolean) => void;
}

export const useMemberTable = ({
  selectedColumns,
  setSelectedColumns,
  memberInsights,
  currentPage,
  pageSize,
  handleSortingChange,
  sorting,
  globalFilter,
  setIsPartialLoading,
}: UseMemberTableConfig) => {
  const handleExpandColumn = useCallback(
    (columnId: string) => {
      const toggleExpand = (columns: ColumnDefinitionInput[]): ColumnDefinitionInput[] => {
        return [...columns].map((column: ColumnDefinitionInput) => {
          if (column.id === columnId) {
            return { ...column, isColumnExpanded: !column.isColumnExpanded };
          }
          if (column.subColumns?.length) {
            return { ...column, subColumns: toggleExpand(column.subColumns) };
          }
          return column;
        });
      };
      setIsPartialLoading(true);
      setSelectedColumns((prevColumns: ColumnDefinitionInput[]) => toggleExpand(prevColumns));
    },
    [setSelectedColumns, setIsPartialLoading],
  );

  const handleDragEnd = useCallback(
    (fromIndex: number, toIndex: number, mappedColumnData: MappedColumn[]) => {
      if (fromIndex === toIndex) return;

      // Find the ID of the column to be moved in mappedColumnData
      const movedColumnId = mappedColumnData[fromIndex].id;

      // Find the actual moved column in selectedColumns by its ID
      const movedColumn = selectedColumns.find((col) => col.id === movedColumnId);

      if (!movedColumn) {
        console.error('Column not found in selectedColumns');
        setSelectedColumns((prevColumns) => [...prevColumns]);
        return;
      }

      // Update the selectedColumns in the state
      setSelectedColumns((prevColumns) => {
        // Create a copy of prevColumns to modify
        const updatedColumns = [...prevColumns];

        // Find the original index of the moved column in selectedColumns
        const originalIndex = prevColumns.findIndex((col) => col.id === movedColumnId);

        // Remove the moved column from its original position in prevColumns
        updatedColumns.splice(originalIndex, 1);

        // Find the next non-subcolumn's ID in mappedColumnData starting from toIndex
        let nextNonSubColumnIndex = toIndex;
        const requiresAdjustment = mappedColumnData[nextNonSubColumnIndex]?.isSubColumn && (fromIndex < toIndex);
        while (
          nextNonSubColumnIndex < mappedColumnData.length &&
          mappedColumnData[nextNonSubColumnIndex]?.isSubColumn
        ) {
          nextNonSubColumnIndex++; // Keep moving to the right until we find a non-subcolumn
        }

        // Get the ID of the next non-subcolumn from mappedColumnData
        const nextNonSubColumnId = mappedColumnData[nextNonSubColumnIndex]?.id;

        // Find the index of that next non-subcolumn in prevColumns
        let targetIndexInPrevColumns = prevColumns.findIndex(
          (col) => col.id === nextNonSubColumnId
        );

        // Adjust the index to account for the removed element
        if (requiresAdjustment) {
          // If we're moving forward, the target index must be adjusted by one
          targetIndexInPrevColumns--;
        }

        // If there's a valid non-subcolumn, place the moved column before it
        if (targetIndexInPrevColumns >= 0) {
          updatedColumns.splice(targetIndexInPrevColumns, 0, movedColumn);
        } else {
          // If no valid non-subcolumn is found, add the moved column at the end
          updatedColumns.push(movedColumn);
        }

        // Return the updated columns
        return updatedColumns;
      });
    },
    [setSelectedColumns, selectedColumns]
  );

  const paginationState: PaginationState = {
    pageIndex: currentPage,
    pageSize,
  };

  return {
    memberTableProps: {
      memberInsights,
      selectedColumns,
      handleExpandColumn,
      handleDragEnd,
      paginationState,
      handleSortingChange,
      sorting,
      globalFilter,
    },
  };
};
