import React from 'react';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@frontend/shadcn/components/ui/collapsible';
import { Timeline } from '@frontend/app/refresh-components';
import {
  WalletIcon,
  ClockIcon,
  CircleExclamationIcon,
  CircleCheckIcon,
  ChevronDownIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';

interface TimelineSectionProps {
  paymentDetails: GetPaymentDetailsByIdsQuery_payments[];
}

interface TimelineItem {
  id: number;
  title: string;
  description: string;
  icon: React.ReactNode;
}

export const TimelineSection: React.FC<TimelineSectionProps> = ({ paymentDetails }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const selectedPayment = paymentDetails?.[0];

  if (!selectedPayment || paymentDetails.length !== 1) return null;

  // Map of timeline item icons by id
  const timelineIconMap: Record<number, React.ReactNode> = {
    1: <WalletIcon className="w-4 h-4 bg-white text-grey-3" />,
    2: <ClockIcon className="w-4 h-4 bg-white text-[#FAAD14]" />,
    3: <CircleExclamationIcon className="w-4 h-4 bg-white text-[#EB6661]" />,
    4: <CircleCheckIcon className="w-4 h-4 bg-white text-[#5DB884]" />
  };

  // Format date in "Jan 23, 2020, 7:00 AM" format
  const formatTimelineDate = (date: string | number | Date) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).format(new Date(date));
  };

  // Create timeline items
  const timelineItems: TimelineItem[] = selectedPayment.timeline.map((item) => ({
    id: item.id,
    title: item.name,
    description: formatTimelineDate(item.createdDate),
    icon: timelineIconMap[item.id] || timelineIconMap[1]
  }));

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="w-full space-y-2"
    >
      <CollapsibleTrigger className="flex w-full items-center justify-between hover:opacity-80">
        <p className="m-0 text-sm font-medium">Payment Timeline</p>
        <ChevronDownIcon
          className={`h-3 w-3 transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </CollapsibleTrigger>
      <Separator className="my-2" />
      <CollapsibleContent>
        <Timeline items={timelineItems} />
      </CollapsibleContent>
    </Collapsible>
  );
};
