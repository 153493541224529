import React, { useEffect } from 'react';
import { PencilIcon } from '@revfluence/fresh-icons/regular/esm';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { Label } from '@frontend/shadcn/components/ui/label';

import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Spinner } from '@revfluence/fresh';
import { cn } from '@frontend/shadcn/lib/utils';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { usePayment } from '../PaymentContext';
import { BudgetSource } from '../constants';
import { IEditPayments, IFiscalPeriod } from './EditPaymentDrawer';

export enum Mode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

interface ConnectionsSectionProps {
  paymentDetails: GetPaymentDetailsByIdsQuery_payments[];
  editPayments: IEditPayments;
  setEditPayments: React.Dispatch<React.SetStateAction<IEditPayments>>;
  briefs: IAgreement[];
  isLoading: boolean;
  fiscalPeriods: IFiscalPeriod[];
  projectFiscalPeriods: IFiscalPeriod[];
}

// Helper components for better organization
const ConnectionField: React.FC<{
  label: string | React.ReactNode;
  editMode: boolean;
  value: React.ReactNode;
  editComponent: React.ReactNode;
}> = ({ label, editMode, value, editComponent }) => (
  <div className="flex justify-between items-center">
    <span className="text-gray-400">{label}</span>
    {editMode ? editComponent : <span>{value}</span>}
  </div>
);

const PeriodSelector: React.FC<{
  periods: IFiscalPeriod[];
  selectedKey: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
}> = ({ periods, selectedKey, onChange, placeholder = 'Select Fiscal Period' }) => (
  <Select value={selectedKey || ''} onValueChange={onChange}>
    <SelectTrigger className="w-[170px] h-7 text-sm">
      <SelectValue placeholder={placeholder} />
    </SelectTrigger>
    <SelectContent className="z-[9999]">
      <SelectGroup>
        {periods.length > 0 ? (
          periods.map((period) => (
            <SelectItem key={period.key} value={period.key}>
              {period.label}
            </SelectItem>
          ))
        ) : (
          <SelectItem disabled value="no-period">
            No Period
          </SelectItem>
        )}
      </SelectGroup>
    </SelectContent>
  </Select>
);

export const ConnectionsSection: React.FC<ConnectionsSectionProps> = ({
  editPayments,
  setEditPayments,
  paymentDetails,
  briefs,
  fiscalPeriods,
  projectFiscalPeriods,
  isLoading,
}) => {
  const { groups, budgets, programs } = usePayment();
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isSinglePayment = paymentDetails.length === 1;
  const totalPaymentSum = isSinglePayment
    ? paymentDetails[0]?.amount
    : paymentDetails.reduce((sum, payment) => sum + payment.amount, 0);
  // Reset period selection when period data changes
  useEffect(() => {
    if (fiscalPeriods.length > 0) {
      setEditPayments((prev) => ({
        ...prev,
        periodState: { ...prev.periodState, other: { key: null, id: null } },
      }));
    }
  }, [fiscalPeriods, setEditPayments]);

  useEffect(() => {
    if (projectFiscalPeriods.length > 0) {
      setEditPayments((prev) => ({
        ...prev,
        periodState: { ...prev.periodState, project: { key: null, id: null } },
      }));
    }
  }, [projectFiscalPeriods, setEditPayments]);

  // Reset budget ID when budget source changes to PROJECT
  useEffect(() => {
    if (editPayments.budgetSource === BudgetSource.PROJECT) {
      setEditPayments((prev) => ({
        ...prev,
        budgetId: null,
      }));
    }
  }, [editPayments.budgetSource, setEditPayments]);

  // Add new useEffect to handle budget source based on projectId
  useEffect(() => {
    if (editPayments.mode === Mode.EDIT && !editPayments.projectId) {
      setEditPayments((prev) => ({
        ...prev,
        budgetSource: BudgetSource.OTHER
      }));
    }
  }, [editPayments.mode, editPayments.projectId, setEditPayments]);

  // Don't render anything if paymentDetails is null or empty
  if (!paymentDetails || paymentDetails.length === 0) {
    return null;
  }

  // Extract selected values for display in VIEW mode
  const selectedGroup = isSinglePayment ? paymentDetails[0]?.paymentGroup : null;
  const selectedProject = isSinglePayment ? paymentDetails[0]?.project : null;
  const selectedBudget = isSinglePayment ? paymentDetails[0]?.budgets : null;
  const selectedBrief = isSinglePayment ? paymentDetails[0]?.brief : null;

  // Handler functions for select changes
  const handleGroupChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      groupId: value ? parseInt(value, 10) : null,
    }));
  };

  const handleProjectChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      projectId: value ? parseInt(value, 10) : null,
    }));
  };

  const handleBriefChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      briefId: value ? parseInt(value, 10) : null,
    }));
  };

  const handleBudgetChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      budgetId: value ? parseInt(value, 10) : null,
    }));
  };

  const handleBudgetSourceChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      budgetSource: value as BudgetSource,
    }));
  };

  const handlePeriodChange = (value: string) => {
    const selectedPeriod = fiscalPeriods.find((period) => period.key === value);

    setEditPayments((prev) => ({
      ...prev,
      periodState: {
        ...prev.periodState,
        other: { key: value, id: selectedPeriod?.id || null },
      },
    }));
  };

  const handleProjectPeriodChange = (value: string) => {
    const selectedPeriod = projectFiscalPeriods.find((period) => period.key === value);

    setEditPayments((prev) => ({
      ...prev,
      periodState: {
        ...prev.periodState,
        project: { key: value, id: selectedPeriod?.id || null },
      },
    }));
  };

  // Format date helper function
  const formatDate = (dateString: string | number | null | undefined): string => {
    if (!dateString) return '-';

    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    } catch (e) {
      return String(dateString);
    }
  };

  // Toggle edit mode handler
  const toggleEditMode = () =>
    setEditPayments((prev) => ({ ...prev, mode: prev.mode === Mode.VIEW ? Mode.EDIT : Mode.VIEW }));

  return (
    <section className="w-full">
      <div className="flex justify-between items-center">
        <p className="m-0 text-sm font-medium">Connections</p>
        <button
          type="button"
          className="flex items-center gap-1 hover:cursor-pointer text-grey-6 hover:text-grey-6 transition-colors"
          onClick={toggleEditMode}
        >
          <PencilIcon className="h-3 w-3 text-grey-6" />
          {editPayments.mode === Mode.VIEW ? 'Edit' : 'Cancel Edit'}
        </button>
      </div>

      <Separator className="my-2" />

      {isLoading ? (
        <div className="flex justify-center items-center h-full w-full">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {/* Group Selection */}
          <ConnectionField
            label="Group"
            editMode={editPayments.mode === Mode.EDIT}
            value={selectedGroup?.name || '-'}
            editComponent={
              <Select value={editPayments.groupId?.toString() || ''} onValueChange={handleGroupChange}>
                <SelectTrigger className="w-[170px] h-7 text-sm">
                  <SelectValue placeholder="Select Group" />
                </SelectTrigger>
                <SelectContent className="z-[9999]">
                  <SelectGroup>
                    {groups.map((group) => (
                      <SelectItem key={group.id} value={group.id.toString()}>
                        {group.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
          />

          {/* Project Selection */}
          <ConnectionField
            label="Project"
            editMode={editPayments.mode === Mode.EDIT}
            value={selectedProject?.name || '-'}
            editComponent={
              <Select value={editPayments.projectId?.toString() || ''} onValueChange={handleProjectChange}>
                <SelectTrigger className="w-[170px] h-7 text-sm">
                  <SelectValue placeholder="Select Project" />
                </SelectTrigger>
                <SelectContent className="z-[9999]">
                  <SelectGroup>
                    {programs.map((program) => (
                      <SelectItem key={program.id} value={program.id.toString()}>
                        {program.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
          />

          {/* Brief Selection */}
          {!isSinglePayment ? (
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Brief</span>
              <span className="text-xs text-yellow-5 font-normal">
                Connected automatically if only one brief is present
              </span>
            </div>
          ) : (
            <ConnectionField
              label="Brief"
              editMode={editPayments.mode === Mode.EDIT}
              value={selectedBrief?.createdDate ? formatDate(selectedBrief.createdDate) : '-'}
              editComponent={
                <Select value={editPayments.briefId?.toString() || ''} onValueChange={handleBriefChange}>
                  <SelectTrigger className="w-[170px] h-7 text-sm">
                    <SelectValue placeholder="Select Brief" />
                  </SelectTrigger>
                  <SelectContent className="z-[9999]">
                    <SelectGroup>
                      {briefs.length > 0 ? (
                        briefs.map((brief, index) => (
                          <SelectItem key={index} value={brief.id?.toString() || index.toString()}>
                            {brief?.created_at ? formatDate(brief.created_at * 1000) : `Brief ${index + 1}`}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem disabled value="no-brief">
                          No Brief
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              }
            />
          )}

          {/* Only show budget section if budget allocation is enabled */}
          {isBudgetAllocationEnabled && (
            <>
              {/* Budget Source Selection */}
              <ConnectionField
                label="Assigned to Budget"
                editMode={editPayments.mode === Mode.EDIT}
                value={selectedBudget?.map((budget) => budget.name).join(', ') || '-'}
                editComponent={
                  <RadioGroup
                    value={editPayments.budgetSource}
                    onValueChange={handleBudgetSourceChange}
                    className="flex flex-row space-x-4"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value={BudgetSource.PROJECT}
                        id="project"
                        disabled={!editPayments.projectId || !isSinglePayment}
                      />
                      <Label
                        htmlFor="project"
                        className={cn(!editPayments.projectId && 'text-gray-400 cursor-not-allowed')}
                      >
                        Project's Budget
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value={BudgetSource.OTHER} id="other" />
                      <Label htmlFor="other">Others</Label>
                    </div>
                  </RadioGroup>
                }
              />

              {/* Budget settings in edit mode */}
              {editPayments.mode === Mode.EDIT && (
                <div className="flex flex-col gap-3 border rounded-lg p-3 mt-1">
                  {/* Budget Account Selection */}
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">Budget Account</span>
                    <Select
                      value={editPayments.budgetId?.toString() || ''}
                      onValueChange={handleBudgetChange}
                      disabled={editPayments.budgetSource === BudgetSource.PROJECT}
                    >
                      <SelectTrigger className="w-[170px] h-7 text-sm">
                        <SelectValue
                          placeholder={
                            editPayments.budgetSource === BudgetSource.PROJECT ? 'Using Project Budget' : 'Select Budget'
                          }
                        />
                      </SelectTrigger>
                      <SelectContent className="z-[9999]">
                        <SelectGroup>
                          {budgets.map((budget) => (
                            <SelectItem key={budget.id} value={budget.id.toString()}>
                              {budget.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  {/* Fiscal Period Selection */}
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">Fiscal Year Period</span>
                    {editPayments.budgetSource === BudgetSource.PROJECT ? (
                      <PeriodSelector
                        periods={projectFiscalPeriods}
                        selectedKey={editPayments.periodState.project.key}
                        onChange={handleProjectPeriodChange}
                      />
                    ) : (
                      <PeriodSelector
                        periods={fiscalPeriods}
                        selectedKey={editPayments.periodState.other.key}
                        onChange={handlePeriodChange}
                      />
                    )}
                  </div>

                  {/* Total Amount */}
                  <div className="flex justify-between">
                    <span className="text-gray-400">Total Amount</span>
                    <div className="bg-white rounded-lg overflow-hidden">
                      <Input type="number" className="w-[170px] h-7" disabled value={totalPaymentSum} />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};
