import { parseISO, format } from 'date-fns';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Empty } from '@frontend/app/refresh-components';
import { ChartSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Card } from '@frontend/shadcn/components/ui/card';
import { GraphCardHeader, GraphCardTitle, GraphCardContent } from '../../../components/graph-card';
import { formatNumberDefault } from '../../../utils/formatNumber';

export const postsCardFragment = graphql(`
  fragment PostsCard_PublishedPostBreakdown on PublishedPostBreakdown {
    range {
      precision
      startDate
      endDate
    }
    breakdown {
      date
      network
      count
    }
  }
`);

interface PostsCardProps {
  data: FragmentType<typeof postsCardFragment> | null | undefined;
  className?: string;
}

interface GroupedPosts {
  name: string;
  date: Date;
  Instagram: number;
  TikTok: number;
  YouTube: number;
  Pinterest: number;
}

// Parse date string or Date object
const parseDate = (date: string | Date) => {
  return date instanceof Date ? date : parseISO(date);
};

// Default to MONTH if precision is not supported
const getFormatStrategy = (precision: string | null) => {
  const formatStrategies = {
    MONTH: (date: Date) => format(date, 'MMMM yyyy'),
    DAY: (date: Date) => format(date, 'M/d/yyyy'),
  } as const;

  type PrecisionType = keyof typeof formatStrategies;
  return !precision || !(precision in formatStrategies)
    ? formatStrategies.MONTH
    : formatStrategies[precision as PrecisionType];
};

// Case-insensitive mapping of network names
const getNetwork = (network: string) => {
  const networkMap = {
    instagram: 'Instagram',
    tiktok: 'TikTok',
    youtube: 'YouTube',
    pinterest: 'Pinterest',
  } as const;

  return networkMap[network.toLowerCase() as keyof typeof networkMap];
};

const PostsCard = React.forwardRef<HTMLDivElement, PostsCardProps>(({ data, className }, ref) => {
  const fragmentData = useFragment(postsCardFragment, data);
  const range = fragmentData?.range;
  const breakdown = fragmentData?.breakdown;

  let totalPosts = 0;
  const groupedPosts = (() => {
    if (!range || !breakdown) return {};

    const formatStrategy = getFormatStrategy(range.precision);
    return breakdown.reduce((acc, post) => {
      const groupDate = parseDate(post.date);
      const groupKey = formatStrategy(groupDate);

      if (!acc[groupKey]) {
        acc[groupKey] = {
          name: groupKey,
          date: groupDate,
          Instagram: 0,
          TikTok: 0,
          YouTube: 0,
          Pinterest: 0,
        };
      }

      const network = getNetwork(post.network);
      if (!network) {
        console.warn(`Unsupported network: ${post.network}`);
        return acc;
      }

      acc[groupKey][network] += post.count;
      totalPosts += post.count;
      return acc;
    }, {} as Record<string, GroupedPosts>);
  })();

  const chartData = groupedPosts ? Object.values(groupedPosts).sort((a, b) => a.date.getTime() - b.date.getTime()) : [];
  const colors = {
    Instagram: '#27313F', // Dark gray
    TikTok: '#4E617E', // Medium gray
    YouTube: '#8194B1', // Light gray
    Pinterest: '#C0CAD8', // Very light gray
  };

  return (
    <Card ref={ref} className={className} role="region" aria-label="Posts created over time">
      <GraphCardHeader className="border-b">
        <GraphCardTitle>Number of Posts Created</GraphCardTitle>
      </GraphCardHeader>
      <GraphCardContent className="pt-4 flex flex-col justify-between flex-1">
        <div className="text-sm font-medium text-card-foreground pb-4" aria-live="polite" aria-atomic="true">
          {formatNumberDefault(totalPosts)} total posts
        </div>
        <div
          className="h-[300px]"
          role="graphics-document"
          aria-label={`Bar chart showing post distribution across platforms. Total posts: ${formatNumberDefault(
            totalPosts,
          )}`}
        >
          <ResponsiveContainer width="100%" height="100%">
            {
              totalPosts === 0 ? (
                <Empty
                  icon={ChartSimpleIcon}
                  title="No posts created."
                  description="Posts will appear here once they are created."
                  size="lg"
                />
              ) : (
                <BarChart data={chartData} aria-hidden="true">
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} tickMargin={10} tick={{ fontSize: 12 }} />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value: number) => String(formatNumberDefault(value))}
                  />
                  <Tooltip
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        const total = payload.reduce((sum, entry) => sum + (Number(entry.value) || 0), 0);
                        return (
                          <div
                            className="bg-white border border-gray-200 p-3 shadow-lg rounded-lg"
                            role="tooltip"
                            aria-live="polite"
                            aria-atomic="true"
                          >
                            <p className="text-sm font-medium mb-1">{label}</p>
                            {payload.map((entry) => (
                              <p key={entry.name} className="text-sm">
                                <span>{entry.name}</span>: {formatNumberDefault(entry.value as number)}
                              </p>
                            ))}
                            <p className="text-sm font-medium mt-1 pt-1 border-t">Total: {formatNumberDefault(total)}</p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend
                    verticalAlign="bottom"
                    height={36}
                    iconSize={12}
                    iconType="square"
                    wrapperStyle={{
                      paddingTop: '20px',
                      marginTop: '20px',
                      bottom: 0,
                    }}
                    formatter={(value) => (
                      <span className="text-muted-foreground ml-1 text-[14px]" aria-label={`${value} posts distribution`}>
                        {value}
                      </span>
                    )}
                  />
                  {Object.entries(colors).map(([platform, color]) => (
                    <Bar
                      key={platform}
                      dataKey={platform}
                      stackId="platform"
                      fill={color}
                      role="graphics-symbol"
                      aria-label={`${platform} posts`}
                      tabIndex={0}
                      focusable="true"
                    />
                  ))}
                </BarChart>
              )
            }
          </ResponsiveContainer>
        </div>

        {/* Enhanced table for screen readers */}
        <div className="sr-only">
          <table>
            <caption>Post distribution by platform and time period</caption>
            <thead>
              <tr>
                <th scope="col">Time Period</th>
                <th scope="col">Instagram Posts</th>
                <th scope="col">TikTok Posts</th>
                <th scope="col">YouTube Posts</th>
                <th scope="col">Pinterest Posts</th>
                <th scope="col">Total Posts</th>
              </tr>
            </thead>
            <tbody>
              {chartData.map((data) => {
                const periodTotal =
                  +(data.Instagram || 0) + +(data.TikTok || 0) + +(data.YouTube || 0) + +(data.Pinterest || 0);
                return (
                  <tr key={data.name}>
                    <th scope="row">{data.name}</th>
                    <td>
                      {formatNumberDefault(data.Instagram)}({Math.round((+(data.Instagram || 0) / periodTotal) * 100)}%)
                    </td>
                    <td>
                      {formatNumberDefault(data.TikTok)}({Math.round((+(data.TikTok || 0) / periodTotal) * 100)}%)
                    </td>
                    <td>
                      {formatNumberDefault(data.YouTube)}({Math.round((+(data.YouTube || 0) / periodTotal) * 100)}%)
                    </td>
                    <td>
                      {formatNumberDefault(data.Pinterest)}({Math.round((+(data.Pinterest || 0) / periodTotal) * 100)}%)
                    </td>
                    <td>{formatNumberDefault(periodTotal)}</td>
                  </tr>
                );
              })}
              <tr>
                <th scope="row">Total</th>
                <td>{formatNumberDefault(chartData.reduce((sum, data) => sum + +(data.Instagram || 0), 0))}</td>
                <td>{formatNumberDefault(chartData.reduce((sum, data) => sum + +(data.TikTok || 0), 0))}</td>
                <td>{formatNumberDefault(chartData.reduce((sum, data) => sum + +(data.YouTube || 0), 0))}</td>
                <td>{formatNumberDefault(chartData.reduce((sum, data) => sum + +(data.Pinterest || 0), 0))}</td>
                <td>{formatNumberDefault(totalPosts)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Summary for screen readers */}
        <div className="sr-only" aria-live="polite">
          <h3>Key Insights:</h3>
          <ul>
            <li>Total posts across all platforms: {formatNumberDefault(totalPosts)}</li>
            <li>
              Most active platform:{' '}
              {
                Object.entries(colors).reduce(
                  (max, [platform]) => {
                    const platformTotal = chartData.reduce((sum, data) => {
                      const value = data[platform as keyof GroupedPosts];
                      return typeof value === 'number' ? sum + value : sum;
                    }, 0);
                    return platformTotal > max.total ? { platform, total: platformTotal } : max;
                  },
                  { platform: '', total: 0 },
                ).platform
              }
            </li>
            <li>
              Time period:{' '}
              {chartData.length > 0
                ? `${chartData[0].name} to ${chartData[chartData.length - 1].name}`
                : 'No data available'}
            </li>
          </ul>
        </div>
      </GraphCardContent>
    </Card>
  );
});

PostsCard.displayName = 'PostsCard';

export default PostsCard;
