import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@frontend/shadcn/components/ui/table';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { GetSocialAnalyticsPostsQuery } from '@frontend/gql/social/graphql';
import { SocialMediaThumbnail } from '../../../components/thumbnail/SocialMediaThumbnail';
import { formatNumberDefault } from '../../../utils/formatNumber';

const THUMBNAIL_LIMIT = 20;

interface PostsTabProps {
  data: GetSocialAnalyticsPostsQuery | undefined;
  isLoading: boolean;
  isPartialLoading: boolean;
}

export const PostsTab = ({ data, isLoading, isPartialLoading }: PostsTabProps) => {
  const posts = data?.client?.socialAnalytics?.posts?.results || [];

  if (isLoading && !isPartialLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <H3>Posts Analytics</H3>
      <P className="mb-4">
        Analyze the performance of your social media posts. Track engagement metrics, reach, and impact across different
        content types and platforms. Total posts: {formatNumberDefault(posts.length)}
      </P>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Preview</TableHead>
              <TableHead>Network</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Member</TableHead>
              <TableHead>Reach</TableHead>
              <TableHead>Impressions</TableHead>
              <TableHead>Engagements</TableHead>
              <TableHead>Likes</TableHead>
              <TableHead>Comments</TableHead>
              <TableHead>Views</TableHead>
              <TableHead>Saves</TableHead>
              <TableHead>Shares</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {posts.map((post, index) => (
              <TableRow key={post.entityId}>
                <TableCell className="p-2">
                  <div className="w-[100px] h-[100px] overflow-hidden flex items-center justify-center bg-gray-50">
                    {index < THUMBNAIL_LIMIT && (
                      <SocialMediaThumbnail
                        url={post.postPermalink}
                        width="100%"
                        height="100%"
                        className="rounded-md object-contain"
                        alt={`${post.member?.name || 'Unknown'} - ${post.entityType} on ${post.entityNetwork}`}
                        loadThumbnail={index < THUMBNAIL_LIMIT}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell>{post.entityNetwork}</TableCell>
                <TableCell>{post.entityType}</TableCell>
                <TableCell>
                  <a
                    href={post.postPermalink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    {post.member?.name}
                  </a>
                </TableCell>
                <TableCell>{formatNumberDefault(post.total?.reach)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.impressions)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.engagements)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.likes)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.comments)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.views)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.saves)}</TableCell>
                <TableCell>{formatNumberDefault(post.total?.shares)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
