import * as React from 'react';
import cx from 'classnames';

import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { debounce, isEmpty, isFunction } from 'lodash';
import { Drawer } from '@frontend/applications/Shared/components/Drawer';
import {
  GetApplicationsByIds_applications as IApplication,
} from '@frontend/app/queries/types/GetApplicationsByIds';
import { useApplicationHeaderVariation, useGetTermsName } from '@frontend/app/hooks';
import termsWizardStyles from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard.scss';

import { gql, useApolloClient } from '@apollo/client';
import {
  ApplicationContainer,
  IApplicationContainerHandle,
  IBulkActionParameters,
  IWorkflowActionParameters,
} from './ApplicationContainer';
import { ApplicationHeader } from './ApplicationHeader';
import { ConfirmationModal } from './ConfirmationModal';

import styles from './ApplicationDrawer.scss';
import { Task, WorkletSpecUri } from '../Projects/constants';

const {
 useState, useEffect, useRef, useCallback, useMemo,
} = React;

interface IProps {
  className?: string;
  application: IApplication;
  onApplicationClose?: () => void;
  memberId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepLinkParameters?: any;
  bulkActionParameters?: IBulkActionParameters;
  workflowActionParameters?: IWorkflowActionParameters;
  paymentAppPaddingEnabled?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const ApplicationDrawer: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    application, memberId, onApplicationClose, workflowActionParameters, paymentAppPaddingEnabled = false,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const workFlowAction = props.deepLinkParameters?.action;
  const apolloClient = useApolloClient();
  const memberData = apolloClient.readFragment<{ name: string }>({
    id: `Member:${workflowActionParameters?.memberIds[0]}`,
    fragment: gql`
      fragment MemberData on Member {
        name
      }
    `,
  });
  const applicationDrawerVariation = useApplicationHeaderVariation(workFlowAction, workFlowAction === 'review_order_request' ? memberData?.name : '');
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const containerRef = useRef<IApplicationContainerHandle>(null);

  const history = useHistory();

  const isSendingTerms = useMemo(
    () => ['send_terms', 'send_bulk_term', 'fix_terms'].includes(workFlowAction),
    [workFlowAction],
  );

  const isViewTerms = useMemo(
    () => workFlowAction === 'view_terms',
    [workFlowAction],
  );

  const isUpdatingMember = useMemo(
    () => ['confirm_address'].includes(workFlowAction),
    [workFlowAction],
  );
  const { pluralTermsName } = useGetTermsName();
  const applicationName = useMemo(
    () => {
      if (isSendingTerms) {
        return `Send ${pluralTermsName}`;
      }
      return application?.name === 'Terms' ? pluralTermsName : application?.name;
    },
    [application?.name, isSendingTerms, pluralTermsName],
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const closeModalConfirmed = useCallback(() => {
    const regex = new RegExp(`/app/${application.id}.*`);
    const newPath = history.location.pathname.replace(regex, '');
    history.push({ ...location, pathname: newPath });
  }, [history, application]);

  const closeModal = useCallback(() => {
    const showWarningOnClose = containerRef.current?.showWarningOnClose();

    if (!showWarningOnClose) {
      closeModalConfirmed();
    } else {
      setShowConfirm(true);
    }

    if (isFunction(onApplicationClose)) {
      onApplicationClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeModalConfirmed,
    onApplicationClose,
    containerRef,
  ]);

  useHotkeys('esc', () => {
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    // This is to prevent modal from being in invalid state on refresh
    if (!props.memberId && !props.bulkActionParameters && !props.workflowActionParameters) {
      closeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      setDimensions((dimensions) => {
        if (window.innerHeight === dimensions.height && window.innerWidth === dimensions.width) {
          return dimensions;
        }
        return { height: window.innerHeight, width: window.innerWidth };
      });
    },
    150,
    { trailing: true }),
    [setDimensions],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const [title, setTitle] = useState<React.ReactElement>();

  useEffect(() => {
    if (!isEmpty(applicationDrawerVariation)) {
      if (workflowActionParameters) {
        const { workletSpecUri, taskId } = workflowActionParameters;
        if (taskId === Task.SendOrderRequestTaskV2 && workletSpecUri === WorkletSpecUri.SendBrandProductCatalogWorkletSpecification) {
          applicationDrawerVariation.name = 'Send Product Catalog';
        }
      }
      setTitle(<ApplicationHeader
        goBack={goBack}
        closeModal={closeModal}
        {...applicationDrawerVariation}
      />);
    } else if (application) {
      setTitle(<ApplicationHeader
        applicationId={application.id}
        iconUrl={application.icon}
        name={applicationName}
        goBack={goBack}
        closeModal={closeModal}
      />);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    application,
    goBack,
    closeModal,
    applicationDrawerVariation,
    applicationName,
  ]);

  const setTitleChange = useCallback(
    (title) => setTitle(
      <ApplicationHeader
        applicationId={application.id}
        iconUrl={application.icon}
        name={title}
        goBack={goBack}
        closeModal={closeModal}
      />,
    ),
    [setTitle, application?.id, application?.icon, goBack, closeModal],
  );

  const isSendingTermsOrViewTerms = useMemo(
    () => (isSendingTerms || isViewTerms),
    [isSendingTerms, isViewTerms],
  );

  const addCustomPaymentPadding = useMemo(
    () => paymentAppPaddingEnabled && applicationName === 'Payment',
    [applicationName, paymentAppPaddingEnabled],
  );
  return (
    <>
      <Drawer
        visible={!!application}
        closable={false}
        maskClosable
        title={title}
        onClose={closeModal}
        width={dimensions.width < 1000 ? 800 : 1000}
        destroyOnClose
        className={cx({
          [styles.drawer]: isSendingTermsOrViewTerms,
          [styles.unpaddedDrawer]: isUpdatingMember,
          [styles.paymentAppPadding]: addCustomPaymentPadding,
        })}
      >
        {!!application
          && (
          <div
            className={cx(styles.ApplicationDrawer, {
              [termsWizardStyles.ApplicationDrawer]: isSendingTermsOrViewTerms,
            })}
          >
            <div className={styles.content}>
              <ApplicationContainer
                ref={containerRef}
                memberId={memberId}
                applicationId={application.id}
                deepLinkParameters={props.deepLinkParameters}
                modalView
                bulkActionParams={props.bulkActionParameters}
                workflowActionParameters={props.workflowActionParameters}
                closeModal={closeModal}
                onSetTitle={setTitleChange}
              />
            </div>
          </div>
      )}
      </Drawer>
      <ConfirmationModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => closeModalConfirmed()}
      />
    </>
  );
});
