import React from 'react';
import { BadgeProps } from '@frontend/shadcn/components/ui/badge';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Tooltip, TooltipTrigger, TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemKey,
  TooltipItemValue,
  TooltipContent,
} from '../../../components/tooltip';
import { DismissableBadge } from './dismissable-badge';
import { NetworkValue } from '../filters-form';

interface NetworkBadgesProps {
  data: Array<{ value: NetworkValue; label: string }>;
  onDismiss: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type CombinedProps = BadgeProps & NetworkBadgesProps;

const NetworkBadges = React.forwardRef<HTMLDivElement, CombinedProps>(({ data, onDismiss, ...props }, ref) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <DismissableBadge
              ref={ref}
              key={data.length > 0 ? data[0].value : 'networks-pill'}
              variant="secondary"
              className="mr-2 mb-2"
              aria-label="Network filter"
              role="button"
              {...props}
            >
              Network:<span className="capitalize ml-1">{data.length > 0 ? data[0].label : 'All'}</span>
              { data.length > 1 && <span className="ml-1">+{data.length - 1}</span> }
              { data.length > 0 && (
                <button
                  type="button"
                  onClick={onDismiss}
                  aria-label="Remove network filter"
                  className="flex ml-1 p-1 focus:outline-none focus:ring"
                >
                  <Cross2Icon className="h-3 w-3" />
                </button>
              )}
              <span className="sr-only">{data.length > 0 ? 'Remove' : 'Add'} network filter for {data.length > 0 ? data[0].label : 'networks'}</span>
            </DismissableBadge>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipHeader>
            <TooltipTitle>Network Filter</TooltipTitle>
          </TooltipHeader>
          <TooltipBody>
            <TooltipItem>
              <TooltipItemKey>Selected Networks</TooltipItemKey>
              <TooltipItemValue>
                {data.length === 0 ? 'All Networks' : data.map((network) => network.label).join(', ')}
              </TooltipItemValue>
            </TooltipItem>
          </TooltipBody>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});

NetworkBadges.displayName = 'NetworkBadges';

export { NetworkBadges };
