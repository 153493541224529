import React from 'react';
import {
 Header, HeaderLayout, HeaderMainContent, HeaderH1, HeaderActions, HeaderTabs,
} from '../../components/header';

interface AnalyticsHeaderProps {
  actions?: React.ReactNode;
  tabs?: React.ReactNode;
}

export const AnalyticsHeader = ({ actions, tabs }: AnalyticsHeaderProps) => (
  <Header>
    <HeaderLayout>
      <HeaderMainContent>
        <HeaderH1>Social Dashboard</HeaderH1>
      </HeaderMainContent>
      <HeaderActions>{actions}</HeaderActions>
    </HeaderLayout>
    <HeaderTabs>{tabs}</HeaderTabs>
  </Header>
  );
