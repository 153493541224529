import React, { useMemo, useState } from 'react';
import { WidgetContainer, DataTable, Empty } from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { cva } from 'class-variance-authority';
import { largeIntegerAbbreviator, largeNumberAbbreviatorWithDecimal } from '@frontend/utils';
import { useGetTopCreators } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetTopCreators';
import { DashboardSortBy } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { ChartUserIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@/shadcn/lib/utils';
import Switcher from '../refresh-components/Switcher';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';
import { EllipsisLabel } from '../refresh-components/EllipsisLabel';

type RowItem = {
  creatorName: string;
  imgUrl?: string;
  totalCount?: number;
  totalCost?: number;
};

const headerVariants = cva(
  'font-semibold text-[#1F1F21] flex items-center h-full -mx-2 -mb-px',
  {
    variants: {
      group: {
        review: 'border-b-2 border-b-[#55A2DF]',
        fulfillment: 'border-b-2 border-b-[#E2AB23]',
        inTransit: 'border-b-2 border-b-[#7D1C53]',
        delivery: 'border-b-2 border-b-[#74965A]',
      },
    },
  },
);

interface PFATopCreatorsTableProps {
  className?: string;
}
export default function PFATopCreatorsTable({ className }: PFATopCreatorsTableProps) {
  const [selectedTab, setSelectedTab] = useState<DashboardSortBy[]>([DashboardSortBy.ORDER]);

  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { topCreators, isTopCreatorsLoading } = useGetTopCreators({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        sortBy: selectedTab[0],
      },
    },
  });

  const data: RowItem[] = useMemo<RowItem[]>(() => topCreators?.map((stat) => ({
      creatorName: stat.name,
      imgUrl: stat.imgUrl,
      totalCount: stat.count,
      totalCost: stat.cost,
    })) || [], [topCreators]);

  const columns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'creatorName',
      header: () => <div className={headerVariants()}>Creator</div>,
      size: 250,
      cell: ({ row }) => {
        const projectName = row.getValue('creatorName') as string;
        const projectImage = row.original.imgUrl as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[24px] w-[24px] rounded-none">
              {projectImage ? (
                <AvatarImage src={projectImage} className="flex h-full w-full items-center justify-center rounded-lg overflow-hidden" />
              ) : (
                <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-sm text-secondary overflow-hidden">
                  <img src="https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png" />
                </AvatarFallback>
              )}
            </Avatar>
            <EllipsisLabel tooltip={projectName} width={220}>{projectName}</EllipsisLabel>
          </div>
        );
      },
    },
    {
      accessorKey: 'totalCount',
      header: () => <div className={headerVariants()}>Orders Count</div>,
      cell: ({ row }) => {
        const totalCount = largeIntegerAbbreviator(row.getValue('totalCount'));
        return (
          <div>
            {totalCount.value}
            {totalCount.symbol}
          </div>
        );
      },
    },
    {
      accessorKey: 'totalCost',
      header: () => <div className={headerVariants()}>Cost</div>,
      cell: ({ row }) => {
        const totalCost = largeNumberAbbreviatorWithDecimal(row.getValue('totalCost'));
        return (
          <div>
            $
            {totalCost.value}
            {totalCost.symbol}
          </div>
);
      },
    },
  ];

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );

  const widgetActions = (
    <Switcher
      items={[
        { label: 'Orders', key: DashboardSortBy.ORDER },
        { label: 'Spend', key: DashboardSortBy.COST },
      ]}
      type="single"
      activeKeys={selectedTab}
      onChange={(keys) => setSelectedTab(keys as DashboardSortBy[])}
    />
  );
  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={ChartUserIcon}
        title="No creators yet"
        description="You have not created orders for any creators yet. Start by sending order requests or creating gift orders for them."
      />
    </div>
  );
  const mainContent = (
    <DataTable columns={columns} data={data} maxHeight="471px" />
  );

  return (
    <WidgetContainer
      widgetTitle="Top 25 Creators"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isTopCreatorsLoading ? loadingContent : data.length ? mainContent : emptyContent}
    </WidgetContainer>
  );
}
