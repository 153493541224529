import gql from 'graphql-tag';

export const PROGRAM_FRAGMENT = gql`
  fragment ProgramFragment on Program {
    id
    title
    columns {
      memberFieldSchemaIds
      dbColumns
    }
    applicationFormFields {
      memberFieldSchemas {
        schemaId
        required
        label
      }
      dbColumns {
        name
        required
        label
      }
    }
    onboardingTemplateConfig {
      skipSelectPageTemplate
      intro {
        title
        hero_image
      }
    }
    owner
    customLandingPagePath
    submittedForReview
    specKey
    isFlexibleSpec
    workletSpecKeys
    hasUnpaidOffer
    gcrEnabled
    gcrApproverIds
    status
    applicationPageTemplateName
    archivedDate
    published
    programMetadata {
      programId
      startDate
      endDate
      budget
      budgetTimePeriod
      salesGoal
      salesGoalTimePeriod
      creatorCount
      creatorsTimePeriod
      creatorDescription
      creatorPreference
      contentPrefPostTypes
      compensationMethod
      discountType
      commissionValue
      commissionValueType
      discountValue
      discountValueType
    }
  }
`;
