import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import Bluebird, { CancellationError } from 'bluebird';
import {
 reduce, isNil, isArray, isEmpty, isFunction, isString, filter,
} from 'lodash';

import {
 DEFAULT_GENERIC_ERROR_MSG, endpoints, ISocialAccount, ISearchParams, addEventLog,
} from '@components';

import { searchAPIParamsSelector, imageSearchAPIParamsSelector, defaultImageSearchAPIParamsSelector } from './selectors';
import {
  IConnectSearchPage,
  CreatorSearchVersion,
  SearchTermType,
  FetchFrom,
  SearchType,
  searchResultPageCache,
  IAuth,
} from '../models';

import { FIRST_PAGE } from './middleware';
import { logger } from '@common';

export enum SearchResultsActionTypes {
  RESET_SEARCH_RESULTS = '@connectSearch/RESET_SEARCH_RESULTS',

  FETCH_CREATORS_REQUEST = '@connectSearch/FETCH_CREATORS_REQUEST',
  FETCH_CREATORS_SUCCESS = '@connectSearch/FETCH_CREATORS_SUCCESS',
  FETCH_CREATORS_FAILURE = '@connectSearch/FETCH_CREATORS_FAILURE',

  FETCH_FEATURED_CREATORS = '@connectSearch/FETCH_FEATURED_CREATORS',

  FETCH_NEXT_PAGE = '@connectSearch/FETCH_NEXT_PAGE',
  FETCH_PAGE = '@connectSearch/FETCH_PAGE',

  SELECT_TEXT_SEARCH = '@connectSearch/SELECT_TEXT_SEARCH',
  SELECT_IMAGE_SEARCH = '@connectSearch/SELECT_IMAGE_SEARCH',

  APPLY_SEARCH_FILTERS = '@connectSearch/APPLY_SEARCH_FILTERS',

  REFETCH_CREATORS_REQUEST = '@connectSearch/REFETCH_CREATORS_REQUEST',
  REFETCH_CREATORS_SUCCESS = '@connectSearch/REFETCH_CREATORS_SUCCESS',
  REFETCH_CREATORS_FAILURE = '@connectSearch/REFETCH_CREATORS_FAILURE',
}

const CREATOR_SEARCH_HANDLED_ERRORS = [
  'RateLimitExceededSearchError',
  'CloudSearchRequestSizeLimitError',
];

export interface ISearchResultsAction {
  type: SearchResultsActionTypes;
  payload?: {
    socialAccounts?: ISocialAccount[];
    page?: number;
    hasNext?: boolean;
    count?: number;
    appendResults?: boolean;
    isImageSearch?: boolean;
    isFeaturedSearch?: boolean;
    query?: string;
    promise?: Bluebird<any>;
    version?: CreatorSearchVersion;
    fetchedSearchTermType?: SearchTermType;
  };
  meta?: {
    error?: Error;
    errorMessage?: string;
  };
}

type SRThunkAction = ThunkAction<void, IConnectSearchPage, unknown, ISearchResultsAction>;

export const fetchFeaturedCreators = (version?: CreatorSearchVersion) => ({
  type: SearchResultsActionTypes.FETCH_FEATURED_CREATORS,
  payload: {
    version,
  }
});

export const applySearchFilters = () => ({
  type: SearchResultsActionTypes.APPLY_SEARCH_FILTERS,
  payload: {},
});

export const fetchPage = (page: number) => ({
  type: SearchResultsActionTypes.FETCH_PAGE,
  payload: {
    page,
  },
});

export const fetchNextPage = () => ({
  type: SearchResultsActionTypes.FETCH_NEXT_PAGE,
  payload: {},
});

export const selectTextSearch = () => ({
  type: SearchResultsActionTypes.SELECT_TEXT_SEARCH,
});

export const selectImageSearch = () => ({
  type: SearchResultsActionTypes.SELECT_IMAGE_SEARCH,
});

const isNonEmptyValue = (value: any): boolean =>
  !isNil(value) && !(isArray(value) && isEmpty(value)) && !(isString(value) && !value);

const fetchFeaturedSocialAccounts = (
  apiEndpoint: string,
  params: ISearchParams,
  page: number = 0,
  auth: IAuth,
): Promise<Response> => {
  const query = reduce(
    params,
    (result, value, key) => {
      if (isNonEmptyValue(value)) {
        result += `&${key}=${value}`;
      }
      return result;
    },
    '',
  );

  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (auth?.token) {
    headers.set('Authorization', `Bearer ${auth.token}`);
  }

  return fetch(
    `${apiEndpoint}/${endpoints.socialAccountEndpoint}?featured=true&page=${page}${query}`,
    {
      method: 'GET',
      headers,
    },
  );
};

const fetchSocialAccountsImageSearch = (
  apiEndpoint: string,
  params: ISearchParams,
  page: number = 0,
  auth?: IAuth,
): Promise<Response> => {

  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (auth?.token) {
    headers.set('Authorization', `Bearer ${auth.token}`);
  }

  return fetch(`${apiEndpoint}/${endpoints.socialAccountImageSearchEndpoint}?search_engine=${params.version}`, {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      page,
    }),
    headers,
  });
};

const fetchSocialAccountsSearch = (
  apiEndpoint: string,
  params: ISearchParams,
  page: number = 0,
): Promise<Response> => fetch(`${apiEndpoint}/${endpoints.socialAccountSearchEndpoint}?search_engine=${params.version}`, {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      page,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

export const refetchCreators = (
  apiEndpoint: string,
  socialAccountsIds: number[],
  hasEmail: boolean
): SRThunkAction => async (dispatch, getState) => {
  try {
    const state = getState();
    const { auth } = state;
    const headers = auth?.token ? {
      'Authorization': `Bearer ${auth.token}`,
    } : {};

    const response = await axios.post(`${apiEndpoint}/${endpoints.socialAccountSearchEndpoint}/social_account`, {
      account_ids: socialAccountsIds,
      has_email: hasEmail,
    }, {
      headers,
    });

    const refreshedSocialAccounts = response.data.data;

    dispatch({
      type: SearchResultsActionTypes.REFETCH_CREATORS_SUCCESS,
      payload: {
        socialAccounts: refreshedSocialAccounts,
      }
    });
  } catch {
    dispatch({
      type: SearchResultsActionTypes.REFETCH_CREATORS_FAILURE,
    });
  }
};

export const fetchCreators = (
  page: number,
  featured: boolean,
  isImageSearch: boolean,
  resetResults = true,
  appendResults = false,
  version = CreatorSearchVersion.v2,
  fetchFrom: FetchFrom = FetchFrom.ApplyFilters,
): SRThunkAction => async (dispatch, getState) => {
  if (fetchFrom !== FetchFrom.Pagination) {
    searchResultPageCache.clear();
  }

  let shouldFallbackToTextSearch = false;

  if (resetResults) {
    dispatch({
      type: SearchResultsActionTypes.RESET_SEARCH_RESULTS,
    });
  }

  const state = getState();

  const {
    auth,
    external: { apiEndpointV1: apiEndpoint },
    searchResults: { _promise: runningPromise },
  } = state;

  if (isFunction(runningPromise?.cancel)) {
    runningPromise.cancel();
  }

  let promise;
  let params;
  let searchType;
  const startTime = performance.now();
  if (featured) {
    if (state.external.clientId) {
      if (state.external.defaultImageSearchUrl) {
        searchType = SearchType.ImageSearch;
        shouldFallbackToTextSearch = true;
        params = defaultImageSearchAPIParamsSelector(state);
        if (!searchResultPageCache.has(page, searchType, fetchFrom)) {
          promise = fetchSocialAccountsImageSearch(apiEndpoint, params, page, auth);
        }
      } else {
        // Fall back to regular search with empty params.
        searchType = SearchType.TextSearch;
        params = searchAPIParamsSelector(state);
        if (!searchResultPageCache.has(page, searchType, fetchFrom)) {
          promise = fetchSocialAccountsSearch(apiEndpoint, params, page);
        }
      }
    } else {
      SearchType.Featured;
      params = searchAPIParamsSelector(state);
      if (!searchResultPageCache.has(page, searchType, fetchFrom)) {
        promise = fetchFeaturedSocialAccounts(apiEndpoint, params, page, auth);
      }
    }
  } else if (isImageSearch) {
    searchType = SearchType.ImageSearch;
    params = imageSearchAPIParamsSelector(state);
    if (!searchResultPageCache.has(page, searchType, fetchFrom)) {
      promise = fetchSocialAccountsImageSearch(apiEndpoint, params, page, auth);
    }
  } else {
    searchType = SearchType.TextSearch;
    params = searchAPIParamsSelector(state);
    if (!searchResultPageCache.has(page, searchType, fetchFrom)) {
      promise = fetchSocialAccountsSearch(apiEndpoint, params, page);
    }
  }

  if (promise) {
    promise = Bluebird.resolve(promise);

    dispatch({
      type: SearchResultsActionTypes.FETCH_CREATORS_REQUEST,
      payload: {
        isImageSearch,
        isFeaturedSearch: featured,
        promise,
      },
    });
  }

  let count;
  try {
    let json;
    if (promise) {
      const resp = await promise;
      json = await resp.json();
      searchResultPageCache.add(page, searchType, fetchFrom, json);
    } else {
      json = searchResultPageCache.get(page, searchType);
    }

    if (json.status && json.status.code === 200) {
      const socialAccounts = json.data.data || [];
      count = json.data.count || socialAccounts.length;

      /** Get social accounts that have to be refetched */
      const accountsToBeRefecthed = filter(socialAccounts, 'refetch');

      dispatch({
        type: SearchResultsActionTypes.REFETCH_CREATORS_REQUEST,
        payload: {
          socialAccounts: accountsToBeRefecthed,
        }
      });

      const hasNext = json.data.has_next;

      dispatch({
        type: SearchResultsActionTypes.FETCH_CREATORS_SUCCESS,
        payload: {
          socialAccounts,
          hasNext,
          count,
          page,
          appendResults,
          query: state.textSearch.query,
          fetchedSearchTermType: state.textSearch.termType,
        },
      });
    } else if (CREATOR_SEARCH_HANDLED_ERRORS.includes(json.status?.error_type)) {
      throw new Error(json.status.error_msg);
    } else {
      throw new Error(DEFAULT_GENERIC_ERROR_MSG);
    }
  } catch (err) {
    if (err instanceof CancellationError) {
      // Ignore cancellation errors.
      return;
    }

    const endTime = performance.now();
    const endTimeOfApi = (endTime - startTime) / 1000;
    if (endTimeOfApi >= 40) {
      // We want to ignore timeout error when api goes over 40 seconds.
      // this will be kept until we can solve the timeout issue
      logger.error('backend server timed out')
      return;
    }


    if (shouldFallbackToTextSearch) {
      dispatch(fetchCreators(
        FIRST_PAGE,
        false,
        false,
        resetResults,
        appendResults,
        version,
        fetchFrom,
      ))
    } else {
      dispatch({
        type: SearchResultsActionTypes.FETCH_CREATORS_FAILURE,
        meta: {
          error: err,
          errorMessage: err.message,
        },
      });
    }
  }

  addEventLog('search', {
    term: params.query,
    count,
    network: params.network_type,
    image_search: isImageSearch,
    is_image_search: isImageSearch,
  });
};
