import React, { useMemo } from 'react';
import {
 WidgetContainer, DataTable, BadgeGroup, Empty,
} from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';

import {
  FileDown,
} from 'lucide-react';
import { ExportToCsv } from 'export-to-csv';
import { cva } from 'class-variance-authority';
import { useGetBrandCatalogDashboardStats } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetBrandCatalogDashboardStats';
import { useHistory } from 'react-router-dom';
import { ArrowRightLongIcon, BoxesStackedIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { cn } from '@/shadcn/lib/utils';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';
import { ProductFulfillmentDashboardTabEnum } from '../containers/ProductFulfillmentDashboard/constants';
import { EllipsisLabel } from '../refresh-components/EllipsisLabel';

type RowItem = {
  catalogId: number;
  catalogName: string;
  programNames: string[];
  sentToMembers: number;
  ordersCreated?: number;
  totalSpent?: number;
  totalCollections: number;
  totalProducts: number;
  totalVariants: number;
};

const headerVariants = cva(
  'font-semibold text-[#1F1F21] flex items-center h-full -mx-2 -mb-px',
  {
    variants: {
      group: {
        view: 'justify-center',
      },
    },
  },
);

interface PFACatalogTableProps {
  className?: string;
}
export default function PFACatalogTable(props: PFACatalogTableProps) {
  const { className } = props;

  const history = useHistory();

  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { brandCatalogDashboardStats, isBrandCatalogDashboardStatsLoading } = useGetBrandCatalogDashboardStats({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const data: RowItem[] = useMemo<RowItem[]>(() => brandCatalogDashboardStats?.map((stat) => ({
    catalogId: stat.catalogId,
    catalogName: stat.catalogName,
    programNames: stat.programNames,
    sentToMembers: stat.sentToMembers,
    ordersCreated: stat.ordersCreated,
    totalSpent: stat.totalSpent,
    totalCollections: stat.totalCollections,
    totalProducts: stat.totalProducts,
    totalVariants: stat.totalVariants,
  })) || [], [brandCatalogDashboardStats]);

  const exportBudgetData = () => {
    const options = {
      headers: ['Catalog Name', 'Program Names', 'Sent To Members', 'Orders Created', 'Total Spent', 'Total Collections', 'Total Products', 'Total Variants'],
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Brand Catalog Dashboard Stats',
    };

    const csvExporter = new ExportToCsv(options);
    const dataToExport = data.map((projectData) => ({
      catalogId: projectData.catalogId,
      catalogName: projectData.catalogName,
      programNames: projectData.programNames.join(', '),
      sentToMembers: projectData.sentToMembers,
      ordersCreated: projectData.ordersCreated,
      totalSpent: projectData.totalSpent,
      totalCollections: projectData.totalCollections,
      totalProducts: projectData.totalProducts,
      totalVariants: projectData.totalVariants,
    }));

    csvExporter.generateCsv(dataToExport);
  };

  const columns: ColumnDef<RowItem>[] = [
    {
      id: 'catalogName',
      accessorKey: 'catalogName',
      header: () => <div className={headerVariants()}>Catalog</div>,
      size: 250,
      cell: ({ row }) => {
        const catalogName = row.getValue('catalogName') as string;
        return (
          <EllipsisLabel tooltip={catalogName} width={220}>{catalogName}</EllipsisLabel>
        );
      },
    },
    {
      accessorKey: 'programNames',
      header: () => <div className={headerVariants()}>Projects Used in</div>,
      size: 250,
      cell: ({ getValue }) => <BadgeGroup items={getValue() as string[] || []} />,
    },
    {
      accessorKey: 'sentToMembers',
      header: () => <div className={headerVariants()}>Sent to Members</div>,
      cell: ({ row }) => {
        const value = row.getValue('sentToMembers') as string;
        return <div>{value}</div>;
      },
    },
    {
      accessorKey: 'ordersCreated',
      header: () => <div className={headerVariants()}>Orders Created</div>,
      cell: ({ row }) => {
        const value = row.getValue('ordersCreated') as string;
        return <div>{value}</div>;
      },
    },
    {
      accessorKey: 'totalCollections',
      header: () => (
        <div
          className={headerVariants()}
        >
          Collections
        </div>
      ),
      cell: ({ row }) => {
        const value = row.getValue('totalCollections') as string;
        return <div>{value}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-subtitle',
        },
      },
    },
    {
      accessorKey: 'totalProducts',
      header: () => (
        <div
          className={headerVariants()}
        >
          Products
        </div>
      ),
      cell: ({ row }) => {
        const value = row.getValue('totalProducts') as string;
        return <div>{value}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-orange-6',
        },
      },
    },
    {
      accessorKey: 'totalVariants',
      header: () => (
        <div
          className={headerVariants()}
        >
          Variants
        </div>
      ),
      cell: ({ row }) => {
        const value = row.getValue('totalVariants') as string;
        return <div>{value}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-orange-6',
        },
      },
    },
    {
      accessorKey: 'totalSpent',
      header: () => <div className={headerVariants()}>Spend</div>,
      cell: ({ row }) => {
        const value = row.getValue('totalSpent') as string;
        if (!value) {
          return null;
        }
        return (
          <div>
            {Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(value))}
          </div>
);
      },
    },
    {
      id: 'view',
      accessorKey: 'catalogId',
      header: () => <div className={headerVariants({ group: 'view' })}>View</div>,
      size: 80,
      cell: ({ row }) => {
        const catalogId = row.original.catalogId;

        if (!catalogId) {
          return null;
        }

        const params = new URLSearchParams(location.search);
        params.set('catalogId', catalogId.toString());
        params.set('tab', ProductFulfillmentDashboardTabEnum.Orders);

        return (
          <div className="flex items-center justify-center">
            <Button
              size="xs"
              variant="outline"
              onClick={() => {
                history.push({ search: params.toString() });
              }}
              className="w-6 px-0"
              data-tooltip-id={`pfa-ff-tooltip-${catalogId}`}
              data-tooltip-content="View Catalog Orders"
            >
              <ArrowRightLongIcon />
            </Button>
            <ReactTooltip
              id={`pfa-ff-tooltip-${catalogId}`}
              place="top"
            />
          </div>
        );
      },
    },
  ];

  const widgetActions = (
    <div className="flex items-center gap-2">
      <Button size="icon" variant="outline" onClick={exportBudgetData}>
        <FileDown className="h-4 w-4" />
      </Button>
    </div>
  );

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={BoxesStackedIcon}
        title="No catalogs created"
        description="There are no catalogs or products created yet. Start by adding a catalog to track product usage and inventory."
      />
    </div>
  );
  const mainContent = (
    <DataTable
      columns={columns}
      data={data}
      maxHeight="471px"
      columnPinning={{
        left: ['catalogName'],
        right: ['view'],
      }}
    />
  );

  return (
    <WidgetContainer
      widgetTitle="Catalog Stats"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isBrandCatalogDashboardStatsLoading ? loadingContent : data.length ? mainContent : emptyContent}
    </WidgetContainer>
  );
}
