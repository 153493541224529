import gql from 'graphql-tag';

const GET_PAYMENTS_QUERY = gql`
  query GetPaymentsQuery($filters: PaymentDashboardFilterInput!) {
    payments: getPaymentDashboardTableData(filters: $filters) {
      total
      page
      pageSize
      totalPages
      data {
        id
        paymentId
        member {
          id
          name
        }
        paymentDate
        amount
        status
        paypalEmail
        project {
          id
          name
        }
        type
        budgets {
          id
          name
        }
        tags {
          id
          name
        }
        initiatedBy
        notes
      }
    }
  }
`;

export default GET_PAYMENTS_QUERY;
