import React, { createContext, useContext, useState, useCallback, ReactNode, useEffect } from 'react';
import { SortingState } from '@tanstack/react-table';
import { useDateFilterSettings } from '@frontend/app/hooks';
import { DateRangeOptions, IDateRangeSettings } from '@frontend/app/components';
import { GetUsersQuery_users } from '@frontend/app/queries/types/GetUsersQuery';
import { GetAllPrograms_programs } from '@frontend/app/queries/types/GetAllPrograms';
import { GetTagsQuery_tags } from '@frontend/app/queries/types/GetTagsQuery';
import { GetBudgetAccountsQuery_budgetAccounts } from '@frontend/app/queries/types/GetBudgetAccountsQuery';
import { TagAction, TagEntityType } from '@frontend/app/types/globalTypes';
import { GetCommunitiesQuery_communities } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { GetPaymentDashboardWidgetDataQuery } from '@frontend/app/queries/types/GetPaymentDashboardWidgetDataQuery';
import { GetPaymentsQuery_payments } from '@frontend/app/queries/types/GetPaymentsQuery';
import { useAssignUnassignTag } from '@frontend/app/hooks/tag';
import { cn } from '@frontend/shadcn/lib/utils';
import { logger } from '@common';
import { useGetPaymentDashboardWidgetData, useGetPayments } from './hooks';
import { PaymentStatus, PaymentType } from '../constants';
import { useToast } from '@/shadcn/hooks/use-toast';
import { usePaymentTagDialog } from './hooks/usePaymentTagDialog';

export interface Transaction {
  id: string;
  paymentId: number;
  member: {
    id?: number;
    name?: string;
  };
  paymentInitiatedByUser: {
    id: string;
    name: string;
    profilePicture: string;
  };
  paymentDate: string;
  amount: number;
  status: PaymentStatus;
  paypalEmail: string;
  project: {
    id?: number;
    name?: string;
  };
  type: 'Brief' | 'Commission' | 'Other';
  budgets: {
    id?: number;
    name?: string;
  }[];
  tags: {
    id?: number;
    name?: string;
  }[];
  notes: string;
}

const processPaymentsData = (data: GetPaymentsQuery_payments): Transaction[] => {
  return data.data.map((payment) => ({
    id: payment.id.toString(),
    paymentId: payment.paymentId,
    member: {
      id: payment?.member?.id,
      name: payment?.member?.name || payment?.paypalEmail || 'Member Deleted',
    },
    paymentInitiatedByUser: {
      id: payment.initiatedBy,
      name: payment.initiatedBy,
      profilePicture: '',
    },
    paymentDate: payment.paymentDate,
    amount: payment.amount,
    status: payment.status as PaymentStatus,
    paypalEmail: payment.paypalEmail,
    project: {
      id: payment?.project?.id,
      name: payment?.project?.name,
    },
    type: payment.type as 'Brief' | 'Commission' | 'Other',
    budgets: payment.budgets.map((budget) => ({
      id: budget.id,
      name: budget.name,
    })),
    tags: payment.tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
    })),
    notes: payment.notes,
  }));
};

export interface PaymentFilter {
  status: PaymentStatus[];
  paymentTypes: PaymentType[];
  programIds: number[];
  tagIds: number[];
  budgetIds: number[];
  userIds: string[];
  searchBy?: string;
  searchQuery?: string;
}

export interface PaymentContextType {
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
  data: Transaction[];
  pageCount: number;
  isLoading: boolean;
  rowSelection: Record<string, boolean>;
  selectedRows: Transaction[];
  dateRangeSettings: IDateRangeSettings;
  totalCount: number;
  columnVisibility: Record<string, boolean>;
  isFilterOpen: boolean;
  users: GetUsersQuery_users[];
  programs: GetAllPrograms_programs[];
  tags: GetTagsQuery_tags[];
  budgets: GetBudgetAccountsQuery_budgetAccounts[];
  isTagsDialogOpen: boolean;
  selectedPaymentIdsForTags: number[];
  tagAction: TagAction;
  isEditPaymentDrawerOpen: boolean;
  groups: GetCommunitiesQuery_communities[];
  availableBalance: number;
  setAvailableBalance: React.Dispatch<React.SetStateAction<number>>;
  setGroups: React.Dispatch<React.SetStateAction<GetCommunitiesQuery_communities[]>>;
  setIsEditPaymentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onTagDialogClose: () => void;
  openTagDialog: (paymentIds: number[], action: TagAction, onSuccess?: () => void) => void;
  closeTagDialog: () => void;
  setBudgets: React.Dispatch<React.SetStateAction<GetBudgetAccountsQuery_budgetAccounts[]>>;
  setTags: React.Dispatch<React.SetStateAction<GetTagsQuery_tags[]>>;
  setUsers: React.Dispatch<React.SetStateAction<GetUsersQuery_users[]>>;
  setPrograms: React.Dispatch<React.SetStateAction<GetAllPrograms_programs[]>>;
  setIsFilterOpen: (open: boolean) => void;
  setPageIndex: (index: number) => void;
  setSorting: (sorting: SortingState) => void;
  setData: (data: Transaction[]) => void;
  setPageCount: (count: number) => void;
  setIsLoading: (loading: boolean) => void;
  setRowSelection: (selection: Record<string, boolean>) => void;
  setTotalCount: (count: number) => void;
  setColumnVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  paymentDashboardData: GetPaymentDashboardWidgetDataQuery | null;
  setPaymentDashboardData: React.Dispatch<React.SetStateAction<GetPaymentDashboardWidgetDataQuery | null>>;
  filter: PaymentFilter;
  setFilter: React.Dispatch<React.SetStateAction<PaymentFilter>>;
  fetchPaymentDashboardData: () => Promise<void>;
  fetchPayments: () => Promise<void>;
  isPaymentDashboardLoading: boolean;
  assignUnassignTagsDirectly: (paymentIds: number[], tagIds: number[], action: TagAction) => Promise<boolean>;
}

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

const SORT_FIELD_MAPPER: Record<string, string> = {
  project: 'programName',
  paymentInitiatedByUser: 'initiatedBy',
};

export function PaymentProvider({ children }: { children: ReactNode }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(100);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<Transaction[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({});

  const [availableBalance, setAvailableBalance] = useState<number>(0);

  // Replace these individual states with the hook
  const {
    isOpen: isTagsDialogOpen,
    selectedPaymentIds: selectedPaymentIdsForTags,
    action: tagAction,
    open: openTagDialog,
    close: closeTagDialog
  } = usePaymentTagDialog();

  // Filter state
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [users, setUsers] = useState<GetUsersQuery_users[]>([]);
  const [programs, setPrograms] = useState<GetAllPrograms_programs[]>([]);
  const [tags, setTags] = useState<GetTagsQuery_tags[]>([]);
  const [budgets, setBudgets] = useState<GetBudgetAccountsQuery_budgetAccounts[]>([]);

  const dateRangeSettings = useDateFilterSettings(DateRangeOptions.LAST_6_MONTHS);

  // Edit Payment Drawer state
  const [isEditPaymentDrawerOpen, setIsEditPaymentDrawerOpen] = useState(false);

  const [groups, setGroups] = useState<GetCommunitiesQuery_communities[]>([]);

  const [paymentDashboardData, setPaymentDashboardData] = useState<GetPaymentDashboardWidgetDataQuery | null>(null);
  const [isPaymentDashboardLoading, setIsPaymentDashboardLoading] = useState(false);

  const selectedRows = React.useMemo(
    () =>
      Object.keys(rowSelection)
        .map((key) => data.find((row) => row.id === key))
        .filter(Boolean) as Transaction[],
    [rowSelection, data],
  );

  // Add the filter state
  const [filter, setFilter] = useState<PaymentFilter>({
    status: [],
    paymentTypes: [],
    programIds: [],
    tagIds: [],
    budgetIds: [],
    userIds: [],
    searchBy: null,
    searchQuery: null,
  });

  // Get the query hook but don't execute it automatically
  const { refetch } = useGetPaymentDashboardWidgetData({
    variables: {
      filters: {
        startDate: dateRangeSettings.dateRange?.startDate?.toISOString(),
        endDate: dateRangeSettings.dateRange?.endDate?.toISOString(),
        paymentStatus: filter.status,
        paymentTypes: filter.paymentTypes,
        projectIds: filter.programIds,
        tags: filter.tagIds,
        budgetAccounts: filter.budgetIds,
        initiatedBy: filter.userIds,
      },
    },
    skip: true, // Skip automatic execution
  });

  // Add the payments query
  const { refetch: refetchPayments } = useGetPayments({
    variables: {
      filters: {
        startDate: dateRangeSettings.dateRange?.startDate?.toISOString(),
        endDate: dateRangeSettings.dateRange?.endDate?.toISOString(),
        paymentStatus: filter.status,
        paymentTypes: filter.paymentTypes,
        projectIds: filter.programIds,
        tags: filter.tagIds,
        budgetAccounts: filter.budgetIds,
        initiatedBy: filter.userIds,
        page: pageIndex + 1,
        pageSize,
        sortBy: sorting[0]?.id ? (SORT_FIELD_MAPPER[sorting[0].id] || sorting[0].id) : undefined,
        sortDirection: sorting[0]?.desc ? 'desc' : 'asc',
        searchBy: filter.searchBy,
        searchQuery: filter.searchQuery,
      },
    },
    skip: true, // Skip automatic execution
  });

  const { toast } = useToast();
  const [assignUnassignTag] = useAssignUnassignTag();

  // Function to fetch dashboard data
  const fetchPaymentDashboardData = useCallback(async () => {
    setIsPaymentDashboardLoading(true);
    try {
      const { data } = await refetch({
        filters: {
          startDate: dateRangeSettings.dateRange?.startDate?.toISOString(),
          endDate: dateRangeSettings.dateRange?.endDate?.toISOString(),
          paymentStatus: filter.status,
          paymentTypes: filter.paymentTypes,
          projectIds: filter.programIds,
          tags: filter.tagIds,
          budgetAccounts: filter.budgetIds,
          initiatedBy: filter.userIds,
        },
      });

      if (data) {
        setPaymentDashboardData(data);
      }
    } catch (error) {
      logger.error({ message: error });
    } finally {
      setIsPaymentDashboardLoading(false);
    }
  }, [refetch, dateRangeSettings.dateRange?.startDate, dateRangeSettings.dateRange?.endDate, filter]);

  // First effect to reset page index when filter changes
  useEffect(() => {
    // Reset to page 1 (index 0) when filter changes, but not when paginating
    setPageIndex(0);
  }, [filter, dateRangeSettings.dateRange?.startDate, dateRangeSettings.dateRange?.endDate]); // Only depend on filter changes, not pagination

  // Function to fetch payments data
  const fetchPayments = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await refetchPayments({
        filters: {
          startDate: dateRangeSettings.dateRange?.startDate?.toISOString(),
          endDate: dateRangeSettings.dateRange?.endDate?.toISOString(),
          paymentStatus: filter.status,
          paymentTypes: filter.paymentTypes,
          projectIds: filter.programIds,
          tags: filter.tagIds,
          budgetAccounts: filter.budgetIds,
          initiatedBy: filter.userIds,
          page: pageIndex + 1,
          pageSize,
          sortBy: sorting[0]?.id ? (SORT_FIELD_MAPPER[sorting[0].id] || sorting[0].id) : undefined,
          sortDirection: sorting[0]?.desc ? 'desc' : 'asc',
          searchBy: filter.searchBy,
          searchQuery: filter.searchQuery,
        },
      });

      if (data?.payments) {
        setData(processPaymentsData(data.payments));
        setPageCount(data.payments.totalPages);
        setTotalCount(data.payments.total);
      }
    } catch (error) {
      setData([]);
      console.error('Error fetching payments:', error);
    } finally {
      setIsLoading(false);
    }
  }, [
    refetchPayments,
    dateRangeSettings.dateRange?.startDate,
    dateRangeSettings.dateRange?.endDate,
    filter,
    pageIndex,
    pageSize,
    sorting,
  ]);

  // Second effect to fetch data when necessary
  useEffect(() => {
    // Only fetch if not the initial render (empty filter arrays are the default state)
    const isInitialFilterState =
      filter.status.length === 0 &&
      filter.paymentTypes.length === 0 &&
      filter.programIds.length === 0 &&
      filter.tagIds.length === 0 &&
      filter.budgetIds.length === 0 &&
      filter.userIds.length === 0;

    // Skip the first render but allow empty filters after user interaction
    if (!isInitialFilterState || data.length > 0) {
      fetchPaymentDashboardData();
      fetchPayments();
    }
  }, [filter, pageIndex, sorting, fetchPaymentDashboardData, fetchPayments, data.length]);

  const onTagDialogClose = useCallback(() => {
    closeTagDialog();
    setRowSelection({});
    fetchPayments();
  }, [fetchPayments, closeTagDialog]);

  // Method to directly assign/unassign tags without opening the dialog
  const assignUnassignTagsDirectly = useCallback(
    async (paymentIds: number[], tagIds: number[], action: TagAction): Promise<boolean> => {
      try {
        await assignUnassignTag({
          variables: {
            entityType: TagEntityType.PAYMENT,
            entityIds: paymentIds,
            tagIds,
            action,
          },
        });

        toast({
          variant: 'success',
          title: `Successfully ${action === TagAction.ASSIGN ? 'Assigned' : 'Unassigned'} Tags`,
          duration: 3000,
          className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
        });

        // Refresh payments list to reflect the changes
        fetchPayments();
        return true;
      } catch (error) {
        toast({
          variant: 'error',
          title: `Failed ${action === TagAction.ASSIGN ? 'Assigning' : 'Unassigning'} Tags`,
          duration: 3000,
          className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
        });
        logger.error({ message: error });
        return false;
      }
    },
    [assignUnassignTag, toast, fetchPayments],
  );

  const value = {
    pageIndex,
    pageSize,
    sorting,
    data,
    pageCount,
    isLoading,
    rowSelection,
    selectedRows,
    dateRangeSettings,
    totalCount,
    columnVisibility,
    isFilterOpen,
    users,
    programs,
    tags,
    budgets,
    isTagsDialogOpen,
    selectedPaymentIdsForTags,
    tagAction,
    isEditPaymentDrawerOpen,
    groups,
    availableBalance,
    setAvailableBalance,
    setGroups,
    setIsEditPaymentDrawerOpen,
    onTagDialogClose,
    openTagDialog,
    closeTagDialog,
    setBudgets,
    setTags,
    setUsers,
    setPrograms,
    setIsFilterOpen,
    setPageIndex,
    setSorting,
    setData,
    setPageCount,
    setIsLoading,
    setRowSelection,
    setTotalCount,
    setColumnVisibility,
    paymentDashboardData,
    setPaymentDashboardData,
    filter,
    setFilter,
    fetchPaymentDashboardData,
    fetchPayments,
    isPaymentDashboardLoading,
    assignUnassignTagsDirectly,
  };

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
}

export function usePayment() {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
}
