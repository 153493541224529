import React, { useEffect, useMemo, useCallback } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerClose,
} from '@frontend/shadcn/components/ui/drawer';
import { XmarkIcon } from '@revfluence/fresh-icons/solid';
import { Button } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';
import { useGetData } from '@frontend/applications/TermsApp/hooks/useGetData';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '@common';
import { toast } from '@frontend/shadcn/hooks/use-toast';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { useAuth } from '@frontend/context/authContext';
import { BulkAssignBudgetDataInput } from '@frontend/app/types/globalTypes';
import { useAssignBudgetToBulkPayments } from '@frontend/app/hooks/budgetAllocation/useAssignBudgetToBulkPayments';
import {
  GetBudgetPeriodDetails_periodDetails,
  GetBudgetPeriodDetails_periodDetails_quarterDistributions,
} from '@frontend/app/queries/types/GetBudgetPeriodDetails';
import { Spinner } from '@revfluence/fresh';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { IUpdatePaymentAssignemntRequest, updatePaymentData } from '../../savePayment';
import { fetchPaymentById } from '../../fetchPaymentById';
import { BudgetSource } from '../constants';
import { TopSection } from './TopSection';
import { ConnectionsSection, Mode } from './ConnectionsSection';
import { PaymentStatusSection } from './PaymentStatusSection';
import { PaymentDetailSection } from './PaymentDetailSection';
import { TimelineSection } from './TimelineSection';
import { useGetPaymentDetailsByIdsQuery } from '../hooks';
import { usePayment } from '../PaymentContext';
import { exportElementAsPdf } from '../utils/pdfExport';
import { ExpandableTextInput } from './ExpandableTextInput';

export interface IEditPayments {
  isLoading: boolean;
  notes: string;
  mode: Mode;
  groupId: number | null;
  projectId: number | null;
  briefId: number | null;
  budgetId: number | null;
  budgetSource: BudgetSource;
  periodState: {
    other: { key: string | null; id: number | null };
    project: { key: string | null; id: number | null };
  };
}

export interface IFiscalPeriod {
  key: string;
  id: number;
  label: string;
  granularityLabelForPayload: string;
}

// Main component
export const EditPaymentDrawer: React.FC = () => {
  const {
    isEditPaymentDrawerOpen,
    setIsEditPaymentDrawerOpen,
    selectedRows,
    programs,
    groups,
    fetchPayments,
    setRowSelection,
  } = usePayment();
  const { budgetAllocation } = useClientFeatures();
  const [editPayments, setEditPayments] = React.useState<IEditPayments>({
    isLoading: false,
    notes: '',
    mode: Mode.VIEW,
    groupId: null,
    projectId: null,
    briefId: null,
    budgetId: null,
    budgetSource: BudgetSource.PROJECT,
    periodState: {
      other: { key: null, id: null },
      project: { key: null, id: null },
    },
  });

  const { clientInfo } = useAuth();
  const [paymentDetails, setPaymentDetails] = React.useState<GetPaymentDetailsByIdsQuery_payments[] | null>([]);

  const isSinglePayment = paymentDetails.length === 1;

  // Extract payment details
  const memberId = paymentDetails?.[0]?.member?.id;
  const projectId = editPayments?.projectId || paymentDetails?.[0]?.project?.id;
  const shouldSkipFetch = !isSinglePayment && !(memberId && projectId);

  const { data: paymentDetailsData, loading: isPaymentDetailsLoading } = useGetPaymentDetailsByIdsQuery({
    variables: { paymentIds: selectedRows.map((row) => row.paymentId) },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (paymentDetailsData?.payments) {
      setPaymentDetails(paymentDetailsData.payments);
    }
  }, [paymentDetailsData]);

  const { loading: isLoadingBudgetPeriod, periodDetails } = useGetBudgetPeriodDetails({
    variables: {
      budgetAccountId: editPayments.budgetSource === BudgetSource.OTHER ? editPayments.budgetId : null,
    },
    skip: editPayments.budgetSource === BudgetSource.PROJECT || !editPayments.budgetId,
  });

  const { loading: isLoadingProjectBudgetPeriod, periodDetails: projectPeriodDetails } = useGetBudgetPeriodDetails({
    variables: {
      programId: editPayments.budgetSource === BudgetSource.PROJECT ? editPayments.projectId : null,
    },
    skip: editPayments.budgetSource === BudgetSource.OTHER || !editPayments.projectId,
  });

  const { data: terms = [], loading: isTermsLoading } = useGetData<IAgreement[]>(
    `${backendServerApiEndpoint()}/agreements?member_id=${memberId}&program_id=${projectId}`,
    shouldSkipFetch,
  );

  const { backendServerFetchResponse } = useBackendServerFetch();
  const [bulkReassignBudget] = useAssignBudgetToBulkPayments();

  const transformPeriodData = (periodData: GetBudgetPeriodDetails_periodDetails[] = []): IFiscalPeriod[] => {
    if (!periodData.length) return [];

    const result: IFiscalPeriod[] = [];

    periodData.forEach((period) => {
      // Add fiscal year
      result.push({
        key: uuidv4(),
        id: period.fiscalYearPeriodDefinitionId,
        label: period.fiscalYearLabel,
        granularityLabelForPayload: 'FY',
      });

      // Add quarters if available
      if (period.quarterDistributions?.length) {
        period.quarterDistributions.forEach((quarter: GetBudgetPeriodDetails_periodDetails_quarterDistributions) => {
          result.push({
            key: uuidv4(),
            id: quarter.quarterPeriodDefinitionId,
            label: quarter.quarterLabel,
            granularityLabelForPayload: quarter.quarterKey,
          });
        });
      }
    });

    return result;
  };
  const fiscalPeriods = useMemo(() => transformPeriodData(periodDetails), [periodDetails]);

  const projectFiscalPeriods = useMemo(() => transformPeriodData(projectPeriodDetails), [projectPeriodDetails]);

  const briefs = useMemo(() => terms?.map((term) => term) || [], [terms]);

  useEffect(() => {
    if (isSinglePayment) {
      setEditPayments((prev) => ({
        ...prev,
        groupId: paymentDetails[0]?.paymentGroup?.id || null,
        projectId: paymentDetails[0]?.project?.id || null,
        briefId: paymentDetails[0]?.brief?.id || null,
        notes: paymentDetails[0]?.notes || '',
      }));
    }
  }, [isSinglePayment, paymentDetails]);

  const getValidationError = useMemo(() => {
    if (!budgetAllocation || editPayments.mode !== Mode.EDIT) return null;

    if (editPayments.budgetSource === BudgetSource.PROJECT) {
      if (!editPayments.periodState.project.key) {
        return 'Please select a fiscal period';
      }
    } else {
      if (!editPayments.budgetId) {
        return 'Please select a budget';
      }
      if (!editPayments.periodState.other.key) {
        return 'Please select a fiscal period';
      }
    }
    return null;
  }, [
    budgetAllocation,
    editPayments.mode,
    editPayments.budgetSource,
    editPayments.budgetId,
    editPayments.periodState.project.key,
    editPayments.periodState.other.key,
  ]);

  // Add this state to track note changes
  const [originalNotes, setOriginalNotes] = React.useState('');

  // Add this useEffect to initialize original notes
  useEffect(() => {
    if (paymentDetails?.[0]) {
      setOriginalNotes(paymentDetails[0].notes || '');
      setEditPayments((prev) => ({
        ...prev,
        notes: paymentDetails[0].notes || ''
      }));
    }
  }, [paymentDetails]);

  const onSubmit = async () => {
    setEditPayments((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      // If only notes changed (not in edit mode), just update notes
      if (editPayments.mode !== Mode.EDIT && editPayments.notes !== originalNotes) {
        await updateNotes();
      } else {
        // Existing full update logic
        for (const paymentId of selectedRows.map((row) => row.paymentId)) {
          try {
            const payment = await fetchPaymentById(paymentId, clientInfo.id, backendServerFetchResponse);
            const selectedProgram = programs.find((program) => program.id === editPayments?.projectId);
            const selectedGroup = groups.find((group) => group.id === editPayments?.groupId);

            const url = `${backendServerApiEndpoint()}/payment`;
            const updatePayload: IUpdatePaymentAssignemntRequest = {
              program_ids: selectedProgram ? [selectedProgram.id] : payment.program_ids,
              program_names: selectedProgram ? [selectedProgram.title] : payment.program_names,
              community_ids: selectedGroup ? [selectedGroup.id] : payment.community_ids,
              activation_ids: payment.activation_ids,
              activation_names: payment.activation_names,
              assigned: payment.assigned,
              client_id: payment.client_id,
              note: editPayments.notes,
            };
            if (isSinglePayment) {
              updatePayload.project_id = editPayments?.briefId ? editPayments.briefId : null;
              updatePayload.assign_payment_to_brief = true;
            }
            await updatePaymentData(`${url}/${payment.id}`, updatePayload);
          } catch (err) {
            logger.error({ message: err });
          }
        }

        if (budgetAllocation) {
          let selectedPeriod = null;
          if (editPayments.budgetSource === BudgetSource.OTHER) {
            selectedPeriod = fiscalPeriods?.find((period) => period.key === editPayments.periodState?.other?.key);
          } else {
            selectedPeriod = projectFiscalPeriods?.find(
              (period) => period.key === editPayments.periodState?.project?.key,
            );
          }
          const splitPayment = [
            {
              budgetAccountId: editPayments?.budgetId,
              fiscalGranularityLabel: selectedPeriod?.granularityLabelForPayload,
              budgetPeriodDefinitionId: selectedPeriod?.id,
            },
          ];

          const bulkAssignBudgetData: BulkAssignBudgetDataInput = {
            paymentIds: paymentDetails?.map((payment) => payment.paymentId) || [],
            source: editPayments?.briefId ? 'Term' : null,
            ...(editPayments.budgetSource === BudgetSource.OTHER
              ? {
                  accountsPaymentBudgetInput: {
                    splitInfo: splitPayment,
                  },
                }
              : {
                  projectPaymentBudgetInput: {
                    programSplitFiscalInfo: {
                      budgetPeriodDefinitionId: selectedPeriod?.id,
                      fiscalGranularityLabel: selectedPeriod?.granularityLabelForPayload.split(' ')[0],
                    },
                    overflow: [],
                  },
                }),
          };

          if (!selectedPeriod) {
            throw new Error('Please select budget and fiscal period');
          }
          await bulkReassignBudget({
            variables: { bulkAssignBudgetData },
            onError(error) {
              logger.error({ message: error });
              throw error;
            },
          });
        }
      }

      toast({
        variant: 'success',
        title: editPayments.mode === Mode.EDIT ? 'Payment Updated' : 'Notes Updated',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
    } catch (error) {
      const customMessage =
        error.message == 'No valid logs to insert' ? 'Please select a project or others budget' : '';
      toast({
        variant: 'error',
        title: customMessage || 'Error updating payment',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
      logger.error({ message: error });
    } finally {
      setIsEditPaymentDrawerOpen(false);
      setRowSelection({});
      fetchPayments();
      setEditPayments((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const updateNotes = async () => {
    try {
      for (const paymentId of selectedRows.map((row) => row.paymentId)) {
        try {
          const payment = await fetchPaymentById(paymentId, clientInfo.id, backendServerFetchResponse);
          const url = `${backendServerApiEndpoint()}/payment`;
          const updatePayload: IUpdatePaymentAssignemntRequest = {
            client_id: payment.client_id,
            community_ids: payment.community_ids,
            program_ids: payment.program_ids,
            program_names: payment.program_names,
            activation_ids: payment.activation_ids,
            activation_names: payment.activation_names,
            assigned: payment.assigned,
            note: editPayments.notes,
          };
          await updatePaymentData(`${url}/${payment.id}`, updatePayload);
        } catch (err) {
          logger.error({ message: err });
        }
      }
      toast({
        variant: 'success',
        title: 'Updated Notes',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
    } catch (error) {
      logger.error({ message: error });
    }
  };

  const isLoadingEditPayments =
    isPaymentDetailsLoading || isTermsLoading || isLoadingBudgetPeriod || isLoadingProjectBudgetPeriod;
  const title = selectedRows.length === 1 ? 'Payment Overview' : 'Update Payments in Bulk';

  const captureComponentScreenshot = () => {
    exportElementAsPdf('.drawer-content', 'payment-details');
  };

  const handleClose = useCallback(() => {
    setIsEditPaymentDrawerOpen(false);
    setRowSelection({});
    fetchPayments();
  }, [setIsEditPaymentDrawerOpen, setRowSelection, fetchPayments]);

  return (
    <Drawer
      open={isEditPaymentDrawerOpen}
      onOpenChange={(open) => {
        if (!open) handleClose();
      }}
      onClose={handleClose}
      direction="right"
    >
      <DrawerContent
        className={cn(
          'fixed left-auto inset-y-0 mt-0 right-0 z-[9999] flex h-full w-[572px] flex-col border-l bg-background rounded-none p-0',
          'duration-300 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
          'bg-background backdrop-blur-sm',
          'top-[var(--header-height,0px)] h-[calc(100vh-var(--header-height,0px))]',
          'bg-white',
        )}
        data-vaul-no-drag
      >
        <DrawerHeader className="flex justify-between items-center border-b border-grey-3 px-6 py-4">
          <DrawerTitle className="font-medium mb-0">{title}</DrawerTitle>
          <DrawerClose asChild>
            <Button
              variant="ghost"
              size="headerIcon"
              className="border border-transparent hover:border-grey-6 hover:bg-transparent"
              onClick={handleClose}
            >
              <XmarkIcon className="h-4 w-4" />
            </Button>
          </DrawerClose>
        </DrawerHeader>

        <div className="drawer-content flex flex-col overflow-y-scroll w-full h-full">
          {isPaymentDetailsLoading ? (
            <div className="flex justify-center items-center h-full w-full">
              <Spinner size="large" />
            </div>
          ) : (
            <>
              <TopSection paymentDetails={paymentDetails} onClickDownload={captureComponentScreenshot} />

              <div className="flex flex-col gap-4 p-4">
                <PaymentStatusSection paymentDetails={paymentDetails} />
                <div className="flex flex-col gap-2">
                  <ExpandableTextInput
                    value={editPayments.notes}
                    onChange={(value) => {
                      setEditPayments((prevState) => ({
                        ...prevState,
                        notes: value,
                      }));
                    }}
                    label="Notes"
                    placeholder="Add notes.."
                  />
                </div>
                <TimelineSection paymentDetails={paymentDetails} />
                <ConnectionsSection
                  editPayments={editPayments}
                  setEditPayments={setEditPayments}
                  paymentDetails={paymentDetails}
                  briefs={briefs}
                  isLoading={isLoadingEditPayments}
                  fiscalPeriods={fiscalPeriods}
                  projectFiscalPeriods={projectFiscalPeriods}
                />
                <PaymentDetailSection paymentDetails={paymentDetails} />
              </div>
            </>
          )}
        </div>

        {(editPayments.mode === Mode.EDIT || editPayments.notes !== originalNotes) && (
          <DrawerFooter className="flex flex-row gap-2 space-x-2 border-t border-grey-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Button
                      disabled={
                        isPaymentDetailsLoading ||
                        editPayments.isLoading ||
                        (editPayments.mode === Mode.EDIT && !!getValidationError)
                      }
                      onClick={onSubmit}
                      loading={editPayments.isLoading}
                    >
                      {isSinglePayment ? 'Apply' : 'Update Payments'}
                    </Button>
                  </span>
                </TooltipTrigger>
                {getValidationError && (
                  <TooltipContent>
                    {getValidationError}
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
            <Button
              variant="outline"
              onClick={() => {
                setEditPayments((prev) => ({
                  ...prev,
                  notes: originalNotes,
                  mode: Mode.VIEW
                }));
                handleClose();
              }}
              disabled={editPayments.isLoading}
            >
              Cancel
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
