/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IToastRefHandles,
  Toast,
} from '@components';
import { useResizeDetector } from 'react-resize-detector';

import {
  Button,
} from 'antd';
import cx from 'classnames';
import {
  chunk,
  endsWith,
  get,
  isEmpty,
  isFunction,
  isNil,
  isUndefined,
  keyBy,
  keys,
  map,
  reduce,
  reject,
  some,
  find,
  isArray,
  filter,
  uniq,
  first,
  cloneDeep,
} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Row,
  Space,
  Alert,
  Typography,
  Divider,
  Modal,
  message,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';

import { logger } from '@common';
import {
  Drawer,
} from '@frontend/app/components';
import {
  IMembersTableSearchQuery,
  MembersTable,
  OWNED_BY_ME_FILTER_VALUE,
  LAST_MENTION_DATE_7DAYS_FILTER_VALUE,
} from '@frontend/app/components/MembersTableOld/MembersTable';
import {
  IWorkflowActionParameters,
} from '@frontend/app/containers/Application/ApplicationContainer';
import {
  ApplicationDrawer,
} from '@frontend/app/containers/Application/ApplicationDrawer';
import {
  ICounts,
  TGetTaskBySpecUriAndId,
  useCustomCTA,
  useCustomTaskNotice,
  useOverviewPage,
  useWorkItemsTableColumnConfig,
} from '@frontend/app/containers/Projects/hooks';
import { WorkflowTask } from '@frontend/applications/AffiliatesApp/utils/workflowUtils';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import {
  TCondition,
  TProject,
  TTask,
  TWorkItem,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import {
  useGetApplicationsByIds,
  useGetAllCompletedMembersQuery,
  useMarkWorkItemsViewedMutation,
  IThread,
  useClientFeatureEnabled,
  useGetContentGuidelineTemplatesForProject,
  CommunitySwitcherProvider,
  useGetWorkItemsQuery,
  useGetTermsName,
  usePredefinedSegmentsForProgram,
  useDefaultSegmentsForProgram,
  useGetInstalledApplicationIds,
  useMemberSearchCountQuery,
  useBulkMoveToMutation,
} from '@frontend/app/hooks';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { MemberListContextProvider } from '@frontend/app/context/MemberListContext';
import { CONTENT_APP_ID, CONTRACT_APP_ID, TERMS_APP_ID } from '@frontend/app/constants/applicationIds';
import { FetchContextProvider } from '@frontend/utils';
import { useMessagingContext } from '@frontend/hooks';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';

import {
  hasError, hasNotification, isTransient, parseToITaskUI,
} from '@frontend/app/utils/task';
import { MessagingProvider } from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { MessageList } from '@frontend/app/containers/MessagingApp/MessageList';
import {
  QuickFilters,
} from '@frontend/app/components/FilterList/QuickFilters';
import { ClientFeature } from '@frontend/app/constants';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import { useFetchMembers } from '@frontend/app/components/MembersTableOld/hooks/useFetchMembers';
import { SelectedField } from '@frontend/app/queries';
import {
  ColumnKey,
  ProjectsPageState,
  AllInProgressStageTitle,
  Task,
  CtaOrderConfig,
  WorkletSpecUri,
} from '@frontend/app/containers/Projects/constants';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { useApolloClient } from '@frontend/applications/AffiliatesApp/hooks';
import { GET_OFFERS_BY_SEARCH_QUERY } from '@frontend/applications/AffiliatesApp/queries';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { useRefreshSelectedMembers } from '@frontend/app/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGetMemberIdsByInternalStatus } from '@frontend/app/hooks/contentReviews/useGetMemberIdsByInternalStatus';
import { InternalContentReviewStatus } from '@frontend/app/types/globalTypes';
import { useGetCreatorsOrderCatalogInfo } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCreatorsOrderCatalogInfo';
import { useApolloClient as usePfaApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { useAuth } from '@frontend/context/authContext';
import { TabComponent } from '@frontend/app/refresh-components';
import { FlexMembersTable } from '@frontend/app/components/MembersTableOld/FlexMembersTable';
import { useGetFlexCompletedMemberIdsQuery } from '@frontend/app/hooks/useGetFlexCompletedMemberIds';
import { MembersTableFiltersProvider } from '@frontend/app/components/MembersTableOld/MembersTableFiltersDrawer/MembersTableFiltersDrawerContext';
import { useTaskProgressMonitor } from '@frontend/app/hooks/memberList/useTaskProgressMonitor';
import { useGetNoDeliverableMemberIdsQuery } from '@frontend/app/hooks/useGetNoDeliverableMemberIdsQuery';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';
import { TableHeader } from '../TableHeader/TableHeader';

import styles from './WorkItemsList.scss';
import { NewMemberDetailContainer } from '../../../MemberDetail/NewMemberDetailContainer';
import { WorkItemItemBox } from './WorkItemItemBox';
import { ShopifyReauthenticateModal } from './ShopifyReauthenticateModal';
import { ContentReviewFilters } from '../GroupContentReviewPage/ContentReviewWorkItemFilters';
import { WorkletSpecKey } from '../../utils';
import { useContentWithSideMenuContext } from '@/shadcn/layouts/ContentWithSideMenu/ContentWithSideMenuContext';
import { FlexTableHeader } from '../FlexTableHeader/FlexTableHeader';
import { AddMembersProgress } from './AddMembersProgress';
import { checkTaskIdHasTabs } from '../../utils';

const { Text, Title } = Typography;
const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

interface IProps {
  applicationId?: string;
  className?: string;
  counts: ICounts;
  onSelectWorkItems?: (workItemIds: string[]) => void;
  pageState?: ProjectsPageState;
  project?: TProject;
  refetchCounts: () => Promise<void>;
  task?: TTask;
  getTaskBySpecUriAndId: TGetTaskBySpecUriAndId;
  onTaskSelected: THandleTaskSelected;
  worklets?: TWorklet[];
  conditions?: TCondition[];
  openAddToCollectionModal(modalType: ModalType): void;
  onSelectMemberIds?: (memberIds: number[]) => void;
  workletSpecUri?: string;
  tasks: TTask[];
  showSelectOffer?: (value: boolean) => void;
  migrateToGraphQL?: boolean;
}

const WORKITEM_VIEWED_BATCHSIZE = 200;
const PFA_COLUMN_PREFIX = 'pfa__';
// store them as strings as that is how it's currently passed around in workItems
const TASKS_REQUIRING_EMAIL = [
  Task.SendTermsTask.toString(),
];
const CONTENT_STAGES = {
  CONTENT: 'Content',
  TRACK_POSTS: 'Track Posts',
  REVIEW_CONTENT: 'Review Content',
};

const messageContainerFixed = {
  position: 'fixed',
  top: '10px',
  left: '0',
  right: '0',
  margin: 'auto',
};

const fieldResolversMapperForMembers = {
  PFA: [
    WorkletSpecUri.CreatorProductSelectionWorkletSpecification.toString(),
    WorkletSpecUri.SendBrandProductCatalogWorkletSpecification.toString(),
    WorkletSpecUri.OfflineCreatorProductSelectionWorkletSpecification.toString(),
    WorkletSpecUri.SendProductWorkletSpecification.toString(),
  ],
  STA: [
    WorkletSpecUri.SalesLinkWorkletSpecification.toString(),
    WorkletSpecUri.PromoCodesWorkletSpecification.toString(),
    WorkletSpecUri.SalesLinkPromoCodesWorkletSpecification.toString(),
  ],
};

const insertActionCol = (
  segments: IPredefinedSegment[],
  workletSpecUri: string,
  projectId: number,
) => {
  if (workletSpecUri?.startsWith('CustomWorklet')) {
    const stageName = workletSpecUri.split('CustomWorklet-')[1]?.split('-')?.[0];
    return find(segments, (segment) => segment.id.includes(stageName));
  }
  const segment = find(segments, (segment) => segment.id === `In Stage_${workletSpecUri}_${projectId}`);
  if ([
    WorkletSpecUri.PromoCodesWorkletSpecification,
    WorkletSpecUri.SalesLinkWorkletSpecification,
    WorkletSpecUri.SalesLinkPromoCodesWorkletSpecification,
  ].includes(workletSpecUri as WorkletSpecUri)) {
    return segment;
  }
  if (segment) {
    const segmentWithCTA = cloneDeep(segment);
    if (!segmentWithCTA.columns?.order?.includes(CtaOrderConfig)) {
      segmentWithCTA.columns?.order?.unshift(CtaOrderConfig);
    }
    if (!segmentWithCTA.columns?.projectColumns?.includes(ColumnKey.CTA)) {
      segmentWithCTA.columns?.projectColumns?.unshift(ColumnKey.CTA);
    }
    return segmentWithCTA;
  }
  return segment;
};

interface TaskItemLabel {
  name: string;
  label: string;
}
const transformTaskItemLabels = (taskItemLabels: TaskItemLabel[]) => {
  return taskItemLabels.map((taskItemLabel) => {
    const { name, label } = taskItemLabel;
    const count = label.replace(/^.*\((.*)\).*$/, '$1');
    const [workletSpecUri, taskId] = name.split('-');
    switch (workletSpecUri) {
      case WorkletSpecUri.SendTermsWorkletSpecification: {
        switch (taskId) {
          case Task.Done:
            return {
              name,
              label: `Signed (${count})`,
            };
          default:
            return taskItemLabel;
        }
      }
      case WorkletSpecUri.SendContractWorkletSpecification: {
        switch (taskId) {
          case Task.Done:
            return {
              name,
              label: `Signed (${count})`,
            };
          default:
            return taskItemLabel;
        }
      }
      case WorkletSpecUri.CreatorProductSelectionWorkletSpecification:
      case WorkletSpecUri.SendBrandProductCatalogWorkletSpecification:
      case WorkletSpecUri.SendProductWorkletSpecification:
      case WorkletSpecUri.OfflineCreatorProductSelectionWorkletSpecification: {
        switch (taskId) {
          case Task.Done:
            return {
              name,
              label: `Delivered (${count})`,
            };
          default:
            return taskItemLabel;
        }
      }
      case WorkletSpecUri.ConfirmAddressWorkletSpecification: {
        switch (taskId) {
          case Task.Done:
            return {
              name,
              label: `Info Received (${count})`,
            };
          default:
            return taskItemLabel;
        }
      }
      case WorkletSpecUri.PaymentWorkletSpecification: {
        switch (taskId) {
          case Task.Done:
            return {
              name,
              label: `Paid (${count})`,
            };
          default:
            return taskItemLabel;
        }
      }
    }
    switch (name) {
      case Task.WaitingForAgreementTask:
        return {
          name,
          label: `Pending Agreement (${count})`,
        };
      case Task.ReviewTermsTask:
        return {
          name,
          label: `Review Needed (${count})`,
        };
      case Task.WaitingForContentTask:
        return {
          name,
          label: `Pending Submission (${count})`,
        };
      case Task.ReviewContentTask:
        return {
          name,
          label: `Review Needed (${count})`,
        };
      case Task.SendPaymentTask:
        return {
          name,
          label: `Payment Due (${count})`,
        };
      default:
        return taskItemLabel;
    }
  });
};

export const WorkItemsList: React.FC<IProps> = React.memo(({
  className,
  counts,
  onSelectWorkItems,
  pageState,
  project,
  task,
  getTaskBySpecUriAndId,
  onTaskSelected,
  refetchCounts,
  worklets,
  conditions,
  openAddToCollectionModal,
  onSelectMemberIds,
  workletSpecUri,
  tasks,
  showSelectOffer,
  migrateToGraphQL,
}) => {
  const toastRef = useRef<IToastRefHandles>();

  const { showErrorMessage, showInfoMessage, showSuccessMessage } = useMessagingContext();
  const [displayedRowCount, setDisplayedRowCount] = useState(0);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string>('');
  const [offers, setOffers] = useState<GetOfferById_offer[]>([]);
  const { height, ref: tableContainerRef } = useResizeDetector();
  const contentGuidelinesEnabled = useClientFeatureEnabled(ClientFeature.CONTENT_GUIDELINES);
  const isNewWorkletMenuEnabled = useClientFeatureEnabled(ClientFeature.WORKET_MENU);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const isFlexEnabled = useClientFeatureEnabled(ClientFeature.FLEX_EXPANDABLE_COLUMN);
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS) || isFlexEnabled;
  const isFlexibleProjectEnabled = useClientFeatureEnabled(ClientFeature.FLEXIBLE_PROJECT) && project?.isFlexibleSpec;
  const { [ClientFeature.RENAME_TERMS]: isRenameTermsEnabled } = useClientFeatures();
  const [dynamicQueryFields, setDynamicQueryFields] = useState<Set<SelectedField>>(new Set<SelectedField>());
  const { renderArchivedNotice } = useOverviewPage(project);
  const staApolloClient = useApolloClient();
  const { setRoundedSideMenu } = useContentWithSideMenuContext();
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>();

  // Block for fetching saved and default columns
  const {
    loading: isLoadingPredefinedProgramSegments,
    data: { segments = [] } = {},
    refetch: refetchSegments,
  } = usePredefinedSegmentsForProgram({
    programId: project?.id,
  }, { skip: !isPFAColumnsEnabled });

  const {
    loading: isLoadingReviews,
    memberIds: filteredGCRMemberIds,
    refetch: refetchFilteredGCRMemberIds,
  } = useGetMemberIdsByInternalStatus({
    variables: {
      programId: project?.id,
      statuses: [],
      limit: 200,
      offset: 0,
    },
    skip: !project?.id || task?.workletSpecUri !== 'ReviewContentWorkletSpecification',
  });

  const {
    loading: isLoadingDefaultProgramSegments,
    data: { segments: defaultSegments = [] } = {},
  } = useDefaultSegmentsForProgram({
    programId: project?.id,
  }, { skip: !isPFAColumnsEnabled, fetchPolicy: 'no-cache' });

  const installedApps = useGetInstalledApplicationIds();

  const handleRefetchSegments = useCallback(() => (projectId) => {
    refetchSegments({ programId: projectId });
  }, [refetchSegments]);

  const savedColumns = useMemo(() => {
    if (isLoadingPredefinedProgramSegments) return [];
    if (checkTaskIdHasTabs(task?.taskId, [Task.NeedsAction, Task.All])) {
      return insertActionCol(segments, workletSpecUri, project?.id);
    }
    if (checkTaskIdHasTabs(task?.taskId, [Task.Done, Task.All, Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
      return find(segments, (segment) => segment.id === `In Stage_${workletSpecUri}_${project?.id}`);
    }
    if (task) {
      return find(segments, (segment) => segment.id === `${task.taskId}+${task.workletSpecKey}_${project?.id}`);
    }
    if (workletSpecUri) {
      return find(segments, (segment) => segment.id === `In Stage_${workletSpecUri}_${project?.id}`);
    }
    return find(
      segments,
      (segment) => segment.id.split('_')[0] === AllInProgressStageTitle,
    );
  }, [
    task,
    workletSpecUri,
    segments,
    isLoadingPredefinedProgramSegments,
    project?.id,
  ]);

  const defaultSavedColumns = useMemo(() => {
    if (isLoadingDefaultProgramSegments) return [];
    if (checkTaskIdHasTabs(task?.taskId, [Task.NeedsAction, Task.All])) {
      return insertActionCol(defaultSegments, workletSpecUri, project?.id);
    }
    if (checkTaskIdHasTabs(task?.taskId, [Task.Done, Task.All, Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
      return find(defaultSegments, (segment) => segment.id === `In Stage_${workletSpecUri}_${project?.id}`);
    }
    if (task) {
      return find(defaultSegments, (segment) => segment.id === `${task.taskId}+${task.workletSpecKey}_${project?.id}`);
    }
    if (workletSpecUri) {
      return find(defaultSegments, (segment) => segment.id === `In Stage_${workletSpecUri}_${project?.id}`);
    }
    return find(
      defaultSegments,
      (segment) => segment.id.split('_')[0] === AllInProgressStageTitle,
    );
  }, [
    task,
    workletSpecUri,
    defaultSegments,
    isLoadingDefaultProgramSegments,
    project?.id,
  ]);

  useEffect(() => {
    if (!isPFAColumnsEnabled || isLoadingPredefinedProgramSegments) return;

    // Does not include the memberSchema fields as they are in members table and always fetched
    const dbColumns = (savedColumns as IPredefinedSegment)?.columns?.dbColumns || [];
    const projectColumns = (savedColumns as IPredefinedSegment)?.columns?.projectColumns || [];
    const allColumns = [...dbColumns, ...projectColumns];

    const updatedDynamicQueryFields = new Set<SelectedField>();
    // Change this logic to have suffix for aggregation fields in the future(order summary, program order summary, etc.)
    if (find(allColumns, (column) => column?.startsWith(PFA_COLUMN_PREFIX))) {
      updatedDynamicQueryFields.add(SelectedField.ORDER);
    }
    setDynamicQueryFields(updatedDynamicQueryFields);
  }, [
    isPFAColumnsEnabled,
    isLoadingPredefinedProgramSegments,
    savedColumns,
    setDynamicQueryFields,
  ]);

  const {
    data: otherProjectGuidelinesData,
    loading: otherProjectGuidelinesLoading,
  } = useGetContentGuidelineTemplatesForProject({
    skip: !contentGuidelinesEnabled || task?.taskId !== WorkflowTask.SEND_TERMS_TASK || !project?.id,
    variables: {
      programId: project?.id,
    },
    fetchPolicy: 'no-cache',
  });

  // TODO-MIAS: need to update this
  const {
    data: {
      workItems,
    } = {},
    loading: isWorkItemsLoading,
    refetch: refetchWorkItems,
  } = useGetWorkItemsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      programId: project?.id,
      specUri: workletSpecUri,
      taskId: task?.taskId,
    },
    skip: !project?.id,
  });

  const workletSpecKey = useMemo(() => worklets?.find((worklet) => worklet.specURI === workletSpecUri)?.specKey, [worklets, workletSpecUri]);

  const {
    data: {
      flexCompletedMemberIds,
    } = {},
    refetch: refetchFlexCompletedMemberIds,
  } = useGetFlexCompletedMemberIdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      programId: project?.id,
      specKey: workletSpecKey,
    },
    skip: !project?.id || !workletSpecKey,
  });

  const {
    data: {
      noDeliverableMemberIds,
    } = {},
    refetch: refetchNoDeliverableMemberIds,
  } = useGetNoDeliverableMemberIdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      programId: project?.id,
      specKey: workletSpecKey,
    },
    skip: !project?.id
      || ![
        WorkletSpecUri.TrackPostsWorkletSpecification,
        WorkletSpecUri.ReviewContentWorkletSpecification,
        WorkletSpecUri.PaymentWorkletSpecification,
      ].includes(workletSpecUri as WorkletSpecUri),
  });

  const { token } = useAuth();
  const pfaApolloClient = usePfaApolloClient(token);
  const { creatorsOrderCatalogInfo } = useGetCreatorsOrderCatalogInfo({
    variables: {
      orderIds: workItems?.map((workItem) => workItem.data[Task.SendOrderRequestV2]?.orderId),
    },
    skip: !workItems?.length
      || ![
        Task.WaitingForOrderRequestTaskV2,
        Task.ReviewOrderRequestTaskV2,
        Task.ProcessingCreatorOrderTaskV2,
        Task.CreatorOrderInTransitTaskV2,
      ].includes(task?.taskId as Task)
      || !pfaApolloClient,
    client: pfaApolloClient,
  });

  // Main identifier when the page changes
  const pageId = useMemo(
    () => (
      pageState === ProjectsPageState.Task
        ? `${task?.workletSpecUri}/${task?.taskId}`
        : pageState
    ),
    [pageState, task],
  );
  const isCompletedPage = pageState === ProjectsPageState.Completed;

  const [markWorkItemsViewed] = useMarkWorkItemsViewedMutation();
  useEffect(
    () => {
      if (pageState === ProjectsPageState.AllInProgress || isWorkItemsLoading) {
        return;
      }
      if (some(workItems, { viewed: false })) {
        const unViewedItems = reject(workItems, 'viewed');
        const workItemViews = map(unViewedItems, (workItem) => ({
          workItemId: workItem.id,
          workletSpecURI: workItem.specURI,
          taskId: workItem.taskId,
        }));

        Promise.allSettled(
          map(
            chunk(workItemViews, WORKITEM_VIEWED_BATCHSIZE),
            async (batchedWorkItemViews) => {
              await markWorkItemsViewed({
                variables: { workItemViews: batchedWorkItemViews, specKey: project?.specKey },
              });
            },
          ),
        ).then(
          () => refetchCounts(),
        ).catch(logger.error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workItems, isWorkItemsLoading],
  );

  useEffect(() => {
    if (migrateToGraphQL && project) {
      staApolloClient.query({
        query: GET_OFFERS_BY_SEARCH_QUERY,
        variables: {
          query: {
            programId: project.id,
          },
        },
        fetchPolicy: 'no-cache',
      }).then((response) => {
        if (response.data?.offers?.length) {
          setOffers(response.data?.offers);
        }
      }).catch((error) => {
        logger.error(error.message);
      });
    }
  }, [project, staApolloClient, migrateToGraphQL, task?.taskId]);

  /**
   * Router
   */
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(
    () => {
      if (isFunction(onSelectMemberIds)) {
        onSelectMemberIds(map(selectedMembers, (member) => member.id));
      }
    },
    [onSelectMemberIds, selectedMembers],
  );
  const workItemByMemberId = useMemo(
    (): { [id: number]: TWorkItem } => (
      keyBy<TWorkItem>(workItems, (workItem) => workItem.data.memberId)
    ),
    [workItems],
  );
  const selectedWorkItems = useMemo(
    (): TWorkItem[] => reduce(
      selectedMembers,
      (result, member) => {
        const workItem = workItemByMemberId[member.id];
        if (workItem) {
          result.push(workItem);
        }
        return result;
      },
      [],
    ),
    [selectedMembers, workItemByMemberId],
  );
  const selectedWorkItemIds = useMemo(
    () => map(selectedWorkItems, ({ id }) => id),
    [selectedWorkItems],
  );

  useEffect(
    () => {
      if (isFunction(onSelectWorkItems)) {
        onSelectWorkItems(selectedWorkItemIds);
      }
    },
    [onSelectWorkItems, selectedWorkItemIds],
  );

  const resetSelection = useCallback(() => {
    setSelectedMembers([]);
  }, [setSelectedMembers]);

  /**
   * Completed members
   */
  const {
    data: {
      members: completedMembers = undefined,
    } = {},
    loading: isFetchingCompletedMembers,
    refetch: refetchCompletedMembers,
  } = useGetAllCompletedMembersQuery({
    variables: {
      programId: project?.id,
    },
    skip: !isCompletedPage || !project?.id,
  });

  /**
   * Application
   */
  const [appWorkflowParameters, setAppWorkflowParamters] = useState<IWorkflowActionParameters>();
  const [deepLinkParameters, setDeepLinkParameters] = useState<string>();

  const [applicationId, setApplicationId] = useState(task?.taskMetaData?.serviceId);

  const allApplicationIds = useMemo(
    () => {
      const applicationIds = uniq(
        map(workItems, (workItem) => getTaskBySpecUriAndId(workItem.specURI, workItem.taskId)?.taskMetaData?.serviceId),
      );
      return reject([...applicationIds, CONTENT_APP_ID], isNil);
    },
    [getTaskBySpecUriAndId, workItems],
  );

  const {
    data: {
      applications = null,
    } = {},
    loading: isApplicationsLoading,
    refetch: refetchApplications,
  } = useGetApplicationsByIds(allApplicationIds, {
    skip: !allApplicationIds.length,
  });

  const application = applications?.find((app) => app.id === applicationId);

  /**
   * Refetch all data
   */
  const refetchData = useCallback(async () => {
    resetSelection();
    if (project?.id) {
      if (isCompletedPage) {
        await refetchCompletedMembers({
          programId: project.id,
        });
      } else {
        await refetchWorkItems();
        await refetchCounts();
        await refetchFlexCompletedMemberIds();
        await refetchNoDeliverableMemberIds();
      }
    }
    await refetchApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageId,
    workletSpecUri,
    project,
    isCompletedPage,
  ]);

  useEffect(
    () => {
      if (!isWorkItemsLoading) {
        refetchData();
      }
    },
    [
      refetchData,
      isWorkItemsLoading,
    ],
  );

  const currentStageTitle = useMemo(() => {
    switch (pageState) {
      case ProjectsPageState.AllInProgress:
        return 'All In Progress';
      case ProjectsPageState.Completed:
        return 'Completed';
      case ProjectsPageState.Worklet:
        return getWorkletNameBySpecURI(workletSpecUri, worklets);
      case ProjectsPageState.Task:
        if (isNewWorkletMenuEnabled) {
          return getWorkletNameBySpecURI(workletSpecUri, worklets);
        }
        return task?.taskName;
    }
  }, [isNewWorkletMenuEnabled, pageState, task?.taskName, workletSpecUri, worklets]);

  /**
   * Member search query based on the data
   */
  const memberIds = useMemo(
    () => {
      if (isWorkItemsLoading || !pageId) {
        return [];
      }
      switch (pageId) {
        case ProjectsPageState.AllInProgress:
          return map(workItems, (workItem) => parseInt(workItem.data.memberId, 10));
        case ProjectsPageState.Worklet:
          return map(workItems, (workItem) => parseInt(workItem.data.memberId, 10));
        case ProjectsPageState.Completed:
          return map(completedMembers, (member) => parseInt(member.data.memberId, 10));
        default:
          if (checkTaskIdHasTabs(task?.taskId, [Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
            return noDeliverableMemberIds || [];
          }
          if (checkTaskIdHasTabs(task?.taskId, [Task.Done])) {
            return flexCompletedMemberIds || [];
          }
          if (checkTaskIdHasTabs(task?.taskId, [Task.All])) {
            return uniq([
              ...map(workItems, (workItem) => parseInt(workItem.data.memberId, 10)),
              ...(flexCompletedMemberIds || []),
              ...(noDeliverableMemberIds || []),
            ]);
          }
          if (checkTaskIdHasTabs(task?.taskId, [Task.NeedsAction])) {
            return map(workItems, (workItem) => parseInt(workItem.data.memberId, 10));
          }
          return reduce(
            workItems,
            (result, workItem) => {
              if (task && task.workletSpecUri === workItem.specURI && task.taskId === workItem.taskId) {
                result.push(parseInt(workItem.data.memberId, 10));
              }
              return result;
            },
            [],
          );
      }
    },
    [completedMembers, flexCompletedMemberIds, isWorkItemsLoading, pageId, task, workItems, noDeliverableMemberIds],
  );
  const membersSearchQuery = useMemo(
    (): IMembersTableSearchQuery =>
      (!isEmpty(memberIds) && !isUndefined(isPFAColumnsEnabled) && project?.id
        ? {
            query: {
              includeMemberIds: memberIds,
              joinOnProgramId: project.id,
              stage: isFlexEnabled
                ? ['AllInProgress', 'Completed'].includes(pageState)
                  ? fieldResolversMapperForMembers.PFA.join(',')
                  : fieldResolversMapperForMembers.PFA.includes(workletSpecUri)
                  ? workletSpecUri
                  : null
                : null,
              isFlexibleProject: isFlexEnabled,
            },
            includeLastMessage: true,
            includeTags: true,
            includeCommunities: true,
            includePrograms: true,
            includeProgramMemberships: true,
            includeProgramMembershipsLogs: true,
            includeOwners: true,
            includeOffers: isFlexEnabled,
            includeBriefs: isFlexEnabled,
            includeDeliverables: isFlexEnabled,
            includeOrders: isFlexEnabled,
            take: 200,
            skip: 0,
          }
        : undefined),
    [memberIds, isPFAColumnsEnabled, project?.id, workletSpecUri, isFlexEnabled, pageState],
  );

  const {
    fetchMembers: fetchNonFlexMembers,
    fetchFlexMembers,
    isLoading: isFetchLoading,
    members: membersData,
  } = useFetchMembers(
    {
      searchQuery: membersSearchQuery,
      fetchPolicy: isFlexEnabled ? 'cache-first' : 'network-only',
    },
    dynamicQueryFields,
  );

  const fetchMembers = isFlexEnabled ? fetchFlexMembers : fetchNonFlexMembers;

  const { data: totalCountData, loading: isTotalLoading, refetch: refetchTotalCount } = useMemberSearchCountQuery({
    variables: {
      query: membersSearchQuery?.query,
    },
    skip: !membersSearchQuery?.query || !isFlexEnabled,
  });

  const members = useMemo(
    () => {
      let uniqueMemberIds = memberIds;
      if ([ProjectsPageState.AllInProgress, ProjectsPageState.Completed].includes(pageState)) {
        uniqueMemberIds = uniq(memberIds);
      }
      const memberById = keyBy(membersData, 'id');
      return map(filter(uniqueMemberIds, (id) => !!memberById[id]), (id) => memberById[id]);
    },
    [pageState, membersData, memberIds],
  );

  const { refreshSelectedMembers } = useRefreshSelectedMembers({
    selectedMembers,
    members,
    updateSelectedMembers: setSelectedMembers,
  });

  const [bulkMoveTo] = useBulkMoveToMutation();

  const handleMoveToStage = useCallback(async (workItemId: string) => {
    try {
      const condition = find(conditions, { workletSpecKey: task.workletSpecKey });
      if (!condition) {
        showErrorMessage(`Unable to move to Send ${isRenameTermsEnabled ? 'Briefs' : 'Terms'}: Could not find the appropriate condition`);
        logger.error('Could not find condition for worklet spec key', task.workletSpecKey);
        return;
      }
      showInfoMessage(`Moving to Send ${isRenameTermsEnabled ? 'Briefs' : 'Terms'}`);
      await bulkMoveTo({
        variables: {
          workItemIds: [workItemId],
          conditionId: condition.conditionId,
        },
        onCompleted: () => {
          refetchData();
          showSuccessMessage(`Moved to Send ${isRenameTermsEnabled ? 'Briefs' : 'Terms'}`);
        },
        onError: (error) => {
          logger.error(error);
          showErrorMessage(`Unable to Move to Send ${isRenameTermsEnabled ? 'Briefs' : 'Terms'}`);
        },
      });
    } catch (error) {
      logger.error(error);
    }
  }, [bulkMoveTo, conditions, refetchData, showErrorMessage, showInfoMessage, showSuccessMessage, task?.workletSpecKey, isRenameTermsEnabled]);

  /**
   * Handlers
   */
  const handleOpenApplication = useCallback(async (workItem, applicationIdParam?: string, taskParam?: ITask) => {
    const singleTask = workItem ? getTaskBySpecUriAndId(workItem.specURI, workItem.taskId) : (taskParam || task);
    const singleApplicationId = workItem ? singleTask?.taskMetaData?.serviceId : (applicationIdParam || applicationId);
    if (!singleTask) {
      logger.error('Cannot open application without task');
      return;
    }
    if (!singleApplicationId) {
      logger.error('Cannot open application without task with no service ID');
      return;
    }
    setApplicationId(singleApplicationId);

    const isBulkAction = !workItem;
    const actionDeepLink = isBulkAction ? singleTask?.taskMetaData?.bulkCTADeepLink : singleTask?.taskMetaData?.singleCTADeepLink;

    try {
      const bulkCTADeepLinkCTA = JSON.parse(singleTask?.taskMetaData?.bulkCTADeepLink);
      if (bulkCTADeepLinkCTA?.action === 'fix_terms' && !isBulkAction) {
        logger.debug('Moving to Send Briefs');
        await handleMoveToStage(workItem.id);
        return;
      }
    } catch {
      setDeepLinkParameters(null);
    }

    try {
      setDeepLinkParameters(JSON.parse(actionDeepLink));
    } catch {
      logger.error(`Unable to parse deeplink parameters for task: ${singleTask.taskName}. Link: ${actionDeepLink}`);
      setDeepLinkParameters(null);
    }

    const workItems = !isEmpty(workItem)
      ? [workItem]
      : selectedWorkItems;
    setAppWorkflowParamters({
      memberIds: map(workItems, (workItem) => workItem.data.memberId),
      programId: project.id,
      workItems,
      taskId: singleTask.taskId,
      workletSpecUri: singleTask.workletSpecUri,
      programName: project.title,
    });
    const membersForAction = !isEmpty(workItem)
      ? [find(members, { id: workItem.data.memberId })]
      : selectedMembers;
    if (TASKS_REQUIRING_EMAIL.includes(singleTask.taskId) && isArray(membersForAction) && !isEmpty(membersForAction)) {
      const membersWithoutEmail = filter(membersForAction, (member) => !member.email);
      if (membersWithoutEmail.length === membersForAction.length) {
        Modal.confirm({
          title: members.length === 1 ? 'Cannot send brief to member' : 'Cannot send brief to members',
          icon: <ExclamationCircleOutlined />,
          content: membersForAction.length === 1
            ? 'The member you selected does not have an email address. Add their email to send a brief.'
            : `${membersForAction.length}/${membersForAction.length} members you selected do not have email addresses. Add their emails to send them a brief.`,
          okText: 'Okay',
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
        });
        return;
      } else if (membersWithoutEmail.length > 0) {
        Modal.confirm({
          title: 'Some members are missing email',
          icon: <ExclamationCircleOutlined />,
          content: `${membersWithoutEmail.length}/${membersForAction.length} members you selected do not have email addresses. These members will not be sent a brief.`,
          okText: 'Continue',
          cancelText: 'Cancel',
          onOk: () => {
            history.push({
              ...location,
              pathname: `${match.url}/app/${singleApplicationId}`,
            });
          },
        });
        return;
      }
      history.push({
        ...location,
        pathname: `${match.url}/app/${singleApplicationId}`,
      });
    } else {
      history.push({
        ...location,
        pathname: `${match.url}/app/${singleApplicationId}`,
      });
    }
  }, [getTaskBySpecUriAndId, task, project, selectedWorkItems, history, location, match, applicationId, members, selectedMembers, handleMoveToStage]);

  const handleOpenDeliverables = useCallback(async (memberId: number) => {
    setApplicationId(CONTENT_APP_ID);

    const actionDeepLink = {
      action: 'view_deliverables'
    };

    setDeepLinkParameters(actionDeepLink as any);

    setAppWorkflowParamters({
      memberIds: [Number(memberId)],
      programId: project.id,
      workItems: [],
      taskId: Task.WaitingForContentTask,
      workletSpecUri: WorkletSpecUri.ReviewContentWorkletSpecification,
      programName: project.title,
    });
    history.push({
      ...location,
      pathname: `${match.url}/app/${CONTENT_APP_ID}`,
    });
  }, [history, location, match.url, project?.id, project?.title]);

  const customCTAAction = useCustomCTA(project?.id, task);

  const onClickSingleCTA = useCallback(async (workItem: TWorkItem) => {
    if (workItem.specURI === 'SendContractWorkletSpecification' && !installedApps[CONTRACT_APP_ID]) {
      message.info({
        content: 'Contract app is not installed on your account. Please contact support to get started.',
        style: messageContainerFixed,
      }, 5);
      return;
    }
    if (isFunction(customCTAAction)) {
      try {
        await customCTAAction([workItem]);
      } catch (error) {
        logger.error('Error calling custom action', { error });
        showErrorMessage('There was an error when triggering the CTA. Please reach out to support.');
      }
      await refetchData();
    } else {
      handleOpenApplication(workItem);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customCTAAction, showErrorMessage, refetchData, members, installedApps]);

  const handleSelectMembers = useCallback(
    (members) => {
      setSelectedMembers(members);
    },
    [setSelectedMembers],
  );

  useEffect(() => {
    refreshSelectedMembers();
  }, [refreshSelectedMembers]);

  const handleCheckIn = () => {
    toastRef.current?.showMessage({
      type: 'success',
      content: 'Successfully marked as done',
    });
  };

  const handleRowClicked = useCallback((memberId: IMember['id']) => {
    history.push(`${match.url}/member/${memberId}`);
  }, [
    match,
    history,
  ]);

  const handleLastMessageClicked = useCallback(
    (threadId: IThread['id']) => {
      history.push(`${match.url}/threads/${threadId}`);
    },
    [match, history],
  );

  /**
   * Table column config
   */
  const {
    visibleColumnIds,
  } = useWorkItemsTableColumnConfig({
    pageState,
    showInlineCTA: !isEmpty(task?.taskMetaData?.singleCTAText),
    task,
    isOfferPromoLink: first(offers)?.isPromoLink,
  });

  const workItemsFiltersConfig = useMemo(() => {
    const workItemFilters = [{
      label: 'Owned by Me',
      filter: OWNED_BY_ME_FILTER_VALUE,
    }];

    if (currentStageTitle === CONTENT_STAGES.CONTENT
      || currentStageTitle === CONTENT_STAGES.TRACK_POSTS
      || task?.workletSpecUri === 'TrackPostsWorkletSpecification'
      || task?.workletSpecUri === 'ReviewContentWorkletSpecification') {
      workItemFilters.push({
        label: 'Posts tracked < 7 days',
        filter: LAST_MENTION_DATE_7DAYS_FILTER_VALUE,
      });
    }

    if (
      (currentStageTitle === CONTENT_STAGES.REVIEW_CONTENT || currentStageTitle === CONTENT_STAGES.CONTENT)
      && task?.taskId === 'review_content_task' && project?.gcrEnabled
    ) {
      workItemFilters.push(...ContentReviewFilters);
    }
    return workItemFilters;
  }, [currentStageTitle, task, project]);

  const cellActionsByColumn = useMemo(() => ({
    name: handleRowClicked,
    lastMessage: handleLastMessageClicked,
    cta: onClickSingleCTA,
    task: onTaskSelected,
  }), [
    handleRowClicked,
    handleLastMessageClicked,
    onTaskSelected,
    onClickSingleCTA,
  ]);

  /**
   * Table data
   */
  const mergedTaskDataForWorkItem = (workItem) => {
    const taskDataContainers = [workItem?.data, workItem?.dataUpdated];
    return reduce(taskDataContainers, (mergedData, taskData) => {
      if (taskData) {
        return {
          ...mergedData,
          ...reduce(keys(taskData), (mergedContainerData, key) => {
            if (endsWith(key, '_data')) {
              return {
                ...mergedContainerData,
                ...taskData[key],
              };
            }
            return mergedContainerData;
          }, {}),
        };
      } else {
        return mergedData;
      }
    }, {});
  };

  const workItemsData = useMemo(
    () => {
      if (!isFunction(getTaskBySpecUriAndId)) {
        return undefined;
      }
      const orderCatalogInfoByOrderId = keyBy(creatorsOrderCatalogInfo, 'orderId');
      return map(workItems, (workItem) => {
        const taskData = mergedTaskDataForWorkItem(workItem);
        const taskBySpecUriAndId = getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId);
        return {
          ...taskData,
          viewed: workItem.viewed,
          [ColumnKey.ID]: workItem.data.memberId,
          [ColumnKey.CTA]: {
            taskMetaData: isEmpty(task?.taskMetaData) ? taskBySpecUriAndId?.taskMetaData : task?.taskMetaData,
            workItem,
          },
          [ColumnKey.DateAdded]: workItem?.startTime
            ? moment(workItem.startTime).format('MM/DD/YYYY')
            : undefined,
          [ColumnKey.DaysInStage]: workItem?.startTime
            ? moment().diff(moment(workItem.startTime), 'days')
            : undefined,
          [ColumnKey.TaskName]: getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId),
          [ColumnKey.WorkletTaskName]: getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId),
          [ColumnKey.WorkletName]: getTaskBySpecUriAndId(workItem?.specURI, workItem?.taskId),
          ...(task?.workletSpecKey === WorkletSpecKey.PFACreatorProductSelectionV2 ? {
            [ColumnKey.SentCatalog]: {
              id: orderCatalogInfoByOrderId[taskData?.orderId]?.brandCatalogId,
              name: orderCatalogInfoByOrderId[taskData?.orderId]?.brandCatalogName,
            },
            [ColumnKey.SentSelectionRule]: {
              id: orderCatalogInfoByOrderId[taskData?.orderId]?.selectionRuleId,
              name: orderCatalogInfoByOrderId[taskData?.orderId]?.selectionRuleName,
            },
          } : {}),
          _raw: workItem,
          _bodyRowClassName: cx(
            styles.bodyRow,
            { [styles.newWorkItem]: !workItem.viewed && pageState !== ProjectsPageState.AllInProgress },
          ),
        };
      });
    },
    [getTaskBySpecUriAndId, creatorsOrderCatalogInfo, workItems, task?.taskMetaData, task?.workletSpecKey, pageState],
  );

  const completedMembersData = useMemo(
    () => map(completedMembers, (completedMember) => ({
      ...completedMember,
      ...get(completedMember, 'data', {}),
      [ColumnKey.ID]: completedMember.data.memberId,
      [ColumnKey.DateCompleted]: completedMember?.dateCompleted
        ? moment(completedMember.dateCompleted).format('MM/DD/YYYY')
        : undefined,
      _raw: completedMember,
      _bodyRowClassName: styles.bodyRow,
    })),
    [completedMembers],
  );
  const data = isCompletedPage ? completedMembersData : workItemsData;

  const handleFetchMembers = useCallback(() => {
    fetchMembers(membersSearchQuery);
  }, [membersSearchQuery, fetchMembers]);

  const { pluralTermsName } = useGetTermsName();

  /**
   * Loading
   */
  const isLoading = useMemo(() =>
    isApplicationsLoading
    || isFetchingCompletedMembers
    || isWorkItemsLoading
    || isFetchLoading
    || isLoadingPredefinedProgramSegments
    || isLoadingDefaultProgramSegments
    || isLoadingReviews
    || isTotalLoading,
    [
      isApplicationsLoading,
      isFetchingCompletedMembers,
      isWorkItemsLoading,
      isFetchLoading,
      isLoadingPredefinedProgramSegments,
      isLoadingDefaultProgramSegments,
      isLoadingReviews,
      isTotalLoading,
    ]);

  let renderedGuidelines: React.ReactElement;
  let renderedGuidelinesAlert: React.ReactElement;
  if (
    contentGuidelinesEnabled
    && task?.taskId === WorkflowTask.SEND_TERMS_TASK
    && !otherProjectGuidelinesLoading
  ) {
    if (!isEmpty(otherProjectGuidelinesData?.guidelines?.contentGuidelines)) {
      renderedGuidelines = (
        <Button
          type="ghost"
          onClick={() => history.push({
            ...location,
            pathname: `/settings/${TERMS_APP_ID}`,
            search: `?projectId=${project.id}`,
          })}
        >
          Manage Content Guidelines
        </Button>
      );
    } else {
      renderedGuidelinesAlert = (
        <div
          style={{ margin: '10px' }}
        >
          <Alert
            message={`No content guidelines for this project. Set up content guidelines to quickly send ${pluralTermsName} to members.`}
            type="warning"
            action={(
              <Space>
                <Button
                  size="small"
                  type="ghost"
                  onClick={() => history.push({
                    ...location,
                    pathname: `/settings/${TERMS_APP_ID}`,
                    search: `?projectId=${project.id}`,
                  })}
                >
                  Manage Content Guidelines
                </Button>
              </Space>
            )}
          />
        </div>
      );
    }
  }

  /**
   * Render
   */
  const renderTitle = () => (
    <div className={styles.title}>
      <Space
        direction="vertical"
        size={[32, 32]}
        className={styles.spacer}
      >
        {isArchiveProjectEnabled && renderArchivedNotice}
        <Row
          justify="space-between"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          align="baseline"
        >
          <h1>{currentStageTitle}</h1>
          {renderedGuidelines}
        </Row>
      </Space>
    </div>
  );

  const renderEmptyPlaceholder = (searchText?: string, isFilterApplied?: boolean) => {
    if (isLoading || isUndefined(workItems)) return;

    // This condition is specifically for flex enabled projects
    if (isFlexEnabled) {
      if (isEmpty(members) && (searchText || selectedQuickFilter || isFilterApplied)) {
        return (
          <div className={styles.empty}>
            <MagnifyingGlassIcon className={styles.searchIcon} />
            <Title level={5}>No members found</Title>
            <Text type="secondary">
              Try adjusting your search. Members can be searched by any of the available columns.
            </Text>
          </div>
        );
      }

      if (isEmpty(members)) {
        return (
          <div className={styles.empty}>
            Add people to the project
            <br />
            <a onClick={() => openAddToCollectionModal(ModalType.AddFromGroup)}>from your groups</a>
            &nbsp;or&nbsp;
            <a onClick={() => openAddToCollectionModal(ModalType.AddFromProject)}>from other projects</a>
            .
          </div>
        );
      }
    }

    if (!isEmpty(data) && (searchText || selectedQuickFilter)) {
      // return this empty state when user is searching members from search box
      return (
        <div className={styles.empty}>
          <MagnifyingGlassIcon className={styles.searchIcon} />
          <Title level={5}>No members found</Title>
          <Text type="secondary">
            Try adjusting your search. Members can be searched by any of the available columns.
          </Text>
        </div>
      );
    }

    if (isEmpty(data)) {
      // return this empty state when stage has no members
      return (
        <div className={styles.empty}>
          Add people to the project
          <br />
          <a onClick={() => openAddToCollectionModal(ModalType.AddFromGroup)}>from your groups</a>
          &nbsp;or&nbsp;
          <a onClick={() => openAddToCollectionModal(ModalType.AddFromProject)}>from other projects</a>
          .
        </div>
      );
    }

    return <></>;
  };

  const onWorkItemBoxClick = useCallback((workItemBoxTask: ITask, taskId: string) => {
    if (!workItemBoxTask || task?.taskId === taskId) return;
    resetSelection();
    onTaskSelected(workItemBoxTask.workletSpecUri, taskId);
  }, [resetSelection, onTaskSelected, task]);

  // Sets rounded side menu when worklets are rendered as tabs
  useEffect(() => {
    if (
      !(
        !isNewWorkletMenuEnabled
        || isNil(workletSpecUri)
        || isNil(counts)
        || (pageState !== ProjectsPageState.Task && pageState !== ProjectsPageState.Worklet)
        || workletSpecUri.includes('CustomWorklet-')
      )
      && isRefreshUIEnabled
    ) {
      setRoundedSideMenu(true);
    } else {
      setRoundedSideMenu(false);
    }

    return () => {
      setRoundedSideMenu(false);
    };
  }, [
    isNewWorkletMenuEnabled,
    workletSpecUri,
    counts,
    pageState,
    isRefreshUIEnabled,
    setRoundedSideMenu,
  ]);

  const { taskIds, removeTaskId } = useTaskProgressMonitor();

  const renderWorkItemBoxes = () => {
    if (!isNewWorkletMenuEnabled) return null;

    if (isNil(workletSpecUri) || isNil(counts)) return null;
    if (pageState !== ProjectsPageState.Task && pageState !== ProjectsPageState.Worklet) return null;

    if (!isFlexEnabled && workletSpecUri.includes('CustomWorklet-')) return null;
    const selectedTasks = tasks ? tasks.filter((t) => t.workletSpecUri === workletSpecUri) : [];
    const countsPerWorklet = counts?.[workletSpecUri];
    let total = 0;
    if (!isNil(countsPerWorklet)) {
      const keysPerWorklet = keys(countsPerWorklet) ?? [];
      total = reduce(keysPerWorklet.map((key) => countsPerWorklet[key].total), function (sum, n) {
        return sum + n;
      }, 0);
    }

    if (isRefreshUIEnabled) {
      const tabs = selectedTasks
        .filter((t) => {
          const taskUI = parseToITaskUI(t);
          const isTransientValue = isTransient(t);
          const count = counts?.[t.workletSpecUri]?.[t.taskId]?.total ?? 0;

          // Moved the condition from WorkItemBox component
          return !taskUI || !isTransientValue || (isTransientValue && count > 0);
        })
        .map((t) => ({
          name: t.taskId,
          label: `${t.taskName} (${counts?.[t.workletSpecUri]?.[t.taskId]?.total ?? 0})`,
          hasNotification: hasNotification(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total),
        }));

      const newTabs = isFlexibleProjectEnabled ? [
        ...(
          workletSpecUri === WorkletSpecUri.ReviewContentWorkletSpecification
            ? [
              { label: `No Deliverables (${counts?.[workletSpecUri]?.[Task.NoDeliverables]?.total ?? 0})`, name: `${workletSpecUri}-${Task.NoDeliverables}` },
            ]
            : []
        ),
        ...(
          workletSpecUri === WorkletSpecUri.TrackPostsWorkletSpecification
            ? [
              { label: `No Post (${counts?.[workletSpecUri]?.[Task.NoPost]?.total ?? 0})`, name: `${workletSpecUri}-${Task.NoPost}` },
            ]
            : []
        ),
        ...(
          workletSpecUri === WorkletSpecUri.PaymentWorkletSpecification
            ? [
              { label: `Not Due (${counts?.[workletSpecUri]?.[Task.NotDue]?.total ?? 0})`, name: `${workletSpecUri}-${Task.NotDue}` },
            ]
            : []
        ),
        { label: `Needs Action (${counts?.[workletSpecUri]?.[Task.NeedsAction]?.total ?? 0})`, name: `${workletSpecUri}-${Task.NeedsAction}` },
        ...tabs,
        { label: `Done (${counts?.[workletSpecUri]?.[Task.Done]?.total ?? 0})`, name: `${workletSpecUri}-${Task.Done}` },
        { label: `All (${counts?.[workletSpecUri]?.[Task.All]?.total ?? 0})`, name: `${workletSpecUri}-${Task.All}` },
      ] : [
        { label: `In Stage (${total})`, name: `${workletSpecUri}-In Stage` },
        ...tabs,
      ];

      const transformedTabs = isFlexibleProjectEnabled ? transformTaskItemLabels(newTabs) : newTabs;
      const transformedTabsWithIcons = transformedTabs.map((tab) => {
        const hasErrorValue = tab.label.toLowerCase().includes('error');
        return {
          ...tab,
          icon: hasErrorValue ? <TriangleExclamationIcon fill="#d9534f" /> : null,
        };
      });

      return (
        <div className="shadcn bg-primary">
          <TabComponent
            tabs={transformedTabsWithIcons}
            activeTab={
              pageState === ProjectsPageState.Worklet ? `${workletSpecUri}-In Stage` : task?.taskId
            }
            onTabChange={(tabName) => {
              if (tabName === `${workletSpecUri}-In Stage`) {
                onWorkItemBoxClick(task, undefined);
              } else if (checkTaskIdHasTabs(tabName, [Task.NeedsAction, Task.Done, Task.All, Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
                onWorkItemBoxClick({
                  workletSpecUri,
                } as ITask, tabName);
              } else {
                const clickedTask = selectedTasks.find((t) => t.taskId === tabName);
                onWorkItemBoxClick(clickedTask, clickedTask.taskId);
              }
            }}
            activeTabBackgroundTailwindColor="bg-white"
          />
        </div>
      );
    }

    return (
      <div className={styles.workItemBoxContainer}>
        <WorkItemItemBox
          count={total}
          onClick={() => onWorkItemBoxClick(task, undefined)}
          key={`${workletSpecUri}-In Stage`}
          name="In Stage"
          isActive={pageState === ProjectsPageState.Worklet}
          hasError={false}
          hasNotification={false}
          isTransient={false}
          taskUI={undefined}
        />
        {
          selectedTasks.map((t) => (
            <WorkItemItemBox
              count={counts?.[t.workletSpecUri]?.[t.taskId]?.total}
              onClick={() => onWorkItemBoxClick(t, t.taskId)}
              key={t.taskName}
              name={t.taskName}
              isActive={t.taskId === task?.taskId}
              hasError={hasError(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total)}
              hasNotification={hasNotification(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total)}
              isTransient={isTransient(t)}
              taskUI={parseToITaskUI(t)}
            />
          ))
        }
      </div>
    );
  };

  const count = useMemo(
    () => {
      if (!isEmpty(selectedMembers)) {
        return selectedMembers.length;
      }
      switch (pageState) {
        case ProjectsPageState.AllInProgress:
          return get(counts, 'all_in_progress', 0);
        case ProjectsPageState.Task:
          if (isNil(task)) {
            return 0;
          }
          const taskTotal = counts?.[task.workletSpecUri]?.[task.taskId]?.total;
          return taskTotal || 0;
        case ProjectsPageState.Worklet:
          if (isNil(counts) || isNil(workletSpecUri)) return 0;

          const countsPerWorklet = counts?.[workletSpecUri];
          if (isNil(countsPerWorklet)) return 0;

          const keysPerWorklet = keys(countsPerWorklet) ?? [];
          return reduce(keysPerWorklet.map((key) => countsPerWorklet[key].total), function (sum, n) {
            return sum + n;
          }, 0);
        case ProjectsPageState.Completed:
          return get(counts, 'completed', 0);
      }
    },
    [counts, pageState, selectedMembers, task, workletSpecUri],
  );

  const handleBack = useCallback(() => {
    history.push({
      ...location,
      pathname: match.url,
    });
  }, [match.url, history, location]);

  const renderHeaderActions = () => (
    isFlexEnabled ? (
      <FlexTableHeader
        buttons={{
          applications: false,
          columns: false,
          compose: true,
          ctaBulkAction: true,
          cta: true,
          refresh: true,
          tags: true,
          delete: false,
        }}
        disabledButtons={{
          applications: isLoading,
          compose: isLoading,
          cta: isLoading,
          refresh: isLoading,
          tags: isLoading,
        }}
        count={selectedMembers?.length ? count : displayedRowCount}
        countSuffix={selectedMembers?.length ? 'selected' : undefined}
        onCheckIn={handleCheckIn}
        onOpenApplication={handleOpenApplication}
        openAddToCollectionModal={openAddToCollectionModal}
        pageState={pageState}
        project={project}
        refetchData={refetchData}
        selectedMemberIds={map(selectedMembers, (member) => member.id)}
        selectedMembers={selectedMembers}
        selectedWorkItems={selectedWorkItems}
        selectedWorkItemIds={selectedWorkItemIds}
        task={task}
        tasks={tasks?.filter((t) => t.workletSpecUri === workletSpecUri)}
        worklets={worklets}
        conditions={conditions}
        showSelectOffer={showSelectOffer}
        migrateToGraphQL={migrateToGraphQL}
        offers={offers}
      />
    ) : (
      <TableHeader
        buttons={{
          applications: false,
          columns: false,
          compose: true,
          ctaBulkAction: true,
          cta: true,
          refresh: true,
          tags: true,
          delete: false,
        }}
        disabledButtons={{
          applications: isLoading,
          compose: isLoading,
          cta: isLoading,
          refresh: isLoading,
          tags: isLoading,
        }}
        count={selectedMembers?.length ? count : displayedRowCount}
        countSuffix={selectedMembers?.length ? 'selected' : undefined}
        onCheckIn={handleCheckIn}
        onOpenApplication={handleOpenApplication}
        openAddToCollectionModal={openAddToCollectionModal}
        pageState={pageState}
        project={project}
        refetchData={refetchData}
        selectedMemberIds={map(selectedMembers, (member) => member.id)}
        selectedMembers={selectedMembers}
        selectedWorkItems={selectedWorkItems}
        selectedWorkItemIds={selectedWorkItemIds}
        task={task}
        worklets={worklets}
        conditions={conditions}
        showSelectOffer={showSelectOffer}
        migrateToGraphQL={migrateToGraphQL}
        offers={offers}
      />
    )
  );

  useEffect(() => {
    setSelectedQuickFilter('');
  }, [currentStageTitle, task?.taskId]);

  const handleSelectQuickFilter = (newQuickFilter: string) => {
    resetSelection();
    if (newQuickFilter === selectedQuickFilter) {
      setSelectedQuickFilter('');
    } else {
      setSelectedQuickFilter(newQuickFilter);
    }
  };
  useEffect(() => {
    if ([InternalContentReviewStatus.SOFT_APPROVED].includes(selectedQuickFilter as InternalContentReviewStatus)) {
      refetchFilteredGCRMemberIds({
        programId: project?.id,
        statuses: [InternalContentReviewStatus.SOFT_APPROVED, InternalContentReviewStatus.SOFT_REJECTED],
        limit: 200,
        offset: 0,
      });
    }
    if ([InternalContentReviewStatus.SUBMITTED].includes(selectedQuickFilter as InternalContentReviewStatus)) {
      refetchFilteredGCRMemberIds({
        programId: project?.id,
        statuses: [selectedQuickFilter as InternalContentReviewStatus],
        limit: 200,
        offset: 0,
      });
    }
  }, [selectedQuickFilter, refetchFilteredGCRMemberIds, project?.id]);

  const filteredMembers = useMemo(() => {
    if (isLoadingReviews || task?.taskId !== 'review_content_task') {
      return members;
    }
    if ([InternalContentReviewStatus.SOFT_APPROVED, InternalContentReviewStatus.SUBMITTED].includes(selectedQuickFilter as InternalContentReviewStatus)) {
      return members?.filter((member) => (filteredGCRMemberIds.includes(member.id)));
    } else {
      return members;
    }
  }, [
    members,
    filteredGCRMemberIds,
    selectedQuickFilter,
    isLoadingReviews,
    task?.taskId,
  ]);

  const customTaskNotice = useCustomTaskNotice(project?.id, task, memberIds, project?.title);
  if (offers && offers.length > 0) {
    const [firstOffer] = offers || [];
    const [firstPromo] = firstOffer?.promos || [];
    const affiliates = firstPromo?.affiliates;
    if (affiliates && affiliates.length > 0) {
      map(data, (member) => {
        const memberId = member.id;
        const affiliate = affiliates.find((a) => a.affiliate.memberId === memberId);
        if (affiliate) {
          member.promoCode = affiliate.externalDiscountCode;
        }
        if (firstOffer.isPromoLink && firstOffer.links.length) {
          // find affiliateofferLink for member
          const offerLink = first(firstOffer.links);
          const affiliate = find(offerLink.affiliates, (af) => af.affiliate.memberId === memberId);
          if (affiliate && affiliate.affiliateShortLink) {
            member.affiliateLink = affiliate.affiliateShortLink;
          }
        }
      });
    }
  }
  const renderTable = () => (
    <MemberListContextProvider programId={project?.id}>
      <div ref={tableContainerRef} className={styles.tableWrapper}>
        {isFlexEnabled ? (
          <MembersTableFiltersProvider>
            <FlexMembersTable
              className={cx(styles.membersTable, { [styles.loading]: isLoading })}
              project={project}
              projectSpecKey={project?.specKey}
              data={data}
              onSelectedDataChange={handleSelectMembers}
              refetchCounts={refetchCounts}
              selectedMembers={selectedMembers}
              containerHeight={height}
              cellActionsByColumn={cellActionsByColumn}
              renderEmptyPlaceholder={renderEmptyPlaceholder}
              renderHeaderActions={renderHeaderActions}
              searchQuery={membersSearchQuery}
              visibleColumnIds={visibleColumnIds}
              worklets={worklets}
              task={task}
              counts={counts}
              config={{
              allowSearch: true,
              pageSize: 200,
            }}
              setDisplayedTotalRowCount={setDisplayedRowCount}
              selectedQuickFilter={selectedQuickFilter}
              fetchMembers={fetchMembers}
              members={filteredMembers}
              pageId={pageId}
              workletSpecUri={workletSpecUri}
              savedColumns={savedColumns as IPredefinedSegment}
              defaultSavedColumns={defaultSavedColumns as IPredefinedSegment}
              isLoading={isLoading}
              isLoadingPredefinedProgramSegments={isLoadingPredefinedProgramSegments}
              onRefetchSegments={handleRefetchSegments}
              totalRowCount={totalCountData?.count}
              refetchTotalCount={refetchTotalCount}
              handleOpenDeliverables={handleOpenDeliverables}
              excludeColumns={isFlexibleProjectEnabled && pageState === ProjectsPageState.AllInProgress ? [ColumnKey.WorkletTaskName, ColumnKey.WorkletName] : []}
            />
          </MembersTableFiltersProvider>
        ) : (
          <MembersTable
            className={cx(styles.membersTable, { [styles.loading]: isLoading })}
            project={project}
            projectSpecKey={project?.specKey}
            data={data}
            onSelectedDataChange={handleSelectMembers}
            refetchCounts={refetchCounts}
            selectedMembers={selectedMembers}
            containerHeight={height}
            cellActionsByColumn={cellActionsByColumn}
            renderEmptyPlaceholder={renderEmptyPlaceholder}
            renderHeaderActions={renderHeaderActions}
            searchQuery={membersSearchQuery}
            visibleColumnIds={visibleColumnIds}
            worklets={worklets}
            task={task}
            counts={counts}
            config={{
              allowSearch: true,
              pageSize: 200,
            }}
            setDisplayedTotalRowCount={setDisplayedRowCount}
            selectedQuickFilter={selectedQuickFilter}
            fetchMembers={fetchMembers}
            members={filteredMembers}
            pageId={pageId}
            workletSpecUri={workletSpecUri}
            savedColumns={savedColumns as IPredefinedSegment}
            defaultSavedColumns={defaultSavedColumns as IPredefinedSegment}
            isLoading={isLoading}
            isLoadingPredefinedProgramSegments={isLoadingPredefinedProgramSegments}
            onRefetchSegments={handleRefetchSegments}
            isOfferPromoLink={first(offers)?.isPromoLink}
            isFlexEnabled={isFlexEnabled}
          />
        )}
      </div>
    </MemberListContextProvider>
  );

  return (
    <div
      className={cx(
        styles.ListDrawer,
        className,
        { [styles.loading]: isLoading },
      )}
    >
      {/* TODO: only show drawer when member details isn't open */}
      <Drawer
        className={styles.drawer}
        expanded
        hideToggle
      >
        {!isRefreshUIEnabled && renderTitle()}
        {renderWorkItemBoxes()}
        <Space
          className="mt-[0.8rem] px-8"
        >
          <QuickFilters
            onSelect={handleSelectQuickFilter}
            selectedFilter={selectedQuickFilter}
            hideFiltersCounts
            quickFiltersOptions={workItemsFiltersConfig}
          />
        </Space>
        <Divider className={styles.divider} />
        {renderedGuidelinesAlert}
        <div className={styles.customTaskNotice}>
          {customTaskNotice}
        </div>
        {isFlexEnabled && taskIds.length > 0 && (
          <AddMembersProgress
            taskTrackerId={taskIds[0]}
            onSyncStatusChange={(isSyncing) => {
              if (!isSyncing) {
                removeTaskId(taskIds[0]);
                refetchData();
              }
            }}
          />
        )}
        {renderTable()}
      </Drawer>

      <section className={styles.content}>
        <Switch>
          {/* TODO: figure out why match.path doesn't work */}
          <Route path="/projects/:projectId/:workletSpecUri/:taskId/app/:appId">
            {application && appWorkflowParameters && (
              <ApplicationDrawer
                application={application}
                deepLinkParameters={deepLinkParameters}
                memberId={null}
                workflowActionParameters={appWorkflowParameters}
                onApplicationClose={async () => {
                  refetchData();
                  handleFetchMembers();
                }}
                paymentAppPaddingEnabled={isBudgetAllocationEnabled && appWorkflowParameters?.taskId === Task.SendPaymentTask}
              />
            )}
          </Route>
          <Route path="/projects/:projectId/all_in_progress/app/:appId">
            {application && appWorkflowParameters && (
              <ApplicationDrawer
                application={application}
                deepLinkParameters={deepLinkParameters}
                memberId={null}
                workflowActionParameters={appWorkflowParameters}
                onApplicationClose={async () => {
                  refetchData();
                  handleFetchMembers();
                }}
                paymentAppPaddingEnabled={isBudgetAllocationEnabled && appWorkflowParameters?.taskId === Task.SendPaymentTask}
              />
            )}
          </Route>
          <Route
            path={[
              '/projects/:projectId/:workletSpecUri/:taskId/member/:memberId',
              '/projects/:projectId/:workletSpecUri/member/:memberId',
            ]}
            render={(routeProps) => (
              <Drawer
                className={cx(styles.drawer, styles.memberDetailsDrawer)}
                expanded
                hideToggle
              >
                {project && (
                  <FetchContextProvider>
                    <NewMemberDetailContainer
                      isInSentTermsStage={applicationId === TERMS_APP_ID}
                      memberId={parseInt(routeProps.match.params.memberId, 10)}
                      disabledApplicationIds={[applicationId]}
                      projectId={project.id}
                      refetchMembers={handleFetchMembers}
                      sendTermsAction={() => handleOpenApplication(workItemByMemberId[routeProps.match.params.memberId])}
                      onBackButtonClicked={handleBack}
                      isDeliverablesHidden={false}
                    />
                  </FetchContextProvider>
                )}
              </Drawer>
            )}
          />
          <Route
            path={[
              '/projects/:projectId/:workletSpecUri/:taskId/threads/:threadId',
              '/projects/:projectId/:workletSpecUri/threads/:threadId',
            ]}
            render={(routeProps) => (
              <Drawer
                className={cx(styles.drawer, styles.memberDetailsDrawer)}
                expanded
                hideToggle
              >
                <CommunitySwitcherProvider useQueryParams={false}>
                  <MessagingProvider>
                    <MessageList
                      threadId={routeProps.match.params.threadId}
                      hideAdditionalActions
                      onBackButtonClicked={() => {
                        history.goBack();
                      }}
                      fetchMembers={handleFetchMembers}
                    />
                  </MessagingProvider>
                </CommunitySwitcherProvider>
              </Drawer>
            )}
          />
        </Switch>
      </section>
      <Toast ref={toastRef} />
      <ShopifyReauthenticateModal />
    </div>
  );
});
