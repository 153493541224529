// Define our own content type enum since we can't import from AffiliatesApp
export enum ContentTypeEnum {
  INSTAGRAM = 'instagram',
  INSTAGRAM_REEL = 'instagram_reel',
  YOUTUBE = 'youtube',
  YOUTUBE_SHORT = 'youtube_short',
  TIKTOK = 'tiktok',
  FACEBOOK = 'facebook',
  PINTEREST = 'pinterest',
  IMAGE = 'image',
}

interface ThumbnailResponse {
  thumbnailUrl: string | null;
  error?: string;
  contentType: ContentTypeEnum | null;
  title?: string;
  author?: string;
}

/**
 * Detect content type from URL
 */
const detectContentType = (url: string): ContentTypeEnum | null => {
  // Instagram post vs reel detection
  const instagramMatch = url.match(/instagram\.com\/(p|reel)\/[\w-]+/);
  if (instagramMatch) {
    return instagramMatch[1] === 'reel' ? ContentTypeEnum.INSTAGRAM_REEL : ContentTypeEnum.INSTAGRAM;
  }

  // YouTube video vs short detection
  const youtubeMatch = url.match(
    /(?:youtube\.com\/(?:shorts\/|[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
  );
  if (youtubeMatch) {
    return url.includes('/shorts/') ? ContentTypeEnum.YOUTUBE_SHORT : ContentTypeEnum.YOUTUBE;
  }

  // Other platforms
  if (/tiktok\.com\/@[\w.-]+\/video\/\d+/.test(url)) {
    return ContentTypeEnum.TIKTOK;
  }
  if (/facebook\.com\/[\w.]+\/(?:posts|videos)\/[\w-]+/.test(url)) {
    return ContentTypeEnum.FACEBOOK;
  }
  if (/pinterest\.com\/pin\/[\w-]+/.test(url)) {
    return ContentTypeEnum.PINTEREST;
  }
  if (/\.(jpg|jpeg|png|gif|webp)(\?.*)?$/i.test(url)) {
    return ContentTypeEnum.IMAGE;
  }

  return null;
};

/**
 * Get YouTube video ID from URL
 */
const getYouTubeVideoId = (url: string): string | null => {
  const pattern = /(?:youtube\.com\/(?:shorts\/|[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  const match = url.match(pattern);
  return match ? match[1] : null;
};

/**
 * Format title based on content type and data
 */
const formatTitle = (contentType: ContentTypeEnum | null, author?: string, title?: string): string => {
  if (!author && !title) return '';

  switch (contentType) {
    case ContentTypeEnum.INSTAGRAM:
      return author ? `${author}'s post` : 'Instagram post';
    case ContentTypeEnum.INSTAGRAM_REEL:
      return author ? `${author}'s reel` : 'Instagram reel';
    case ContentTypeEnum.YOUTUBE:
      return title || (author ? `${author}'s video` : 'YouTube video');
    case ContentTypeEnum.YOUTUBE_SHORT:
      return title || (author ? `${author}'s short` : 'YouTube short');
    case ContentTypeEnum.TIKTOK:
      return title || (author ? `${author}'s video` : 'TikTok video');
    default:
      return title || (author ? `${author}'s content` : '');
  }
};

/**
 * Get thumbnail URL from social media post URL
 */
export const getSocialMediaThumbnail = async (url: string): Promise<ThumbnailResponse> => {
  try {
    // Detect content type
    const contentType = detectContentType(url);

    switch (contentType) {
      case ContentTypeEnum.INSTAGRAM:
      case ContentTypeEnum.INSTAGRAM_REEL: {
        const response = await fetch(
          `https://graph.facebook.com/v20.0/instagram_oembed?url=${encodeURIComponent(
            url,
          )}&media_url&access_token=491285117675871|W1lKVML_Y7cB-bylpoaEUADW4VY`,
        );

        if (!response.ok) {
          throw new Error(`Instagram oembed failed with status: ${response.status}`);
        }

        const data = await response.json();
        return {
          thumbnailUrl: data.thumbnail_url || null,
          contentType,
          author: data.author_name,
          title: formatTitle(contentType, data.author_name, data.title),
        };
      }

      case ContentTypeEnum.YOUTUBE:
      case ContentTypeEnum.YOUTUBE_SHORT: {
        const videoId = getYouTubeVideoId(url);
        if (!videoId) {
          return { thumbnailUrl: null, error: 'Invalid YouTube URL', contentType };
        }
        return {
          thumbnailUrl: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
          contentType,
          title: formatTitle(contentType),
        };
      }

      case ContentTypeEnum.TIKTOK: {
        const response = await fetch(`https://www.tiktok.com/oembed?url=${encodeURIComponent(url)}`);

        if (!response.ok) {
          throw new Error(`TikTok oembed failed with status: ${response.status}`);
        }

        const data = await response.json();
        return {
          thumbnailUrl: data.thumbnail_url || null,
          contentType,
          author: data.author_name,
          title: formatTitle(contentType, data.author_name, data.title),
        };
      }

      case ContentTypeEnum.PINTEREST:
        return {
          thumbnailUrl: null,
          error: 'Pinterest thumbnails not supported',
          contentType,
          title: 'Pinterest pin',
        };

      case ContentTypeEnum.IMAGE:
        return {
          thumbnailUrl: url,
          contentType,
          title: 'Image',
        };

      default:
        return {
          thumbnailUrl: null,
          error: 'Unsupported platform or invalid URL',
          contentType,
          title: 'Unknown content',
        };
    }
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
    const detectedType = detectContentType(url);
    return {
      thumbnailUrl: null,
      error: errorMessage,
      contentType: detectedType,
      title: formatTitle(detectedType),
    };
  }
};
