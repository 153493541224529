import { useEffect } from 'react';
import { useGetAllWorkletsForParentSpecQuery, useGetAllWorkletsQuery } from '@frontend/app/hooks';
import { keyBy } from 'lodash';
import { useCampaign } from '../CampaignContext';
import { TProject } from '../../types';

export const useInitializeCampaign = (mode: 'add' | 'edit', project?: TProject) => {
  const {
    setAllCampaignData,
  } = useCampaign();

  const { data: worklets } = useGetAllWorkletsQuery({
    variables: {
      specKeys: project?.workletSpecKeys || [],
    },
    skip: !project?.workletSpecKeys?.length,
  });

  const {
    data: {
      worklets: allWorkletsForParent = undefined,
    } = {},
  } = useGetAllWorkletsForParentSpecQuery({
    variables: {
      specKey: project?.specKey,
    },
    skip: !project?.specKey,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (mode === 'edit' && project) {
      const workletsById = keyBy(worklets?.worklets, 'specKey');

      // Prepare the data to set all at once
      const campaignData = {
        campaignName: project.title || '',
        campaignImage: project.splashImageUrl || '',
        campaignOwner: project.owner || '',
        listToMarketplace: project.published || false,
        contentReviewEnabled: project.gcrEnabled || false,
        contentReviewers: project.gcrApproverIds || [],
        templateName: project.templateName || '',
        worklets: project.workletSpecKeys?.map((specKey) => workletsById[specKey]) || [],
        programId: project.id,
        startDate: project.programMetadata?.startDate ? new Date(project.programMetadata.startDate) : undefined,
        endDate: project.programMetadata?.endDate ? new Date(project.programMetadata.endDate) : undefined,
        budget: project.programMetadata?.budget,
        budgetTimePeriod: project.programMetadata?.budgetTimePeriod,
        salesGoal: project.programMetadata?.salesGoal,
        salesGoalTimePeriod: project.programMetadata?.salesGoalTimePeriod,
        creatorCount: project.programMetadata?.creatorCount,
        creatorsTimePeriod: project.programMetadata?.creatorsTimePeriod,
        creatorDescription: project.programMetadata?.creatorDescription,
        creatorPreference: project.programMetadata?.creatorPreference,
        contentPrefPostTypes: project.programMetadata?.contentPrefPostTypes,
        compensationMethod: project.programMetadata?.compensationMethod,
        commissionValue: project.programMetadata?.commissionValue,
        commissionValueType: project.programMetadata?.commissionValueType,
        discountValue: project.programMetadata?.discountValue,
        discountType: project.programMetadata?.discountType,
        discountValueType: project.programMetadata?.discountValueType,
        isFlexibleSpec: project.isFlexibleSpec,
        hasUnsavedChanges: false,
      };

      if (!campaignData.worklets.length) {
        // If no worklets are assigned, use all available worklets for the parent spec
        campaignData.worklets = allWorkletsForParent || [];
      }

      setAllCampaignData(campaignData);
    }
  }, [mode, project, setAllCampaignData, worklets, allWorkletsForParent]);
};
