import React, { useEffect, useMemo, useState, useRef } from 'react';
import { RawDraftContentState } from 'draft-js';

import {
 Button, Checkbox, Col, Divider, Row, Select, Space, Spinner, Typography,
} from '@revfluence/fresh';
import { IMember, useClientFeatureEnabled } from '@frontend/app/hooks';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { CircleXmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { GetProductDetailsQuery_productDetail } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetailsQuery';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { ShopifyShippingLine } from '@frontend/applications/ShopifyApp/components/types';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { OrderRequestFooter } from '../SendOrderRequestEmail/OrderRequestFooter';
import { IProduct, IProjectConfig } from '../../../hooks';
import {
  Description, Statistics, Products, OrderRequestReviewEmail,
} from './components';
import { ICurrentRequest, ISelectedVariant, IOrderRequestStatus } from './utils';

import styles from './OrderRequestReview.styles.scss';

const { Text } = Typography;

export interface IOrderRequestReview {
  products: IProduct[];
  fetchMoreProducts: () => void;
  currentRequest: ICurrentRequest;
  currentRequestItems: number;
  currentRequestMarketValue: number;
  projectConfig: IProjectConfig;
  member: IMember;
  searchValue: string;
  setSearchValue(value: string): void;
  isLoadingProducts: boolean;
  selectedVariants: ISelectedVariant[];
  setSelectedVariants(selectedVariants: ISelectedVariant[]): void;
  showProductSearch: boolean;
  setShowProductSearch(value: boolean): void;
  addSelectedVariantsToCurrentRequest(): void;
  toggleRemovedVariantInCurrentRequest(variantId: number): void;
  adjustVariantQuantityInCurrentRequest(variantId: number, quantity: number): void;
  orderRequestStatus: IOrderRequestStatus;
  setOrderRequestStatus(status: IOrderRequestStatus): void;
  customSend(messageParams: SendMessageInput | null, newOrderRequestStatus?: IOrderRequestStatus, shippingLine?: ShopifyShippingLine): void | Promise<void>;
  getInitialEmailState(): [string | null, RawDraftContentState | null];
  disabled: boolean;
  notice: React.ReactNode;
  onGetMoreVariants: (product: GetProductDetailsQuery_productDetail) => void;
  resourceId: number;
  shippingAddress: string;
  isSending?: boolean;
}

export const OrderRequestReview = (props: IOrderRequestReview) => {
  const {
    searchValue,
    setSearchValue,
    isLoadingProducts,
    products,
    fetchMoreProducts,
    member,
    currentRequest,
    currentRequestItems,
    currentRequestMarketValue,
    projectConfig,
    selectedVariants,
    setSelectedVariants,
    showProductSearch,
    setShowProductSearch,
    addSelectedVariantsToCurrentRequest,
    toggleRemovedVariantInCurrentRequest,
    adjustVariantQuantityInCurrentRequest,
    orderRequestStatus,
    setOrderRequestStatus,
    customSend,
    getInitialEmailState,
    disabled,
    notice,
    resourceId,
    shippingAddress,
    isSending = false,
  } = props;

  const emailComposerRef = useRef<any>(null);
  const sendEmailFromLocalStorage = localStorage.getItem('sendEmailToCreator');

  const shippingLineSettingsEnabled = useClientFeatureEnabled(ClientFeature.SHIPPING_LINE_SETTINGS);

  const { clientConfig, loading: isClientConfigLoading } = useGetClientConfig();

  const shippingLines = useMemo<ShopifyShippingLine[]>(() => clientConfig?.shopifyOrderPayload?.shippingLines || [], [clientConfig]);

  const [sendEmailToCreator, setSendEmailToCreator] = useState<boolean | null>(
    sendEmailFromLocalStorage === null ? true : sendEmailFromLocalStorage === 'true',
  );

  const [selectedShippingLineId, setSelectedShippingLineId] = useState<number | null>(null);

  const selectedShippingLine = useMemo(() => shippingLines.find((line) => line.id === selectedShippingLineId), [shippingLines, selectedShippingLineId]);

  const { [ClientFeature.FLEXIBLE_PROJECT]: isFlexEnabled } = useClientFeatures();

  useEffect(() => {
    if (shippingLines.length > 0) {
      setSelectedShippingLineId(shippingLines.find((line) => line.isDefault)?.id || null);
    }
  }, [shippingLines]);

  const handleSendEmailToggle = (checked: boolean) => {
    setSendEmailToCreator(checked);
    localStorage.setItem('sendEmailToCreator', checked.toString());
  };

  const handleAccept = () => {
    if (sendEmailToCreator) {
      setOrderRequestStatus('accept');
    } else {
      customSend(null, 'accept', shippingLineSettingsEnabled ? selectedShippingLine : null);
    }
  };

  const handleReject = () => {
    if (sendEmailToCreator) {
      setOrderRequestStatus('reject');
    } else {
      customSend(null, 'reject');
    }
  };

  const handleBackToReview = () => {
    setOrderRequestStatus(undefined);
  };

  const handleSendEmail = (status: IOrderRequestStatus) => {
    return (messageParams: SendMessageInput) => {
      customSend(messageParams, status, shippingLineSettingsEnabled ? selectedShippingLine : null);
    };
  };

  return (
    <div className={styles.Wizard}>
      <div className={styles.mainContentWrapper}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Description
            title="Review Order Request"
            description={`Add, remove, or change the quantity of each product that ${member.name} has requested for this project.`}
          />
          <Statistics
            maxItems={projectConfig?.quantityMax}
            maxMarketValue={projectConfig?.priceMax}
            selectedItems={currentRequestItems}
            selectedMarketValue={currentRequestMarketValue}
          />
          {notice}
          {orderRequestStatus ? (
            <OrderRequestReviewEmail
              member={member}
              orderRequestStatus={orderRequestStatus}
              getInitialEmailState={getInitialEmailState}
              customSend={handleSendEmail(orderRequestStatus)}
              hideActions={isFlexEnabled}
              emailComposerRef={emailComposerRef}
            />
          ) : (
            <Products
              disabled={disabled}
              shippingAddress={shippingAddress}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isLoadingProducts={isLoadingProducts}
              products={products}
              fetchMoreProducts={fetchMoreProducts}
              currentRequest={currentRequest}
              member={member}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              showProductSearch={showProductSearch}
              setShowProductSearch={setShowProductSearch}
              addSelectedVariantsToCurrentRequest={addSelectedVariantsToCurrentRequest}
              toggleRemovedVariantInCurrentRequest={toggleRemovedVariantInCurrentRequest}
              adjustVariantQuantityInCurrentRequest={adjustVariantQuantityInCurrentRequest}
              resourceId={resourceId}
            />
          )}
        </Space>
      </div>
      <div className={styles.footer}>
        {!isFlexEnabled && (
          <Space direction="vertical" size="small" align="center">
            {orderRequestStatus ? (
              <Button
                className={styles.footerButton}
                type="default"
                icon={<CloseCircleFilled style={{ fontSize: '16px', color: '#F5222D' }} />}
                disabled={disabled}
                onClick={() => setOrderRequestStatus(undefined)}
              >
                Review Order
              </Button>
            ) : (
              <>
                <Row justify="space-between" align="middle">
                  <Col>
                    {shippingLineSettingsEnabled && (
                      <Space>
                        <Text>Shipping Line</Text>
                        {isClientConfigLoading
                          ? <Spinner /> : (
                            <Select
                              placeholder="Select a shipping line"
                              style={{ width: 200 }}
                              value={selectedShippingLineId}
                              onChange={(value) => setSelectedShippingLineId(value)}
                              size="small"
                            >
                              <Select.Option value={null}>None</Select.Option>
                              {shippingLines.map((line) => (
                                <Select.Option key={line.id} value={line.id}>
                                  {line.title}
                                </Select.Option>
                            ))}
                            </Select>
                        )}
                      </Space>
                    )}
                  </Col>
                  <Col>
                    <Checkbox checked={sendEmailToCreator} onChange={(e) => handleSendEmailToggle(e.target.checked)}>
                      Send email to the creator
                    </Checkbox>
                  </Col>
                </Row>
                <Divider style={{
                  width: '100%',
                  margin: '8px 0 12px',
                }}
                />
                <Space direction="horizontal" align="center" size="small">
                  <Button
                    className={styles.footerButton}
                    type="default"
                    icon={<CircleXmarkIcon color="#F5222D" />}
                    onClick={handleReject}
                    disabled={disabled}
                  >
                    Reject Request
                  </Button>
                  <Button
                    className={styles.footerButton}
                    type="default"
                    icon={<CheckCircleFilled style={{ fontSize: '16px', color: '#52C41A' }} />}
                    onClick={handleAccept}
                    disabled={disabled}
                  >
                    Accept Request
                  </Button>
                </Space>
              </>
            )}
          </Space>
        )}
        {isFlexEnabled && (orderRequestStatus ? (
          <OrderRequestFooter
            emailComposerRef={emailComposerRef}
            recipientCount={1}
            onCancel={handleBackToReview}
            onContinueWithoutMessage={handleBackToReview}
            cancelText="Review Order"
            showCancelIcon
            showContinueWithoutMessageButton={false}
            showBackIcon
            showNextReviewIcon
            sendToRecipientsButtonProps={{
              loading: isSending,
              disabled: isSending
            }}
          />
        ) : (
          <Space direction="vertical" size="small" align="center">
            <Row justify="space-between" align="middle">
              <Col>
                {shippingLineSettingsEnabled && (
                  <Space>
                    <Text>Shipping Line</Text>
                    {isClientConfigLoading
                      ? <Spinner /> : (
                        <Select
                          placeholder="Select a shipping line"
                          style={{ width: 200 }}
                          value={selectedShippingLineId}
                          onChange={(value) => setSelectedShippingLineId(value)}
                          size="small"
                        >
                          <Select.Option value={null}>None</Select.Option>
                          {shippingLines.map((line) => (
                            <Select.Option key={line.id} value={line.id}>
                              {line.title}
                            </Select.Option>
                        ))}
                        </Select>
                    )}
                  </Space>
                )}
              </Col>
              <Col>
                <Checkbox checked={sendEmailToCreator} onChange={(e) => handleSendEmailToggle(e.target.checked)}>
                  Send email to the creator
                </Checkbox>
              </Col>
            </Row>
            <Divider style={{
              width: '100%',
              margin: '8px 0 12px',
            }}
            />
            <Space direction="horizontal" align="center" size="small">
              <Button
                className={styles.footerButton}
                type="default"
                icon={<CircleXmarkIcon color="#F5222D" />}
                onClick={handleReject}
                disabled={disabled}
              >
                Reject Request
              </Button>
              <Button
                className={styles.footerButton}
                type="default"
                icon={<CheckCircleFilled style={{ fontSize: '16px', color: '#52C41A' }} />}
                onClick={handleAccept}
                disabled={disabled}
              >
                Accept Request
              </Button>
            </Space>
          </Space>
        ))}
      </div>
    </div>
  );
};
