/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdNetworkType {
  META = "META",
}

export enum AlloyIntegrationType {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
  GORGIAS = "GORGIAS",
  KLAVIYO = "KLAVIYO",
  SLACK = "SLACK",
}

export enum AutomationActionNodeSubType {
  DEBUG = "DEBUG",
  SEND_EMAIL = "SEND_EMAIL",
}

export enum AutomationExecutionStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  TERMINATED = "TERMINATED",
}

export enum AutomationNodeType {
  ACTION = "ACTION",
  CONDITION = "CONDITION",
  DELAY = "DELAY",
  JUMP = "JUMP",
}

export enum AutomationStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DRAFT = "DRAFT",
}

export enum AutomationTriggerType {
  BRIEF_ACCEPTED = "BRIEF_ACCEPTED",
  BRIEF_SENT = "BRIEF_SENT",
  DELIVERABLE_COMPLETED = "DELIVERABLE_COMPLETED",
  DELIVERABLE_REJECTED = "DELIVERABLE_REJECTED",
  DELIVERABLE_SUBMITTED = "DELIVERABLE_SUBMITTED",
  PRODUCT_ORDER_DELIVERED = "PRODUCT_ORDER_DELIVERED",
  PRODUCT_ORDER_REQUEST_SENT = "PRODUCT_ORDER_REQUEST_SENT",
  PROGRAM_APPLICANT_APPROVED = "PROGRAM_APPLICANT_APPROVED",
  PROGRAM_APPLIED = "PROGRAM_APPLIED",
  PROGRAM_INVITED = "PROGRAM_INVITED",
  PROGRAM_REJECTED = "PROGRAM_REJECTED",
  SALES_TRACKING_FIRST_CONVERSION = "SALES_TRACKING_FIRST_CONVERSION",
}

export enum AutomationVariableType {
  AGREEMENT = "AGREEMENT",
  BRIEF_LINK = "BRIEF_LINK",
  CONTENT_REVIEW = "CONTENT_REVIEW",
  INVITE = "INVITE",
  MEMBER = "MEMBER",
  MESSAGE = "MESSAGE",
  PFA_ORDER = "PFA_ORDER",
  RESOURCE = "RESOURCE",
  STA_OFFER = "STA_OFFER",
  THREAD = "THREAD",
}

export enum BackupType {
  CUSTOM_TEXT = "CUSTOM_TEXT",
  LEAVE_BLANK = "LEAVE_BLANK",
  REMOVE_INVALID = "REMOVE_INVALID",
}

export enum BrandedContentStatus {
  ACTIVE = "ACTIVE",
  ERROR = "ERROR",
  OTHER_ACCOUNT_CONNECTED = "OTHER_ACCOUNT_CONNECTED",
  WARNING = "WARNING",
}

export enum ClientFFState {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum ClientFeatureState {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  LIMITED = "LIMITED",
  NEW_ONLY = "NEW_ONLY",
}

export enum CompensationMethod {
  COMMISSION = "COMMISSION",
  DISCOUNT = "DISCOUNT",
  FREE_PRODUCT = "FREE_PRODUCT",
}

export enum ConditionOptionGroup {
  ALLOY = "ALLOY",
  API_EXPLORER_APPLICATION = "API_EXPLORER_APPLICATION",
  AUTOMATION = "AUTOMATION",
  BRIEFS = "BRIEFS",
  CONTENT = "CONTENT",
  CONTRACTS = "CONTRACTS",
  CREATE_ARCHIVES = "CREATE_ARCHIVES",
  CREATE_GIFT_ORDER = "CREATE_GIFT_ORDER",
  CREATOR_SEARCH = "CREATOR_SEARCH",
  CUSTOM_MEMBER_DATA = "CUSTOM_MEMBER_DATA",
  DEFAULT_MEMBER_DATA = "DEFAULT_MEMBER_DATA",
  GMAIL = "GMAIL",
  IGDM = "IGDM",
  INFO_UPDATE = "INFO_UPDATE",
  OUTLOOK = "OUTLOOK",
  PAYMENT = "PAYMENT",
  REMINDER = "REMINDER",
  SALES_TRACKING = "SALES_TRACKING",
  SHOPIFY = "SHOPIFY",
  SOCIAL_ENRICHMENT = "SOCIAL_ENRICHMENT",
  SOCIAL_POST = "SOCIAL_POST",
  TRAY_IO = "TRAY_IO",
  TWILIO = "TWILIO",
  UNKNOWN = "UNKNOWN",
  WOOCOMMERCE = "WOOCOMMERCE",
  ZAPIER = "ZAPIER",
}

export enum ContentFieldEnum {
  DATE = "DATE",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
  NUMBER = "NUMBER",
  SINGLE_SELECT = "SINGLE_SELECT",
  TEXT = "TEXT",
  URL = "URL",
}

export enum ContentGuidelineScope {
  CLIENT = "CLIENT",
  DEFAULT = "DEFAULT",
  GLOBAL_TEMPLATE = "GLOBAL_TEMPLATE",
  MEMBER = "MEMBER",
  PROGRAM = "PROGRAM",
  TERMS = "TERMS",
}

export enum ContentPostTypes {
  CUSTOM_WORK = "CUSTOM_WORK",
  IMAGES = "IMAGES",
  INSTAGRAM_POST = "INSTAGRAM_POST",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  PINTEREST_PIN = "PINTEREST_PIN",
  TIKTOK_CAROUSEL = "TIKTOK_CAROUSEL",
  TIKTOK_VIDEO = "TIKTOK_VIDEO",
  VIDEOS = "VIDEOS",
  YOUTUBE_SHORT = "YOUTUBE_SHORT",
  YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
}

export enum ContentReviewEventType {
  ContentsApproved = "ContentsApproved",
  ContentsApprovedWithComments = "ContentsApprovedWithComments",
  ContentsRejected = "ContentsRejected",
  ContentsUpdated = "ContentsUpdated",
}

export enum ContentReviewStatus {
  Approved = "Approved",
  ApprovedWithComments = "ApprovedWithComments",
  ChangesRequested = "ChangesRequested",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum ContentState {
  ACCEPTED = "ACCEPTED",
  AMENDED = "AMENDED",
  COMPLETED = "COMPLETED",
  COMPLETED_WITHOUT_PAYMENT = "COMPLETED_WITHOUT_PAYMENT",
  GCR_PENDING = "GCR_PENDING",
  MARKED_COMPLETE = "MARKED_COMPLETE",
  NEW = "NEW",
  PENDING_MARK_COMPLETE = "PENDING_MARK_COMPLETE",
  PLACEHOLDER = "PLACEHOLDER",
  REJECTED = "REJECTED",
  SOFT_APPROVED = "SOFT_APPROVED",
  SOFT_REJECTED = "SOFT_REJECTED",
  UPLOADED = "UPLOADED",
  VALIDATING_UPLOAD = "VALIDATING_UPLOAD",
}

export enum ContentType {
  ADDITIONAL_IMAGES = "ADDITIONAL_IMAGES",
  ADDITIONAL_VIDEOS = "ADDITIONAL_VIDEOS",
  BLOG_DEDICATED = "BLOG_DEDICATED",
  BLOG_MENTION = "BLOG_MENTION",
  FACEBOOK_POST = "FACEBOOK_POST",
  FLEXIBLE = "FLEXIBLE",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_POST = "INSTAGRAM_POST",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  INSTAGRAM_VIDEO = "INSTAGRAM_VIDEO",
  OTHER = "OTHER",
  PINTEREST = "PINTEREST",
  PINTEREST_PIN = "PINTEREST_PIN",
  SNAPCHAT_STORY = "SNAPCHAT_STORY",
  TIKTOK_VIDEO = "TIKTOK_VIDEO",
  TWITTER_POST = "TWITTER_POST",
  YOUTUBE_DEDICATED = "YOUTUBE_DEDICATED",
  YOUTUBE_MENTION = "YOUTUBE_MENTION",
  YOUTUBE_SHORT = "YOUTUBE_SHORT",
}

export enum ContentUsageRightsType {
  BROAD = "BROAD",
  CUSTOM = "CUSTOM",
  LIMITED = "LIMITED",
  NONE = "NONE",
}

export enum CustomFieldType {
  ACTIVATION = "ACTIVATION",
  ANNUAL = "ANNUAL",
  ARRAY = "ARRAY",
  BOOLEAN = "BOOLEAN",
  COMMUNITY = "COMMUNITY",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  DYNAMIC_SELECT = "DYNAMIC_SELECT",
  EMAIL = "EMAIL",
  HIGHLIGHT = "HIGHLIGHT",
  IMAGES = "IMAGES",
  LINK = "LINK",
  MULTI_SELECT = "MULTI_SELECT",
  NUMBER = "NUMBER",
  OWNERS = "OWNERS",
  PERCENTAGE = "PERCENTAGE",
  PROGRAM = "PROGRAM",
  PROGRAM_MEMBERSHIP = "PROGRAM_MEMBERSHIP",
  SINGLE_SELECT = "SINGLE_SELECT",
  TAG = "TAG",
  TALENT_AGENTS = "TALENT_AGENTS",
  TEXT = "TEXT",
}

export enum DiscountType {
  AMOUNT_OFF = "AMOUNT_OFF",
  BUY_X_GET_Y = "BUY_X_GET_Y",
  FREE_SHIPPING = "FREE_SHIPPING",
  PERCENT_OFF = "PERCENT_OFF",
}

export enum DueDateType {
  BEFORE = "BEFORE",
  BETWEEN = "BETWEEN",
  ON = "ON",
}

export enum FieldSource {
  DbColumn = "DbColumn",
  MemberFieldSchemaId = "MemberFieldSchemaId",
  ProjectColumn = "ProjectColumn",
  RelationshipColumn = "RelationshipColumn",
}

export enum FieldType {
  ANNUAL = "ANNUAL",
  ARRAY = "ARRAY",
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  EMAIL = "EMAIL",
  NUMBER = "NUMBER",
  PERCENTAGE = "PERCENTAGE",
  TEXT = "TEXT",
}

export enum GuidelineType {
  CAPTION_ABOVE_READ_MORE = "CAPTION_ABOVE_READ_MORE",
  CAPTION_GUIDELINES = "CAPTION_GUIDELINES",
  CONCEPT = "CONCEPT",
  EXAMPLE_CONTENT_GUIDELINES = "EXAMPLE_CONTENT_GUIDELINES",
  LINK = "LINK",
  OTHER_CONTENT_GUIDELINES = "OTHER_CONTENT_GUIDELINES",
  OTHER_GUIDELINES = "OTHER_GUIDELINES",
  PHOTO_GUIDELINES = "PHOTO_GUIDELINES",
  POST_GUIDELINES = "POST_GUIDELINES",
  POST_INSTRUCTIONS = "POST_INSTRUCTIONS",
  STORY_SLIDES_GUIDELINES = "STORY_SLIDES_GUIDELINES",
  TALKING_POINTS = "TALKING_POINTS",
  VIDEO_GUIDELINES = "VIDEO_GUIDELINES",
}

export enum HistoricalDataGroupBy {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum IGDMMessageAttachmentType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum IGDMStatus {
  ACTIVE = "ACTIVE",
  ERROR = "ERROR",
  NOT_INSTALLED = "NOT_INSTALLED",
  OTHER_CLIENT_CONNECTION = "OTHER_CLIENT_CONNECTION",
  WARNING = "WARNING",
}

export enum InternalContentReviewStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  SOFT_APPROVED = "SOFT_APPROVED",
  SOFT_REJECTED = "SOFT_REJECTED",
  SUBMITTED = "SUBMITTED",
  UNDER_REVIEW = "UNDER_REVIEW",
  WAITING_FOR_SUBMISSION = "WAITING_FOR_SUBMISSION",
}

export enum MemberApplicantOperation {
  Approve = "Approve",
  Reject = "Reject",
}

export enum MemberDataRestrictionFieldName {
  EMAIL = "EMAIL",
}

export enum MemberDataRestrictionFieldType {
  CONTAINS = "CONTAINS",
  EXACT_MATCH = "EXACT_MATCH",
  POSTFIX = "POSTFIX",
  PREFIX = "PREFIX",
}

export enum MessageActionType {
  openModal = "openModal",
  openUrl = "openUrl",
}

export enum MessageBackupPlanEntity {
  MEMBER = "MEMBER",
}

export enum MessageType {
  COMMENT = "COMMENT",
  CONTENT = "CONTENT",
  CONTRACT = "CONTRACT",
  CREATE = "CREATE",
  CREATE_ARCHIVE = "CREATE_ARCHIVE",
  GMAIL = "GMAIL",
  IGDM = "IGDM",
  NOTE = "NOTE",
  OUTLOOK = "OUTLOOK",
  PAYMENT = "PAYMENT",
  PRODUCT_FULFILLMENT = "PRODUCT_FULFILLMENT",
  REMINDER = "REMINDER",
  SENDGRID = "SENDGRID",
  SMS = "SMS",
  SOCIAL = "SOCIAL",
  TERMS = "TERMS",
  ZAPIER = "ZAPIER",
}

export enum NetworkType {
  BLOG = "BLOG",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  OTHER = "OTHER",
  PINTEREST = "PINTEREST",
  SNAPCHAT = "SNAPCHAT",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum NotificationMediumType {
  DESKTOP = "DESKTOP",
  EMAIL = "EMAIL",
}

export enum NotificationSourceType {
  AUTOWORKFLOW_DIGEST = "AUTOWORKFLOW_DIGEST",
  COMMENT = "COMMENT",
  CONTENT = "CONTENT",
  CONTRACT = "CONTRACT",
  CREATE = "CREATE",
  GMAIL = "GMAIL",
  IG_DISCONNECTED = "IG_DISCONNECTED",
  INBOX = "INBOX",
  MESSAGE_DIGEST = "MESSAGE_DIGEST",
  NOTE = "NOTE",
  OUTLOOK = "OUTLOOK",
  PAYMENT = "PAYMENT",
  PRODUCT_FULFILLMENT = "PRODUCT_FULFILLMENT",
  REMINDER = "REMINDER",
  SMS = "SMS",
  SOCIAL = "SOCIAL",
  SYSTEM = "SYSTEM",
  TERMS = "TERMS",
}

export enum NotificationType {
  ASSIGNED_TO_YOU = "ASSIGNED_TO_YOU",
  AUTOWORKFLOW_DIGEST = "AUTOWORKFLOW_DIGEST",
  IG_DISCONNECTED = "IG_DISCONNECTED",
  MESSAGE_DIGEST = "MESSAGE_DIGEST",
  NEW_MESSAGE = "NEW_MESSAGE",
}

export enum OrderingDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PreferredCreatorTypes {
  CUSTOMERS = "CUSTOMERS",
  MACRO_CREATORS = "MACRO_CREATORS",
  MICRO_CREATORS = "MICRO_CREATORS",
  MID_TIER_CREATORS = "MID_TIER_CREATORS",
  NANO_CREATORS = "NANO_CREATORS",
  UGC_CREATORS = "UGC_CREATORS",
}

export enum ProgramTimePeriod {
  PER_MONTH = "PER_MONTH",
  PER_YEAR = "PER_YEAR",
  TOTAL = "TOTAL",
}

export enum RawContentReviewState {
  CONTENT_REVIEW_STATE_AMENDED = "CONTENT_REVIEW_STATE_AMENDED",
  CONTENT_REVIEW_STATE_APPROVED = "CONTENT_REVIEW_STATE_APPROVED",
  CONTENT_REVIEW_STATE_COMPLETED = "CONTENT_REVIEW_STATE_COMPLETED",
  CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT = "CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT",
  CONTENT_REVIEW_STATE_MARK_COMPLETED = "CONTENT_REVIEW_STATE_MARK_COMPLETED",
  CONTENT_REVIEW_STATE_NEW = "CONTENT_REVIEW_STATE_NEW",
  CONTENT_REVIEW_STATE_PLACEHOLDER = "CONTENT_REVIEW_STATE_PLACEHOLDER",
  CONTENT_REVIEW_STATE_REJECTED = "CONTENT_REVIEW_STATE_REJECTED",
  CONTENT_REVIEW_STATE_UPLOADED = "CONTENT_REVIEW_STATE_UPLOADED",
  CONTENT_REVIEW_STATE_VALIDATING_UPLOAD = "CONTENT_REVIEW_STATE_VALIDATING_UPLOAD",
  CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION = "CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION",
}

export enum RecommendationAxis {
  content = "content",
  conversions = "conversions",
  creators = "creators",
}

export enum RecommendationLevel {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum ResourceType {
  GDRIVE = "GDRIVE",
  GMAIL = "GMAIL",
  IGDM = "IGDM",
  KLAVIYO = "KLAVIYO",
  OUTLOOK = "OUTLOOK",
  SHOPIFY = "SHOPIFY",
  TWILIO = "TWILIO",
  WOOCOMMERCE = "WOOCOMMERCE",
}

export enum SocialAccountSettingsStatus {
  ACTIVE = "ACTIVE",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TagAction {
  ASSIGN = "ASSIGN",
  UNASSIGN = "UNASSIGN",
}

export enum TagEntityType {
  PAYMENT = "PAYMENT",
}

export enum TaskProgressStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum TemplateBodyType {
  HTML = "HTML",
  PLAINTEXT = "PLAINTEXT",
}

export enum TemplateTransportType {
  EMAIL = "EMAIL",
  IGDM = "IGDM",
}

export enum TemplateType {
  APPLICANT_REJECTED_FOLLOW_UP = "APPLICANT_REJECTED_FOLLOW_UP",
  AUTOMATION_REMINDER_INVITE = "AUTOMATION_REMINDER_INVITE",
  BRIEF_ACCEPTED_FOLLOW_UP = "BRIEF_ACCEPTED_FOLLOW_UP",
  BRIEF_REMINDER = "BRIEF_REMINDER",
  CONTENT_POSTED_FOLLOW_UP = "CONTENT_POSTED_FOLLOW_UP",
  FIRST_SALES_CONVERSION_FOLLOW_UP = "FIRST_SALES_CONVERSION_FOLLOW_UP",
  GENERIC = "GENERIC",
  INVITE = "INVITE",
  INVITE_REMINDER = "INVITE_REMINDER",
  MEMBER_ACCEPTED_FOLLOW_UP = "MEMBER_ACCEPTED_FOLLOW_UP",
  MEMBER_APPLIED_FOLLOW_UP = "MEMBER_APPLIED_FOLLOW_UP",
  ORDER_DELIVERED_FOLLOW_UP = "ORDER_DELIVERED_FOLLOW_UP",
  ORDER_SELECTION_REMINDER = "ORDER_SELECTION_REMINDER",
  TERMS = "TERMS",
}

export enum TermsType {
  ADVANCED = "ADVANCED",
  BASIC = "BASIC",
}

export enum ThreadLabelType {
  DONE = "DONE",
  TODO = "TODO",
}

export enum ValueType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum WhitelistingLabel {
  ALLOWLISTING = "ALLOWLISTING",
  BRANDED_CONTENT_ADS = "BRANDED_CONTENT_ADS",
}

export interface AccountsPaymentBudgetInput {
  paymentType?: string | null;
  programId?: number | null;
  splitInfo?: SplitInfoInput[] | null;
}

export interface ActivationInput {
  description?: string | null;
  id?: number | null;
  name?: string | null;
}

export interface AdvertiserAccessInfo {
  endDate: string;
  requireAccess: boolean;
  startDate: string;
}

export interface AgreementIteration {
  briefUrl: string;
  commissionDescription: string;
  contentGuidelines: ContentGuidelinesWithDueDates[];
  contentUsageRights: string;
  disableCreatorEdits: boolean;
  igBcPromoteAccessEndDate: string;
  igBcPromoteAccessStartDate: string;
  metaBamAccessEndDate: string;
  metaBamAccessStartDate: string;
  offersCommission: boolean;
  offersPayment: boolean;
  offersProduct: boolean;
  paymentTerms: string;
  price: number;
  productDescription: string;
  requireContentRights: boolean;
  requireIgBcPromoteAccess: boolean;
  requireIgPartnershipAdsAccess: boolean;
  requireInstagramInsights: boolean;
  requireMetaBamAccess: boolean;
  requireTiktokSparkAdsAccess: boolean;
  tiktokSparkAdsDuration?: number | null;
}

export interface ApplicationDbColumnsMetaInput {
  label?: string | null;
  name: string;
  order?: number | null;
  required?: boolean | null;
}

export interface ApplicationFormFieldsInput {
  dbColumns: ApplicationDbColumnsMetaInput[];
  memberFieldSchemas: ApplicationMemberFieldSchemaInput[];
}

export interface ApplicationMemberFieldSchemaInput {
  label?: string | null;
  order?: number | null;
  required?: boolean | null;
  schemaId: number;
}

export interface AutomationBlueprintInput {
  description?: string | null;
  id?: string | null;
  metadata: any;
  name: string;
  programId?: number | null;
  templateId?: string | null;
  triggers: any[];
}

export interface AutomationClientFilters {
  id?: string | null;
}

export interface AutomationCreateOptions {
  publish?: boolean | null;
}

export interface AutomationExecutionFilters {
  automation?: AutomationFilters | null;
  id?: string | null;
  status?: AutomationExecutionStatus | null;
}

export interface AutomationFilters {
  client?: AutomationClientFilters | null;
  id?: string | null;
  program?: AutomationProgramFilters | null;
  status?: AutomationStatus | null;
  template?: AutomationTemplateFilters | null;
}

export interface AutomationNodeOptionsContext {
  automationId?: string | null;
  templateId?: string | null;
}

export interface AutomationOrdering {
  createdDate?: OrderingDirection | null;
  name?: OrderingDirection | null;
  updatedDate?: OrderingDirection | null;
}

export interface AutomationProgramFilters {
  id?: number | null;
}

export interface AutomationPublishOptions {
  revision?: number | null;
}

export interface AutomationRevisionCreateOptions {
  publish?: boolean | null;
}

export interface AutomationSummaryContext {
  programId?: number | null;
}

export interface AutomationTemplateContext {
  clientId?: string | null;
  programId?: number | null;
}

export interface AutomationTemplateFilters {
  id?: string | null;
  isActive?: boolean | null;
  isSuggested?: boolean | null;
}

export interface AutomationTemplateOrdering {
  createdDate?: OrderingDirection | null;
  updatedDate?: OrderingDirection | null;
  weight?: OrderingDirection | null;
}

export interface BackendServerAdAccountInput {
  ad_account_network_id: string;
  social_account_id: string;
}

export interface BackendServerCampaignInput {
  accepted_place_ids?: BackendServerCampaignPlaceIdInput[] | null;
  categories?: string[] | null;
  external_listing_url?: string | null;
  name?: string | null;
  network_details?: BackendServerCampaignNetworkDetailsInput[] | null;
  offers_commission?: boolean | null;
  offers_other?: boolean | null;
  offers_payment?: boolean | null;
  offers_product?: boolean | null;
  product_types?: string[] | null;
  splash_image_url?: string | null;
  status?: string | null;
  summary?: string | null;
}

export interface BackendServerCampaignNetworkDetailsInput {
  fixed_compensation_is_negotiable?: boolean | null;
  fixed_compensation_per_engagement?: boolean | null;
  maximum_budget?: number | null;
  maximum_engagements?: number | null;
  maximum_followers?: number | null;
  maximum_views?: number | null;
  minimum_authenticity_score?: number | null;
  minimum_engagement_ratio?: number | null;
  minimum_engagements?: number | null;
  minimum_followers?: number | null;
  minimum_views?: number | null;
  network_type: string;
}

export interface BackendServerCampaignPlaceIdInput {
  label: string;
  value: string;
}

export interface BackendServerUpdateAdAccountsInput {
  accounts: BackendServerAdAccountInput[];
  network: AdNetworkType;
}

export interface BrandedContentAccessInfo {
  requireAccess: boolean;
}

export interface BulkAssignBudgetDataInput {
  accountsPaymentBudgetInput?: AccountsPaymentBudgetInput | null;
  clientId?: string | null;
  paymentGroupId?: any | null;
  paymentIds?: any[] | null;
  projectPaymentBudgetInput?: ProjectPaymentBudgetInput | null;
  source?: string | null;
}

export interface CampaignFiltersInput {
  categories?: string[] | null;
  compensations?: string[] | null;
  enabledTsRange?: number[] | null;
  excludedEnabledTsRange?: number[] | null;
  keywords?: string[] | null;
  limit?: number | null;
  networks?: string[] | null;
  page?: number | null;
  socialAccounts: SocialAccountFilterInput[];
}

export interface ClientFFUpdateInput {
  featuresUpdate: FeatureUpdateInput[];
  id: string;
}

export interface ClientFeatureInput {
  id?: number | null;
  name: string;
  state: ClientFeatureState;
}

export interface ClientInput {
  brandCategory?: string | null;
  id: string;
  memberDomainsRestricted?: boolean | null;
  name?: string | null;
  objective?: string | null;
}

export interface CommunityInput {
  description?: string | null;
  id?: number | null;
  memberFieldSchemas?: MemberFieldSchemaInput[] | null;
  showApplicantReview?: boolean | null;
  splashImageUrl?: string | null;
  title?: string | null;
}

export interface ContentGuidelineInput {
  attachments: GuidelineAttachmentInput[];
  guidelines: GuidelineInput[];
  id: number;
  label: string;
  networkType: NetworkType;
  priority: number;
  scope: ContentGuidelineScope;
  type: ContentType;
}

export interface ContentGuidelinesWithDueDates {
  acceptedPostTypes: ContentType[];
  accountHandle: string;
  attachments: GuidelineAttachmentInput[];
  count: number;
  dueDates?: (DueDatesInput | null)[] | null;
  guidelines: GuidelineInput[];
  id: number;
  label: string;
  networkType: NetworkType;
  postType: ContentType;
  requiresBrandedContentTag: boolean;
  requiresContentReview: boolean;
}

export interface ContentRequirementsInput {
  areDueDatesSelected: boolean;
  contentTypes: ContentType[];
  deliverablesDueDates: number[];
  deliverablesWithNoDueDates: number;
}

export interface CreateBudgetPaymentLogsInput {
  accountsPaymentBudgetInput?: AccountsPaymentBudgetInput | null;
  agreementId?: number | null;
  memberId?: number | null;
  paymentId?: number | null;
  projectPaymentBudgetInput?: ProjectPaymentBudgetInput | null;
  source?: string | null;
}

export interface CreateContentReviewCommentParams {
  info: any;
  reviewId: number;
}

export interface CreateContentReviewParams {
  info: any;
  internalStatus?: InternalContentReviewStatus | null;
  isGCR?: boolean | null;
  memberId: number;
  programId: number;
  status?: ContentReviewStatus | null;
}

export interface DueDateInput {
  dueDateInDays?: number | null;
  endDate: string;
  startDate: string;
  type: DueDateType;
}

export interface DueDatesInput {
  completion: DueDateInput;
  submission: DueDateInput;
}

export interface EmailDetails {
  additionalCc?: string[] | null;
  attachments: GuidelineAttachmentInput[];
  body: string;
  resourceId: number;
  subject: string;
}

export interface FeatureUpdateInput {
  id: number;
  state: ClientFFState;
}

export interface FindContentFieldsInput {
  clientId?: string | null;
  fieldTypes?: ContentFieldEnum[] | null;
  postTypes?: ContentType[] | null;
  programIds?: number[] | null;
}

export interface GuidelineAttachmentInput {
  filename: string;
  type: string;
  url: string;
}

export interface GuidelineInput {
  allowTitleEdition: boolean;
  instructions: string[];
  placeholder: string;
  title: string;
  type: GuidelineType;
}

export interface KlaviyoSyncSettings {
  automatedSync: boolean;
  groups: number[];
  projects: number[];
  syncAllMembers: boolean;
}

export interface MemberAgentDeleteInput {
  agentId: string;
  memberId: number;
}

export interface MemberAgentUpsertInput {
  agentEmail: string;
  agentId?: string | null;
  agentMemberId?: number | null;
  agentName: string;
  alwaysCC: boolean;
  memberId: number;
  ownerIds?: string[] | null;
}

export interface MemberCommentInput {
  text: string;
  userId?: string | null;
}

export interface MemberDataUpdateBatchInput {
  memberFieldSchemas: MemberDataUpdateFieldSchemaInput[];
  memberIds?: number[] | null;
  memberQueryJson?: any | null;
  message?: MessageInput | null;
  programId?: number | null;
  updateField?: MemberDataUpdateFieldSchemaInput | null;
  workItemIds?: string[] | null;
}

export interface MemberDataUpdateFieldSchemaInput {
  label?: string | null;
  required?: boolean | null;
  schemaId: number;
}

export interface MemberFieldSchemaInput {
  choices?: string[] | null;
  id?: number | null;
  name: string;
  type: string;
}

export interface MemberInput {
  email?: string | null;
  fields?: any | null;
  name?: string | null;
  notes?: string | null;
  source?: string | null;
}

export interface MemberRestrictionInput {
  filterFieldId: MemberDataRestrictionFieldName;
  filterType: MemberDataRestrictionFieldType;
  filterValue: string;
}

export interface MessageAttachmentInput {
  attachmentId?: string | null;
  filename: string;
  filenameInStorage: string;
  href: string;
  size?: number | null;
  type?: string | null;
}

export interface MessageBackupPlan {
  backupType: BackupType;
  entity: MessageBackupPlanEntity;
  field: string;
  value?: string | null;
}

export interface MessageFilterInput {
  endTime?: number | null;
  excludeFromEmails?: string[] | null;
  from?: string[] | null;
  fullSearch?: string | null;
  startTime?: number | null;
  to?: string[] | null;
}

export interface MessageInput {
  message: string;
  subject: string;
}

export interface MessageResourceConfigInput {
  displayName?: string | null;
  markSourceInboxRead?: boolean | null;
  signature?: ResourceSignatureInput | null;
}

export interface NotificationSettingsInput {
  enabled: boolean;
  medium: NotificationMediumType;
  source: NotificationSourceType;
  type: NotificationType;
}

export interface OnboardingTemplateAboutInput {
  brand_images?: string[] | null;
  componentStyles?: OnboardingTemplateComponentStylesInput | null;
  description?: string | null;
  show?: boolean | null;
  title?: string | null;
}

export interface OnboardingTemplateApplicationInput {
  apply_label?: string | null;
  componentStyles?: OnboardingTemplateComponentStylesInput | null;
  description?: string | null;
  support_email?: string | null;
  title?: string | null;
  unpaidDescription?: string | null;
  unpaidLabel?: string | null;
}

export interface OnboardingTemplateBlockStylingInput {
  fill_color?: string | null;
  font_family?: string | null;
  font_size?: number | null;
}

export interface OnboardingTemplateCTAStylingInput {
  button_color?: string | null;
  font_color?: string | null;
  font_family?: string | null;
}

export interface OnboardingTemplateComponentStylesInput {
  body?: OnboardingTemplateComponentStyles_bodyInput | null;
  button?: OnboardingTemplateComponentStyles_buttonInput | null;
  form?: OnboardingTemplateComponentStyles_pageInput | null;
  heading?: OnboardingTemplateComponentStyles_headingInput | null;
  page?: OnboardingTemplateComponentStyles_pageInput | null;
}

export interface OnboardingTemplateComponentStyles_bodyInput {
  color?: string | null;
  fontFamily?: string | null;
  fontSize?: number | null;
  lineHeight?: string | null;
}

export interface OnboardingTemplateComponentStyles_buttonInput {
  backgroundColor?: string | null;
  borderColor?: string | null;
  color?: string | null;
  cursor?: string | null;
  fontFamily?: string | null;
  fontSize?: number | null;
}

export interface OnboardingTemplateComponentStyles_headingInput {
  color?: string | null;
  fontFamily?: string | null;
  fontSize?: number | null;
}

export interface OnboardingTemplateComponentStyles_pageInput {
  backgroundColor?: string | null;
  isTransparent?: boolean | null;
}

export interface OnboardingTemplateConfigInput {
  about?: OnboardingTemplateAboutInput | null;
  application?: OnboardingTemplateApplicationInput | null;
  fieldsSettings?: OnboardingTemplateFieldSettingsInput[] | null;
  intro?: OnboardingTemplateIntroInput | null;
  perks?: OnboardingTemplatePerksInput | null;
  persona?: OnboardingTemplatePersonaInput | null;
  settings?: OnboardingTemplateSettingsInput | null;
  skipSelectPageTemplate?: boolean | null;
  time?: number | null;
  version?: string | null;
}

export interface OnboardingTemplateFieldSettingsInput {
  fieldName: string;
  isHidden?: boolean | null;
}

export interface OnboardingTemplateIntroInput {
  brand_instagram?: string | null;
  brand_pinterest?: string | null;
  brand_tiktok?: string | null;
  brand_website?: string | null;
  brand_youtube?: string | null;
  button_text?: string | null;
  componentStyles?: OnboardingTemplateComponentStylesInput | null;
  description?: string | null;
  hero_image?: string | null;
  hero_is_video?: boolean | null;
  show?: boolean | null;
  subtitle?: string | null;
  title?: string | null;
}

export interface OnboardingTemplatePageColorInput {
  background_color: string;
  is_transparent?: boolean | null;
}

export interface OnboardingTemplatePerksInput {
  availability?: string[] | null;
  availabilityTitle?: string | null;
  bullet_style?: string | null;
  compensation?: string[] | null;
  compensationTitle?: string | null;
  componentStyles?: OnboardingTemplateComponentStylesInput | null;
  items?: string[] | null;
  show?: boolean | null;
  title?: string | null;
}

export interface OnboardingTemplatePersonaInput {
  bullet_style?: string | null;
  componentStyles?: OnboardingTemplateComponentStylesInput | null;
  cover_image?: string | null;
  features?: string[] | null;
  show?: boolean | null;
  title?: string | null;
}

export interface OnboardingTemplateSettingsInput {
  body_styling?: OnboardingTemplateBlockStylingInput | null;
  cta_styling?: OnboardingTemplateCTAStylingInput | null;
  favicon?: string | null;
  form_color?: OnboardingTemplatePageColorInput | null;
  headline_styling?: OnboardingTemplateBlockStylingInput | null;
  logo?: string | null;
  page_color?: OnboardingTemplatePageColorInput | null;
  page_online: boolean;
  url?: string | null;
}

export interface OrderedColumnInput {
  dbColumn?: string | null;
  memberFieldSchemaId?: number | null;
  projectColumn?: string | null;
}

export interface OverflowSplitFiscalInfoInput {
  budgetAccountId: number;
  budgetPeriodDefinitionId: number;
  fiscalGranularityLabel: string;
}

export interface PaginationInput {
  orderBy?: string | null;
  orderDirection?: string | null;
  page?: number | null;
}

export interface PagingParams {
  limit: number;
  offset: number;
}

export interface PaymentDashboardFilterInput {
  budgetAccounts?: number[] | null;
  endDate?: string | null;
  initiatedBy?: string[] | null;
  page?: number | null;
  pageSize?: number | null;
  paymentStatus?: string[] | null;
  paymentTypes?: string[] | null;
  projectIds?: number[] | null;
  searchBy?: string | null;
  searchQuery?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  startDate?: string | null;
  tags?: number[] | null;
}

export interface ProgramInput {
  applicationFormFields?: ApplicationFormFieldsInput | null;
  applicationPageTemplateName?: string | null;
  archivedBy?: UserInput | null;
  archivedDate?: any | null;
  columns?: SelectedColumnsInput | null;
  communityId?: number | null;
  customLandingPagePath?: string | null;
  description?: string | null;
  gcrApproverIds?: string[] | null;
  gcrEnabled?: boolean | null;
  hasUnpaidOffer?: boolean | null;
  id?: number | null;
  isFlexibleSpec?: boolean | null;
  onboardingTemplateConfig?: OnboardingTemplateConfigInput | null;
  owner?: string | null;
  programMetadata?: ProgramMetadataInput | null;
  published?: boolean | null;
  specKey?: string | null;
  splashImageUrl?: string | null;
  submittedForReview?: boolean | null;
  templateName?: string | null;
  title?: string | null;
  workletSpecKeys?: string[] | null;
}

export interface ProgramMetadataInput {
  budget?: number | null;
  budgetTimePeriod?: ProgramTimePeriod | null;
  commissionValue?: number | null;
  commissionValueType?: ValueType | null;
  compensationMethod?: CompensationMethod | null;
  contentPrefPostTypes?: ContentPostTypes[] | null;
  creatorCount?: number | null;
  creatorDescription?: string[] | null;
  creatorPreference?: PreferredCreatorTypes[] | null;
  creatorsTimePeriod?: ProgramTimePeriod | null;
  discountType?: DiscountType | null;
  discountValue?: number | null;
  discountValueType?: ValueType | null;
  endDate?: any | null;
  programId?: number | null;
  salesGoal?: number | null;
  salesGoalTimePeriod?: ProgramTimePeriod | null;
  startDate: any;
}

export interface ProgramSplitFiscalInfoInput {
  budgetPeriodDefinitionId: number;
  fiscalGranularityLabel: string;
}

export interface ProjectPaymentBudgetInput {
  overflow?: OverflowSplitFiscalInfoInput[] | null;
  programId?: number | null;
  programSplitFiscalInfo?: ProgramSplitFiscalInfoInput | null;
}

export interface RequirementInput {
  description?: string | null;
  dueDate?: string | null;
  name: string;
}

export interface ResourceSignatureInput {
  fromEditor?: string | null;
  fromHTML?: string | null;
  mode?: string | null;
}

export interface SaveBulkPaymentBudgetData {
  budgetData?: CreateBudgetPaymentLogsInput | null;
  paymentGroupId?: number | null;
}

export interface SegmentFolderInput {
  communityId?: number | null;
  id?: number | null;
  programId?: number | null;
  sourceGroup?: string | null;
  title: string;
}

export interface SegmentInput {
  columns?: SelectedColumnsInput | null;
  filter?: any | null;
  folderId?: number | null;
  id?: number | null;
  title?: string | null;
}

export interface SegmentMetadataInput {
  columns?: SelectedColumnsInput | null;
  predefinedSegmentId?: string | null;
  segmentId?: number | null;
}

export interface SelectedColumnsInput {
  dbColumns: string[];
  memberFieldSchemaIds: number[];
  order?: OrderedColumnInput[] | null;
  projectColumns?: string[] | null;
}

export interface SendMessageInput {
  additionalCc?: string[] | null;
  attachments?: MessageAttachmentInput[] | null;
  backupPlans?: MessageBackupPlan[] | null;
  internalMessageId?: string | null;
  members?: SendMessageMemberField[] | null;
  membersSearchQuery?: any | null;
  message?: string | null;
  messageId?: string | null;
  resourceId: number;
  subject?: string | null;
  threadId?: string | null;
  type: MessageType;
  user?: any | null;
}

export interface SendMessageMemberField {
  email?: string | null;
  fields?: any | null;
  id?: number | null;
}

export interface SetupBudgetFiscalSettingsInput {
  fiscalYears?: any[] | null;
  quarterNames?: any | null;
  startMonth?: string | null;
}

export interface SocialAccountFilterInput {
  network: string;
  username: string;
}

export interface SplitInfoInput {
  amount?: number | null;
  budgetAccountId: number;
  budgetGranularityLabel?: string | null;
  budgetPeriodDefinitionId: number;
  fiscalGranularityLabel?: string | null;
}

export interface TableConfigurationInput {
  columnDefinitions: any[];
  id?: number | null;
  tableName: string;
  version: number;
}

export interface TagInput {
  id?: number | null;
  name?: string | null;
}

export interface TaskTrackerParams {
  clientId?: string | null;
  id?: string | null;
  parentId?: string | null;
  success?: boolean | null;
  url?: string | null;
}

export interface TemplateAttachmentInput {
  fileUrl: string;
  id: string;
  localSrc: string;
  name: string;
  size: number;
  type: string;
  url: string;
  uuid: string;
}

export interface TermsMemberDetailsInput {
  memberId: number;
  priceOverride: number;
  workItemId?: string | null;
}

export interface TermsProjectInput {
  advertiserAccess: AdvertiserAccessInfo;
  agreementIteration: AgreementIteration;
  backendServerPostProjectId?: string | null;
  brandedContentAccess: BrandedContentAccessInfo;
  clientId: string;
  emailDetails: EmailDetails;
  introMessage?: string | null;
  programId: number;
}

export interface ThreadFilterInput {
  applicationIds?: string[] | null;
  assignees?: string[] | null;
  community?: number | null;
  excludeApplicationIds?: string[] | null;
  isContact?: boolean | null;
  isRead?: boolean | null;
  isSearchFilter?: boolean | null;
  messageFilter?: MessageFilterInput | null;
  resourceIds?: number[] | null;
  sort?: SortDirection | null;
  threadIds?: string[] | null;
  unassigned?: boolean | null;
  userLabel?: ThreadLabelType | null;
}

export interface UpdateAdvanceTermsTemplateInput {
  compensationCommissionAvailable: boolean;
  compensationCommissionValue?: string | null;
  compensationFreeProductAvailable: boolean;
  compensationFreeProductValue: string;
  compensationPaymentAvailable: boolean;
  compensationPaymentValue: number;
  contentUsageRightType: ContentUsageRightsType;
  contentUsageRightValue: string;
  default: boolean;
  emailTemplateId: number;
  messageTemplateInput: UpdateTemplateInput;
  noCompensationAvailable: boolean;
  programId: number;
  termsType: TermsType;
}

export interface UpdateBasicTermsTemplateInput {
  compensationCommissionAvailable: boolean;
  compensationCommissionValue?: string | null;
  compensationFreeProductAvailable: boolean;
  compensationFreeProductValue: string;
  compensationPaymentAvailable: boolean;
  compensationPaymentValue: number;
  contentGuideline: ContentGuidelineInput;
  contentRequirements: ContentRequirementsInput;
  contentUsageRightType: ContentUsageRightsType;
  contentUsageRightValue: string;
  default: boolean;
  emailTemplateId: number;
  introMessage: string;
  messageTemplateInput: UpdateTemplateInput;
  noCompensationAvailable: boolean;
  programId: number;
  termsType: TermsType;
}

export interface UpdateContentReviewParams {
  id: number;
  info?: any | null;
  internalStatus?: InternalContentReviewStatus | null;
  isGCR?: boolean | null;
  status?: ContentReviewStatus | null;
}

export interface UpdateProfileInput {
  currentPassword?: string | null;
  id: string;
  name?: string | null;
  password?: string | null;
}

export interface UpdateResourceInput {
  config: MessageResourceConfigInput;
}

export interface UpdateTemplateInput {
  attachments?: TemplateAttachmentInput[] | null;
  subject: string;
  text: string;
}

export interface UpsertBudgetAccountDistributionInput {
  budgetDescription?: string | null;
  budgetId?: number | null;
  budgetName?: string | null;
  fiscalYearsBudget?: any[] | null;
  parentBudgetId?: number | null;
  programId?: number | null;
}

export interface UpsertContentFieldInput {
  canMarkNa?: boolean | null;
  clientId: string;
  description?: string | null;
  fieldType: ContentFieldEnum;
  isMandatory?: boolean | null;
  name: string;
  postTypes?: ContentType[] | null;
  programIds?: number[] | null;
  selectOptions?: string[] | null;
}

export interface UserInput {
  createdDate: any;
  email: string;
  featureIds?: number[] | null;
  id: string;
  name: string;
  updatedDate: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
