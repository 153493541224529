import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_NO_DELIVERABLE_MEMBER_IDS } from '@frontend/app/queries';
import { GetNoDeliverableMemberIdsQuery, GetNoDeliverableMemberIdsQueryVariables } from '../queries/types/GetNoDeliverableMemberIdsQuery';

type IOptions = QueryHookOptions<GetNoDeliverableMemberIdsQuery, GetNoDeliverableMemberIdsQueryVariables>;

export const useGetNoDeliverableMemberIdsQuery = (options: IOptions = {}) => (
  useQuery<GetNoDeliverableMemberIdsQuery, GetNoDeliverableMemberIdsQueryVariables>(GET_NO_DELIVERABLE_MEMBER_IDS, options)
);
